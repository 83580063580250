import LogoAz from '../../../../../assets/Logo.svg';
import CloseIcon from '../../../../../assets/new/close.png';
import Caret from '../../../../../assets/new/caret.png';

import RefundMeSVG from '../../../../../components/SVG/RefundMe';
import KontributeSVG from '../../../../../components/SVG/Kontribute';
import UserSVG from '../../../../../components/SVG/User';
import VoucherSVG from '../../../../../components/SVG/Voucher';
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useIsAuthLocation from "../../../../../hooks/useIsAuthLocation";
import { useHideOldComponents } from "../../../../../hooks/useHideOldComponant";

import Smoto from '../../../../../assets/svgs/components/Smoto';
import NQR from '../../../../../assets/svgs/components/NQR';
import Invoicing from '../../../../../assets/svgs/components/Invoicing';
import Mpos from '../../../../../assets/svgs/components/Mpos';
import useShowNewFeature from '../../../../../hooks/useShowNewFeature';
import SponsorVoucherSVG from '../../../../../assets/svgs/components/SponsrVoucher';
import MerchantVoucherSVG from '../../../../../assets/svgs/components/MerchantVoucher';

const Nav = () => {
    const [scrollY, setScrollY] = useState(0);
    const [isAuthLocation] = useIsAuthLocation();
    const [showNav, setShowNav] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const hide = useHideOldComponents();

    const handleSetScroll = () => {
        setScrollY(window.scrollY)
    }
    const ref = useRef<HTMLLIElement>();

    const handleClickOutside = (event) => {
        // @ts-ignore
        if (ref.current && !ref.current.contains(event.target)) {
            setShowProducts(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleSetScroll);
        return () => window.removeEventListener('scroll', handleSetScroll);
    }, []);


    const isActiveRoute = (route: string) => {
        return pathname === route
    }
    if (isAuthLocation || hide) return null;
    return (
        <div
            className="flex w-screen px-[4%] sm:px-[6%] justify-between items-center h-100 fixed top-0 left-0 z-40 transition-all"
            style={{
                background: scrollY === 0 ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.9)'
            }}
        >
            <div className="flex flex-row flex-wrap h-fit justify-between xl:justify-start py-0 items-center w-full xl:w-5/12">
                <div className='w-full md:w-fit flex flex-row justify-between items-center'>
                    <Link to='/'><img className="w-40 object-contain" src={LogoAz} alt="AzatMe" /></Link>
                    <div className='hidden md:flex justify-between p-2 rounded-full bg-[#0898A01A] w-full ml-2 lg:w-[250px] '>
                        <button onClick={() => navigate('/')} className={`${!pathname.includes('business_app') ? 'bg-az-teal text-white' : 'bg-transparent text-az-teal'} rounded-full w-40 h-10 font-normal font-Onest`}>Personal</button>
                        <button onClick={() => navigate('/business_app')} className={`${pathname.includes('business_app') ? 'bg-az-teal text-white' : 'bg-transparent text-az-teal'} rounded-full w-40 h-10 font-normal font-Onest`}>Business</button>
                    </div>
                    <button className="md:hidden w-fit mr-2" onClick={() => setShowNav(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                            <path d="M0 20H30V16.6667H0V20ZM0 11.6667H30V8.33333H0V11.6667ZM0 0V3.33333H30V0H0Z" fill="#222222" />
                        </svg>
                    </button>
                </div>

                <button className="hidden md:flex w-fit mr-2 xl:hidden" onClick={() => setShowNav(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                        <path d="M0 20H30V16.6667H0V20ZM0 11.6667H30V8.33333H0V11.6667ZM0 0V3.33333H30V0H0Z" fill="#222222" />
                    </svg>
                </button>
                <div className='flex md:hidden justify-between p-2 rounded-full bg-[#0898A01A] w-full ml-2'>
                    <button onClick={() => navigate('/')} className={`${!pathname.includes('business_app') ? 'bg-az-teal text-white' : 'bg-transparent text-az-teal'} rounded-full w-40 h-10 font-normal font-Onest`}>Personal</button>
                    <button onClick={() => navigate('/business_app')} className={`${pathname.includes('business_app') ? 'bg-az-teal text-white' : 'bg-transparent text-az-teal'} rounded-full w-40 h-10 font-normal font-Onest`}>Business</button>
                </div>
            </div>
            <div className="flex flex-row items-center">
                <ul className="hidden xl:flex justify-between flex-row h-[50px]">
                    <li
                        // @ts-ignore
                        ref={ref} onClick={() => setShowProducts(!showProducts)} className="flex flex-row justify-start mx-4 items-center cursor-pointer font-Onest relative">
                        <div className='flex flex-row items-center text-[18px]'>
                            Products
                            <svg className={`w-5 h-5 mx-3 transition-all ${showProducts ? 'rotate-[-90deg]' : 'rotate-[0deg]'}`} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.25 6.375L9 11.625L3.75 6.375" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        {
                            showProducts && (
                                <div className='px-6 shadow-md bg-white h-max w-max absolute top-10 rounded-md'>
                                    <ProductsList closeNav={() => setShowNav(false)} />
                                </div>
                            )
                        }
                    </li>
                    <li className={`flex flex-col justify-center text-[18px] mx-6 cursor-pointer font-Onest ${isActiveRoute('/blogs') ? 'text-az-teal' : 'text-az-black-900'}`}>
                        <Link
                            to={'/blogs'}
                        >
                            Blogs
                        </Link>
                        {isActiveRoute('/blogs') && <ActiveLinkMarker />}
                    </li>
                    <li className={`flex flex-col justify-center text-[18px] mx-6 cursor-pointer font-Onest ${isActiveRoute('/contact') ? 'text-az-teal' : 'text-az-black-900'}`}>
                        <Link
                            to={`/contact`}
                        >
                            Contact
                        </Link>
                        {isActiveRoute(`/contact`) && <ActiveLinkMarker />}
                    </li>
                </ul>
                <div className="hidden xl:flex justify-between flex-row">
                    <button className=" w-36 font-normal font-Onest" onClick={() => navigate('/login')}>Login</button>
                    <button className="bg-az-teal text-white w-36 h-10 rounded font-normal font-Onest" onClick={() => navigate('/register')}>Get Started</button>
                </div>
            </div>
            {
                showNav && (
                    <div className="w-screen h-screen bg-zinc-700 fixed top-0 left-0 z-50">
                        <div className="w-full h-full" onClick={() => setShowNav(false)}></div>
                        <div className="h-screen max-w-[300px] w-10/12 bg-white absolute right-0 top-0">
                            <button className="absolute w-fit right-5 top-5" onClick={() => setShowNav(false)}>
                                <img src={CloseIcon} alt="" />
                            </button>
                            <div className="w-full h-full flex flex-col justify-between p-4 pb-6">
                                <div className='h-fit flex flex-col justify-start items-start'>
                                    <img className="w-40 object-contain" src={LogoAz} alt="AzatMe" />
                                    <ul className="flex justify-center flex-col flex-1 h-[60vh]">
                                        <li
                                            // @ts-ignore
                                            ref={ref} onClick={() => setShowProducts(!showProducts)} className="flex flex-col justify-start
                                        my-4 items-start cursor-pointer font-Onest">
                                            <div className='flex flex-row items-center text-[18px]'>
                                                Products
                                                <img src={Caret} className={`w-5 h-5 mx-3 transition-all ${showProducts ? 'rotate-[0deg]' : 'rotate-[-90deg]'}`} alt="" />
                                            </div>
                                            {
                                                // @ts-ignore
                                                showProducts && <div ><ProductsList closeNav={() => setShowNav(false)} /></div>
                                            }
                                        </li>
                                        <li
                                            onClick={() => setShowNav(false)}
                                            className={`flex flex-col justify-center items-start text-[18px] my-4 cursor-pointer font-Onest ${isActiveRoute('/blogs') ? 'text-az-teal' : 'text-az-black-900'}`}
                                        >
                                            <Link
                                                to={'/blogs'}
                                            >
                                                Blogs
                                            </Link>
                                            {isActiveRoute(`/blogs`) && <div className=' w-4/12'><ActiveLinkMarker /></div>}
                                        </li>
                                        <li
                                            onClick={() => setShowNav(false)}
                                            className={`flex flex-col justify-center items-start text-[18px] my-4 cursor-pointer font-Onest ${isActiveRoute('/contact') ? 'text-az-teal' : 'text-az-black-900'}`}>
                                            <Link
                                                to={`/contact`}
                                            >
                                                Contact
                                            </Link>
                                            {isActiveRoute(`/contact`) && <div className='w-4/12'><ActiveLinkMarker /></div>}
                                        </li>
                                    </ul>
                                </div>
                                <div className="flex justify-between flex-row">
                                    <button className=" w-[48%] font-normal text-center font-Onest border border-az-teal" onClick={() => { navigate('/login'); setShowNav(false); }}>Login</button>
                                    <button className="bg-az-teal w-[48%] text-white px-4 !h-10 rounded font-normal font-Onest" onClick={() => { navigate('/register'); setShowNav(false); }}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default Nav;

const ProductsList = ({ closeNav }) => {
    const showFeature = useShowNewFeature();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isActiveRoute = (route: string) => {
        return pathname === route;
    }
    const products = [
        {
            name: 'RefundMe',
            tagline: 'Makes splitting group expenses a breeze!',
            Icon: RefundMeSVG,
            link: 'refundme'
        },
        {
            name: 'Open Kontribute',
            tagline: 'Make your financial dreams a reality!',
            Icon: KontributeSVG,
            link: 'open_kontribute'
        },
        {
            name: 'Close Kontribute',
            tagline: 'Make your financial dreams a reality!',
            Icon: KontributeSVG,
            link: 'close_kontribute'
        },
        {
            name: 'Ajo',
            tagline: 'Save Together & Reach Goals Faster!',
            Icon: UserSVG,
            link: 'ajo',
            isNewFeature: true
        },
        {
            name: 'Voucher',
            tagline: 'User your vouchers!',
            Icon: VoucherSVG,
            link: 'voucher',
            isNewFeature: true
        },
    ];


    const businessProducts = [
        {
            name: 'SMOTO',
            tagline: 'Shop Socially, Pay Securely. From Chat to Checkout in Minutes',
            Icon: Smoto,
            link: '/business_app/smoto'
        },
        {
            name: 'Invoicing',
            tagline: 'Built to save your customer time and get you paid faster',
            Icon: Invoicing,
            link: '/business_app/invoicing'
        },
        {
            name: 'mPOS',
            tagline: 'Turn your phone into a POS terminal',
            Icon: Mpos,
            link: '/business_app/mpos'
        },
        {
            name: 'NGR',
            tagline: '',
            Icon: NQR,
            link: ''
        },
        {
            name: 'Sponsor Voucher',
            tagline: '',
            Icon: SponsorVoucherSVG,
            link: '/business_app/sponsor_voucher',
            isNewFeature: true
        },
        {
            name: 'Merchant Voucher',
            tagline: '',
            Icon: MerchantVoucherSVG,
            link: '/business_app/merchant_voucher',
            isNewFeature: true
        },
    ];

    const handleClick = (name: string) => {
        closeNav && closeNav();
        name && navigate(name);
    }

    const getProduct = () => {
        return pathname.includes('business_app') ? businessProducts : products;
    };
    return (
        <ul>
            {
                getProduct().map((itm) => {
                    const Icon = itm.Icon;
                    // @ts-ignore
                    if ((itm.isNewFeature && showFeature) || !itm.isNewFeature) {
                        return (
                            <li className={`${isActiveRoute(`/${itm.link}`) ? 'text-az-teal' : 'text-az-black-900'} my-6 flex flex-col items-start`} key={itm.name} onClick={() => handleClick(itm.link)}>
                                <div className='flex flex-row items-start'>
                                    <div className='bg-[#0898A00D] w-8 h-8 flex flex-row justify-center items-center'>
                                        <Icon />
                                    </div>
                                    <div className='ml-4'>
                                        <span className='text-[15px] font-medium flex flex-row justify-start items-center'>{itm.name}
                                            {
                                                !itm.link && (
                                                    <div className='p-1 ml-5 px-2 bg-[#3ABE77] text-white w-fit rounded-md'>
                                                        Coming soon
                                                    </div>
                                                )
                                            }
                                        </span>
                                        <span className='hiden md:flex text-[13px]'>{itm.tagline}</span>
                                    </div>

                                </div>
                                {/* {isActiveRoute(`/${itm.link}`) && <ActiveLinkMarker />} */}
                            </li>
                        )
                    }
                })
            }
        </ul>
    )
}

const ActiveLinkMarker = () => {
    return (
        <div className='w-full flex flex-row justify-center items-center'>
            <div className='rounded-full h-1 bg-az-teal w-6/12' />
        </div>
    )
}
import { useState } from "react"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import toast from "react-hot-toast"
import { registerNewAdmin } from "../../../actions/adminActions"

const formInitialData = {
  name: "",
  email: "",
  phone: "",
  password: "",
  password_confirmation: ""
}
export default function RegisterNewAdmin() {
  const [formData, setFormData] = useState(formInitialData)
  const [passError, setPassError] = useState(false)

  const handleChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (formData.password !== formData.password_confirmation) {
      toast.error('Password and Confirm Password do not match!');
      return;
    }
    registerNewAdmin({ ...formData, usertype: "admin" }, (resp) => {
      setFormData(formInitialData)
    })
  }

  return (
    <div className="w-[100%]">
      <form className="w-[100%] md:w-[60%]" onSubmit={handleSubmit}>
        <Input
          label="Admin Name"
          placeholder="Name"
          name="name"
          required
          value={formData.name}
          onChange={handleChange} />

        <Input
          type="email"
          label="Admin Email"
          placeholder="admin@email.com..."
          name="email"
          required
          value={formData.email}
          onChange={handleChange} />

        <Input
          type="tel"
          label="Phone Number"
          placeholder="080XXXXXXXX"
          name="phone"
          required
          value={formData.phone}
          onChange={handleChange} />

        <Input
          type="password"
          label="Password"
          placeholder="enter password..."
          name="password"
          required
          value={formData.password}
          onChange={(name: string, value: string) => {
            handleChange(name, value);
            setPassError(!(/^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)));
          }}
          onBlur={() => {
            setPassError(!(/^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(formData.password)));
          }}
          hasError={passError}
          errorText='Password must 8 or more alpha-numberic, uppercase, lowercase and special characters!' />

        <Input
          type="password"
          label="Confirm Password"
          placeholder="confirm password..."
          name="password_confirmation"
          required
          value={formData.password_confirmation}
          onChange={handleChange} />

        <Button
          label="Register"
          onClick={() => null}
          type="flat"
          btnActionType="submit"
        />
      </form>
    </div>
  )
}
import { useEffect, useState } from "react";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import GetUserProduct from "../GetUserProduct";
import TabComponent from "../../../components/TabComponent";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllAjoByEmail, getAllKontributeByEmail, getAllRefundMeByEmail } from "../../../actions/adminActions";
import AzatsListWrapper from "../../../components/Azats/AzatsListWrapper";
import NotFound from "../../../components/NotFound";
import { handleShareAzatMe } from "../../../utils/common";
import KontributeAccordion from "../../../components/UserGroup/Accordion";
import TablePaginationFooter from "../../../components/TablePaginationFooter/TablePaginationFooter";
import { AccordionData } from "../../../types";
import AllAzats from "../../../components/Azats/AllAzats";
import AjoAccordion from "../../../components/AjoGroup/Accordion";
import SidePane from "../../../components/SidePane";
import ExpenseDetails from "../../../components/ExpenseDetails";
import GroupDetails from "../../../components/GroupDetails";
import AjoDetails from "../../../components/AjoDetails";


const AdminUserEntities = () => {
    const [userEmail, setUserEmail] = useState('');
    const [entities, setEntities] = useState<Record<string, any> | null>(null);

    // Refundme
    const [expense, setExpense] = useState<Record<string, any> | null>(null);
    // kontribute
    const [kontribute, setKontribute] = useState<Record<string, any> | null>(null);
    const [ajo, setAjo] = useState<Record<string, any> | null>(null);

    // get data by email for all services
    const handleGetProduct = (email: string) => {
        setUserEmail(email)
        // get data
        getAllRefundMeByEmail(1, email, setEntities);
        getAllKontributeByEmail(1, email, setEntities);
        getAllAjoByEmail(1, email, setEntities);
    }

    // pagination
    const handleRefundMePagination = (page) => {
        getAllRefundMeByEmail(page, userEmail, setEntities);
    }
    const handlKontributeePagination = (page) => {
        getAllKontributeByEmail(page, userEmail, setEntities)
    }
    const handleAjoPagination = (page) => {
        getAllAjoByEmail(page, userEmail, setEntities)
    }

    return (
        <DashboardComponentWrapper
            subTitle="User Entities"
            pageDetails="Manage all User Entities on AzatMe here."
        >
            <div>
                <GetUserProduct handleGetProduct={handleGetProduct} product={"entities"} noGetAll={true} />
                {
                    entities ?
                        (
                            <TabComponent
                                components={{
                                    // @ts-ignore
                                    'RefundMe': () => <AzatsListTab setExpense={setExpense} allRefundMes={entities.refundMes} handleCreate={() => { }} handlePagination={handleRefundMePagination} />,
                                    'Kontribute': () => <UserGroup handleMore={setKontribute} userGroup={entities.kontributes} handlePagination={handlKontributeePagination} />,
                                    'Ajo': () => <AjoGroup ajos={entities.ajos} handleMore={setAjo} handlePagination={handleAjoPagination} />
                                }}
                            />
                        )
                        :
                        (
                            <div>Search for a valid user email</div>
                        )
                }

                {/* sidepanel for refundmes */}
                <SidePane
                    onClose={() => setExpense(null)}
                    open={!!expense}
                    title={""}
                >
                    <ExpenseDetails expense={expense} handleCloseDetail={() => setExpense(null)} isMine />
                </SidePane>

                {/* sidepanel for kontributes */}
                <SidePane
                    onClose={() => setKontribute(null)}
                    open={!!kontribute}
                    title={""}
                >
                    <GroupDetails
                        group={kontribute}
                        handleCloseDetail={() => setKontribute(null)}
                        isMine
                    />
                </SidePane>

                {/* sidepanel for ajos */}
                <SidePane
                    onClose={() => setAjo(null)}
                    open={!!ajo}
                    title={""}
                >
                    <AjoDetails
                        group={ajo}
                        handleCloseDetail={() => setAjo(null)}
                        isMine
                    />
                </SidePane>
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminUserEntities;



const AzatsListTab = ({ allRefundMes, setExpense, handleCreate, handlePagination }) => {
    const [hideNav, setHideNav] = useState(false);

    const renderChildren = () => {
        return <AllAzats expenses={allRefundMes} setExpense={setExpense} setHideNav={setHideNav} handleCreate={handleCreate} handlePagination={handlePagination} />
    }

    return (
        <AzatsListWrapper>
            {renderChildren()}
        </AzatsListWrapper>
    );
};



const UserGroup = (props) => {
    const dispatch = useAppDispatch();

    const handlePagination = (payload) => {
        const page = payload.split('=')[1];
        props.handlePagination(page);
    }

    return (
        <div className="user-group mt-6 bg-white !w-full !p-0">
            {
                (!props.userGroup?.data.length) ?
                    (
                        <NotFound
                            text1={"You have not been invited to any Kontribute!"}
                            text2='Share the app with Your Friends to experience this.'
                            btnLabel='Share AzatMe.'
                            onClick={() => handleShareAzatMe(dispatch)}
                        />
                    ) : (
                        <div className="wrapper !w-full h-full">
                            <div className="min-h-[50vh]">
                                <KontributeAccordion
                                    items={props.userGroup?.data as unknown as AccordionData[]}
                                    handleMore={props.handleMore}
                                />
                            </div>
                            <TablePaginationFooter
                                total={props.userGroup?.total}
                                from={props.userGroup?.from}
                                to={props.userGroup?.to}
                                nextUrl={props.userGroup?.next_page_url}
                                prevUrl={props.userGroup?.prev_page_url}
                                handlePagination={handlePagination}
                            />
                        </div>
                    )
            }
        </div>
    )
}



const AjoGroup = (props) => {
    const handlePagination = (payload) => {
        const page = payload.split('=')[1];
        props.handlePagination(page)
    }


    return (
        <div className="user-group mt-6 bg-white !w-full !p-0">
            {
                (!props.ajos?.data) ?
                    (
                        <NotFound
                            text1="You have no Ajo yet!"
                            text2='Begin the Journey by creating your first Ajo.'
                            btnLabel='Start new Ajo.'
                            onClick={() => props.handleCreate()}
                        />
                    ) : (
                        <div className="wrapper !w-full">
                            <AjoAccordion
                                items={props.ajos?.data as unknown as AccordionData[]}
                                handleMore={props.handleMore}
                                isMine={false}
                            />
                            <TablePaginationFooter
                                total={props.ajos?.total}
                                from={props.ajos?.from}
                                to={props.ajos?.to}
                                nextUrl={props.ajos?.next_page_url}
                                prevUrl={props.ajos?.prev_page_url}
                                handlePagination={handlePagination}
                            />
                        </div>
                    )
            }
        </div>
    )
}
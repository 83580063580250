import { useCallback, useEffect, useState } from "react";
import EditIcon from "../../../assets/edit-icon.png";
import DeleteIcon from "../../../assets/delete.png";
import {
  delCategoryActionCreator,
  getCategoriesActionCreator,
  getSubCategoriesActionCreator,
  updateCategoriesActionCreator,
} from "../../../actions/dashboardActions";
import "./categories.scss";
import { useAppSelector } from "../../../app/hooks";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

const CategoriesListTab = ({ setCategoryId, category, setCategory }) => {
  const { categories } = useAppSelector((state) => state.dashboard);
  const { subCategoryObj } = useAppSelector((state) => state.dashboard);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isSubCatOpen, setIsSubCatOpen] = useState(false);
  const [data, setData] = useState({
    name: '',
  });

  useEffect(() => {
    getCategoriesActionCreator()
  }, []);

  const handleOpenEditConfirm = useCallback((category) => {
    setCategory(category);
    setData({
      name: category.name
    });
    setIsEditOpen(true);
  }, []);

  const handleCloseEditConfirm = useCallback(() => {
    setIsEditOpen(false);
  }, []);

  const handleOpenDeleteConfirm = useCallback((category) => {
    setCategory(category);
    setIsDeleteOpen(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setIsDeleteOpen(false);
  }, []);

  //fetch all subcategories for a category
  function getSubCates(categoryId) {
    setCategoryId(categoryId);
    if (!subCategoryObj[categoryId]) {
      getSubCategoriesActionCreator(categoryId);
    }
  }

  //delete category from the category list
  const delCate = () => {
    delCategoryActionCreator(category.id, () => {
      setIsDeleteOpen(false);
    });
  };

  const editCate = (categoryId) => {
    updateCategoriesActionCreator({
      categoryId,
      name: data.name,
    }, () => {
      setIsEditOpen(false);
    });
  };

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  if (categories?.length === 0 || !categories) {
    return <p>No Categories Added!</p>
  }

  return (
    <div className="categories">
      <div className="wrapper">
        {categories?.map<any>((category) => {
          return (
            <div className="accordion shadow-md" key={category.id}>
              <div className="accordion-item" style={{ minHeight: '40px' }}>
                <div className="cate-actions">
                  <button onClick={() => handleOpenEditConfirm(category)}>
                    <img src={EditIcon} alt="Edit" />
                  </button>
                  <button onClick={() => handleOpenDeleteConfirm(category)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </button>
                </div>
                <div className="icon">
                  <p>{category.name[0]}</p>
                </div>
                <h4
                  className="title cursor-pointer hover:underline hover:text-teal"
                  onClick={() => {
                    getSubCates(category.id);
                  }}
                >
                  {category.name}
                </h4>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        open={isDeleteOpen}
        onClose={handleCloseDeleteConfirm}
        overrideStyle={{
          maxWidth: "500px",
          width: "80%",
          height: "max-content",
          padding: "10px 20px",
        }}
        title="Confirm Delete Category"
      >
        <div className="confirm-delete">
          <p>
            Are You Sure you want to delete this Category? Once
            deleted, this cateory will no longer be available to
            users.
          </p>
          <div>
            <Button
              type="contained"
              label="No"
              onClick={handleCloseDeleteConfirm}
            />
            <Button
              type="contained"
              label="Yes"
              onClick={() => delCate()}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={isEditOpen}
        onClose={handleCloseEditConfirm}
        overrideStyle={{
          maxWidth: "500px",
          width: "80%",
          height: "max-content",
          padding: "20px 30px",
        }}
        title="Confirm Edit Open"
      >
        <div className="confirm-delete edit-cat-wrapper">
          <p>Edit this Category</p>
          <form>
            <Input
              label="Category Name"
              type="text"
              required
              name="name"
              value={data.name}
              onChange={handleChange}
            />
            <div className="edit-category-btn-wrapper">
              <Button
                type="contained"
                label="Cancel"
                onClick={handleCloseEditConfirm}
              />
              <Button
                type="contained"
                label="Save"
                onClick={() => editCate(category.id)}
              />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default CategoriesListTab;


const SettingsSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="stroked" fillRule="evenodd" clipRule="evenodd" d="M20.8064 7.62374L20.184 6.54364C19.6574 5.62972 18.4905 5.31444 17.5753 5.83884V5.83884C17.1397 6.09547 16.6198 6.16827 16.1305 6.04121C15.6411 5.91414 15.2224 5.59764 14.9666 5.16149C14.8021 4.88427 14.7137 4.56852 14.7103 4.24616V4.24616C14.7251 3.72934 14.5302 3.22852 14.1698 2.85779C13.8094 2.48706 13.3143 2.27799 12.7973 2.2782H11.5433C11.0367 2.27819 10.5511 2.48004 10.1938 2.83907C9.83644 3.1981 9.63693 3.68471 9.63937 4.19124V4.19124C9.62435 5.23705 8.77224 6.07693 7.72632 6.07683C7.40397 6.07348 7.08821 5.98507 6.81099 5.82053V5.82053C5.89582 5.29614 4.72887 5.61142 4.20229 6.52534L3.5341 7.62374C3.00817 8.53652 3.31916 9.70273 4.22975 10.2324V10.2324C4.82166 10.5742 5.18629 11.2057 5.18629 11.8892C5.18629 12.5727 4.82166 13.2042 4.22975 13.5459V13.5459C3.32031 14.0721 3.00898 15.2355 3.5341 16.1455V16.1455L4.16568 17.2347C4.4124 17.6799 4.82636 18.0084 5.31595 18.1476C5.80554 18.2867 6.3304 18.225 6.77438 17.9761V17.9761C7.21084 17.7215 7.73094 17.6517 8.2191 17.7823C8.70725 17.913 9.12299 18.2332 9.37392 18.6718C9.53845 18.949 9.62686 19.2648 9.63021 19.5871V19.5871C9.63021 20.6437 10.4867 21.5002 11.5433 21.5002H12.7973C13.8502 21.5002 14.7053 20.6492 14.7103 19.5963V19.5963C14.7079 19.0882 14.9086 18.6001 15.2679 18.2408C15.6272 17.8816 16.1152 17.6808 16.6233 17.6832C16.9449 17.6918 17.2594 17.7799 17.5387 17.9395V17.9395C18.4515 18.4655 19.6177 18.1545 20.1474 17.2439V17.2439L20.8064 16.1455C21.0615 15.7076 21.1315 15.1861 21.001 14.6965C20.8704 14.2069 20.55 13.7895 20.1108 13.5368V13.5368C19.6715 13.2841 19.3511 12.8667 19.2206 12.3771C19.09 11.8874 19.16 11.3659 19.4151 10.9281C19.581 10.6385 19.8211 10.3983 20.1108 10.2324V10.2324C21.0159 9.70302 21.3262 8.54362 20.8064 7.63289V7.63289V7.62374Z" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <circle className='stroked' opacity="0.4" cx="12.1752" cy="11.8892" r="2.63616" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

export default SettingsSVG;

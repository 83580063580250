import { FormEvent, useCallback, useEffect, useState } from 'react';
import Input, { Select as SelectComponent } from '../Input';
import Button from '../Button';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { 
  addExpenseActionCreator, 
  getSubCategoriesActionCreator, 
  addUserToExpenseActionCreator,
  getSplitformula,
  getContacts,
  getExpenses,
} from '../../actions/dashboardActions';
import { Category, SubCategry } from '../../pages/Dashboard/dashboardSlice';
import toast from 'react-hot-toast';
import { CustomSelect } from '../MultiSelect';
import { Bank } from '../../pages/Bank/bankSlice';
import { navigatorShare } from '../../utils/common';

const CreateExpense = (props) => {
  const {categories, splitFormula} = useAppSelector((state) => state.dashboard);
  const {isLoading} = useAppSelector(state => state.loader);
  const {user} = useAppSelector((state) => state.auth);
  const [subCategories, setSubCategories] = useState<Array<SubCategry>>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [participants, setParticipants] = useState<Array<Record<string, any>>>([]);
  const [splitPayResult, setSplitPayResult] = useState<Array<Record<string, any>>>([]);
  const [participantsMap, setParticipantMap] = useState<Record<string, any>>({});
  const [percentageMap, setPercentageMap] = useState<Record<string, any>>({});
  const [step, setStep] = useState(0);
  // const [showAddPartForm, setShowAddPartForm] = useState(true);
  const [splitId, setSplit] = useState<number>(0);
  const [single, setSingle] = useState<Record<string, any>>({});
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [data, setData] = useState({
    name: '',
    total: '',
    category: '',
    subcategory: '',
    description: '',
    actual_amount: '',
    id: '',
    splittingId: '',
    bankName: '',
    uniqueCode: '',
  });

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  useEffect(() => {
    setSplit(splitFormula?.find((item) => item.split === data.splittingId)?.id as number)
  }, [data.splittingId, splitFormula]);

  useEffect(() => {
    !splitFormula && getSplitformula();
  }, [splitFormula]);

  useEffect(() => {
    if (data.category) {
      const selectedCat = categories?.find((categories) => categories.name === data.category);
      setSelectedCategory(selectedCat);
      if (selectedCat && !selectedCat.subCategories) {
        getSubCategoriesActionCreator(selectedCat.id);
      } else {
        setSubCategories(selectedCat?.subCategories);
      }
    }
  }, [data.category, categories]);

  const isFirst = step === 0;
  const isSecond = step === 1;
  const isThird = step === 2;
  const renderStep = () => {
    return (
      <div className="stepper">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isThird ? 'active' : ''}`} />
      </div>
    )
  };

  const handleAddFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    // if (!data.bankName) {
    //   toast.error('Please select a Recieving Bank Account!')
    //   return;
    // }
    if (parseInt(data.total) > parseInt(data.actual_amount)) {
      toast.error('Amount to be refunded can not be greater than amount spent!')
      return;
    }
    if (parseInt(data.total) <= 0) {
      toast.error('Amount to be refunded must be greater than 0!')
      return;
    }
    const selectedSubCategory = selectedCategory?.subCategories?.find((subCat) => subCat.name === data.subcategory);
    addExpenseActionCreator({
      name: data.name,
      category_id: selectedCategory?.id,
      subcategory_id: selectedSubCategory?.id,
      amount: data.total,
      description: data.description,
      actual_amount: data.actual_amount,
    }, (id, uniqueCode) => {
      setStep(1);
      handleChange('id', id)
      handleChange('uniqueCode', uniqueCode);
    });
  }

  const addParticipants = (payload) => {
    participants.push(payload);
    const newparticipantsMap = {...participantsMap};
    newparticipantsMap[payload.email] = 1;
    setParticipantMap(newparticipantsMap);
    setParticipants([...participants]);
  }

  const handleAddParticipants = (userData: Record<string, any>) => {
    addParticipants({
      ...userData,
    });
  }

  const handleSetPercentageMap = useCallback((email: string, percentage) => {
    setPercentageMap((prevState) => ({
      ...prevState,
      [email]: percentage
    }));
  }, [])

  const handleRemoveParticipant = useCallback((data) => {
    setParticipants(participants.filter((participant) => participant.email !== data));
    const newparticipantsMap = {...participantsMap};
    delete newparticipantsMap[data];
    setParticipantMap({...newparticipantsMap});
    if (percentageMap[data] !== undefined) {
      const newObj = {...percentageMap};
      delete newObj[data];
      setPercentageMap({...newObj});
    }
  }, [participants, participantsMap, percentageMap])

  const handleDone = () => {
    if (!splitId) {
      toast.error('Select a Splitting Formula to continue!');
      return;
    };
    if (participants?.length === 0) {
      toast.error('Add at least 1 participant to continue!');
      return;
    }
    const payload: Record<string, any> = {
      email: (participants.map((participant) => participant.email)).join(';'),
      split_method_id: splitId,
      bankName: selectedBank?.bankName,
      bankCode: selectedBank?.bankCode,
      account_number: selectedBank?.account_number,
    };
    if (splitId === 1) {
      let totalPercentage = 0;
      const userWtError: Array<string> = [];
      const members = Object.entries(percentageMap);
      members.forEach((item) => {
        totalPercentage += parseFloat(item[1]);
        if (item[1] <= 0) {
          userWtError.push(item[0]);
        }
      });
      if (userWtError.length > 0) {
        toast.error(`All participant must have more than 0 percent, check; \n ${userWtError.join('\n')}`);
        return;
      }
      if (totalPercentage !== 100) {
        toast.error(`Selected Percentage ${totalPercentage < 100 ? 'less' : 'more'} than 100!`);
        return;
      }
      payload.percentage_per_user = JSON.stringify(percentageMap);
      if (members.length === 2 && parseFloat(members[0][1]) === 50) {
        payload.percentage = 50;
      }
    }
    addUserToExpenseActionCreator({
      payload,
      expenseId: data.id,
    }, (data) => {
      setStep(2);
      getExpenses(1);
      if (data.splitPayResult) {
        setSplitPayResult(data.splitPayResult.result); 
      } else {
        setSingle(data);
      }
    });
  }

  return (
    <div className=" w-full h-full flex flex-col justify-between">
      <div className='mt-2 flex flex-col justify-center items-start'>
        {renderStep()}
        <h3 className='my-6 text-3xl text-center'>{step === 0 ? 'Create RefundMe' : 'Add Participants'}</h3>
      </div>
      <div className='mt-6 py-10 flex w-full flex-grow flex-auto justify-center items-center'>
        {
          isFirst && (
            <form onSubmit={handleAddFormSubmit} className="">
              <Input
                label="Reason"
                placeholder="Eat out"
                name="name"
                required
                value={data.name}
                onChange={handleChange}
              />
              <div className="input-grp-wrapper">
                <Input
                  label="Actual Amount Spent"
                  placeholder="0"
                  type="number"
                  name="actual_amount"
                  required
                  value={data.actual_amount}
                  onChange={handleChange}
                />
                <Input
                  label="Amount to Refund"
                  placeholder="0"
                  name="total"
                  required
                  value={data.total}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="input-grp-wrapper">
                <SelectComponent
                  data={categories?.map((category) => category.name) || []}
                  label="Category"
                  placeholder="Category"
                  name="category"
                  required
                  value={data.category}
                  onChange={handleChange}
                />
                <SelectComponent
                  data={(selectedCategory?.subCategories || subCategories)?.map((category) => category.name) || []}
                  label="Sub Category"
                  placeholder="Sub Category"
                  required
                  name="subcategory"
                  value={data.subcategory}
                  onChange={handleChange}
                />
              </div>
              <Input
                label="Description"
                placeholder="Description"
                name="description"
                required
                value={data.description}
                onChange={handleChange}
                maxLength={100}
              />
              <Button
                label="Create "
                type="flat"
                btnActionType="submit"
                disabled={isLoading}
              />
            </form>
          )
        }
        {
          isSecond && (
            <form className='user-group-cont'>
              <SelectComponent
                data={(splitFormula as unknown as Array<any>)?.filter((splitItem) => splitItem.split !== 'fixed').map((item) => item.split) || []}
                label="Spliting Formula"
                placeholder=""
                name="splittingId"
                required
                value={data.splittingId}
                onChange={handleChange}
              />
              <div className='user-group-wrapper w-full'>
                {participants?.length === 0 && <p className="empty-part-text text-center">Your added participants will be displayed here, enter their email address in the input below to begin, click the Add button to add them and Done to complete the action.</p>}
              {/* https://www.npmjs.com/package/react-web-share */}
              {/* https://medium.com/@jsphkhan/share-like-native-mobile-apps-with-web-share-8be6f97cd41a */}
              {participants?.length > 0 && ( <div>
                  {
                    [...participants].reverse().map((participant) => (
                      <Participant
                        key={participant.email}
                        email={participant.email}
                        splitId={splitId}
                        handleRemove={handleRemoveParticipant}
                        // percentage={percentageMap[participant.email] || 0}
                        handleSetPercentage={handleSetPercentageMap}
                      />
                    ))
                  }
                </div>)}
              </div>
              <AddParticipantForm
                addParticipant={handleAddParticipants}
                participants={participantsMap}
                setShowPartForm={handleDone}
                doneBtnText='Done'
                from="refundme"
              />
              {/* {
                participants.length > 0 && !showAddPartForm && (
                  <div className='btn-group'>
                    <Button
                      label="Add More"
                      onClick={() => setShowAddPartForm(true)}
                      type="flat"
                      btnActionType="button"
                      disabled={isLoading}
                      className='secondary-btn'
                    />
                    <Button
                      label="Save"
                      onClick={handleDone}
                      type="flat"
                      btnActionType="button"
                      disabled={isLoading}
                    />
                  </div>
                )
              } */}
            </form>
          )
        }
        {
          isThird && (
            <div className="">
              <h4 style={{textAlign: 'center'}}>Users Added, and a mail containing payment link sent to their email. 
              <br />
              <small>Kindly advise the recipients to check their spam or junk folder if they are unable to locate the email in their primary inbox.</small>
              <br/> Click the share button to manually share to other media.</h4>
              <div className='user-group-wrapper my-10'>
              {/* https://medium.com/@jsphkhan/share-like-native-mobile-apps-with-web-share-8be6f97cd41a */}
              {splitPayResult?.length > 0 ? ( <div>
                  {
                    splitPayResult.reverse().map((participant) => (
                      <ShareParticipant
                        key={participant.receipient}
                        email={participant.receipient}
                        url={participant.paylink}
                        title={`AzatMe RefundMe ${data.name} by ${user?.name} | ${user?.email}`}
                        text={`You are expected to pay ${participant.amount} for ${data.description}. To pay click `}
                      />
                    ))
                  }
                </div>) : participants?.length === 1 ? (
                  <ShareParticipant
                    email={participants[0].email}
                    url={single.payLink}
                    title={`AzatmMe Kontribute ${data.name} by ${user?.email}`}
                    text={`You are expected to pay NGA ${data.total} for ${data.description}. To pay click `}
                  />
                ) : ''}
              </div>
              <Button
                type="flat"
                label='Done'
                onClick={() => {
                  props.handleDone()
                }}
              />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CreateExpense;

export const AddParticipantForm = ({ addParticipant, participants, overrideInputDisplay = {}, setShowPartForm, doneBtnText = 'Close', from }) => {
  const {users} = useAppSelector((state) => state.dashboard);

  useEffect(() => {
    !users && getContacts();
  }, [users]);

  const handleSubmit = (type, data, email) => {
    if (participants[email]) {
      toast.error('Participant already added!');
      return
    }
    if (type === 'add') {
      addParticipant({user_id: data, email});
    } else {
      addParticipant({email: data});
    }
  }
  if (!users) return null;
  return (
    <div className='add-participant-wrapper w-full'>
      <CustomSelect
        value={{}}
        name=""
        data={users || []}
        handleAddMember={handleSubmit}
        participants={participants}
        overrideInputDisplay={overrideInputDisplay}
        handleSetShowPartForm={setShowPartForm}
        doneBtnText={doneBtnText}
        from={from}
      />
    </div>
  )
}

export const Participant = ({email, splitId, handleRemove, percentage = 0, handleSetPercentage}) => {
  const [percent, setPercentage] = useState('');
  const isPercentage = splitId === 1;

  useEffect(() => {
    if (isPercentage && percentage > 0) {
      setPercentage(percentage.toString());
      handleSetPercentage(email, percentage);
    }
  }, []);

  return (
    <div className='particpant-wrapper !flex !flex-row !justify-between !items-center' key={email}>
      <div style={{
        width: isPercentage ? '80%' : '80%'
      }}>
        <p className='logo'>{email[0]}</p>
        <p className='email'>{email}</p>
      </div>
      {isPercentage && (
        <input
          className='!mb-0 border border-black text-sm rounded'
          type="text"
          onChange={(event) => {
            // @ts-ignore
            if (isNaN(event.target.value)) {
              return;
            }
            setPercentage(event.target.value);
            handleSetPercentage(email, event.target.value);
          }}
          value={percent}
          placeholder='10%'
        />)
      }
      <button
        type='button'
        onClick={() => handleRemove(email)}
      >
        x
      </button>
    </div>
  )
}

export const ShareParticipant = ({email, url, title, text}) => {
  const {user} = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const handleShare = () => {
    navigatorShare({
      title,
      text,
      url,
      email,
    }, dispatch);
  }

  return (
    <div className='particpant-wrapper !my-2' key={email}>
      <div>
        <p className='!w-[40px] !h-[40px] leading-3 border border-black flex justify-center items-center mr-2 !p-0 !py-0 uppercase text-2xl'>
          {email[0]}
        </p>
        <p className='email'>{email}{email === user?.email ? '(You)' : ''}</p>
      </div>
      <button
        type='button'
        onClick={handleShare}
        className="share"
      >
        Share
      </button>
      {/* <button
        type='button'
        onClick={handleShare}
        className="share"
      >
        S
      </button> */}
    </div>
  )
}

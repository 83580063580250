import searcIcon from "../voucher-assets/icons/search-icon.svg"

export default function SearchVoucherInput({ className }: { className?: string }) {
  return (
    <div className={`w-[300px] flex items-center justify-center gap-2 p-[10px] rounded-full border border-[#2222224D] 
    ${className}`}>
      <img src={searcIcon} alt="search" />
      <input
        type="search"
        className='w-[100%] h-[25px] border-none focus:!border-none focus:!outline-none bg-transparent'
        placeholder='Search'
      />
    </div>
  )
}

import { useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../../components/PageLoader"
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { completVerification, geProfileSilent } from "../../actions/authActions";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

const BVNVerificationRedirect = () => {
    const location = useLocation() as any;
    const navigate = useNavigate();
    const [errorVerifying, setErrorVerifying] = useState(false);
    useEffect(() => {
        handleGetAccessToken();
    }, [location.search]);
    const handleGetAccessToken = async () => {
        let accessToken = location.search ? location.search.split('&')[0] : '';
        if (!location.search || accessToken.split('=')[0] !== '?access_token') {
            navigate('/dashboard/profile');
            toast.error('BVN verification failed, please try again!');
            return
        }
        accessToken = accessToken.split('=')[1]
        completVerification({accessToken}, (resp) => {
          geProfileSilent(() => {
            navigate('/dashboard/profile');
          })
        }, () => {
            setErrorVerifying(true);
        });
    }
    return (
        <div className="w-full h-full flex justify-center items-center">
            { !errorVerifying ? <PageLoader /> : (
                <Modal
                open={errorVerifying}
                onClose={() => {
                    setErrorVerifying(false);
                    navigate('/dashboard/profile');
                }}
                overrideStyle={{
                  maxWidth: '400px',
                  width: '100%',
                  height: 'max-content',
                  maxHeight: '70%',
                  border: '1px solid red',
                }}
                title="Confirm data."
              >
                <div className="flex flex-col">
                  <div className="my-4">We could not verify you according to CBN provided guidelines/policies. You will need to provide a government issued identification to verify you, click here to begin manual verification or check your profile data for any wrongly entered info. Thanks!</div>
                  <div className="flex flex-row w-full justify-between items-center">
                    <Button
                      overrideStyle={{width: '100px'}}
                      type="flat"
                      onClick={() => {
                        setErrorVerifying(false);
                        navigate('/dashboard/profile');
                        }}
                      label="Confirm"
                    />
                  </div>
                </div>
              </Modal>
            )}
        </div>
    )
};

export default BVNVerificationRedirect;

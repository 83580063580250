import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAjoInvitation, getUserAjo, getUserAjoSilent } from "../../actions/dashboardActions";
import { AccordionData } from "../../types";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import Accordion from "./Accordion";
import "./userGroup.scss"
import { useEffect, useState } from "react";
import DashboardNavItem from "../DashboardNavItem/DashboardNavItem";
import NotFound from "../NotFound";
import { handleShareAzatMe } from "../../utils/common";
import useVerificationDone from "../../hooks/useVerificationDone";

const kontributeNavItems = [
  'Created By Me',
  'Invited To'
  // 'In Progress', 'Completed'
];

const AjoGroup = (props) => {
  const [tab, setTab] = useState(kontributeNavItems[0]);
  const {ajo, ajoInvitation} = useAppSelector((state) => state.dashboard);
  const {isVerificationDone} = useVerificationDone();
  const dispatch = useAppDispatch();
  const isMine = tab === kontributeNavItems[0]

  const selectedGrpType: Record<string, any> | undefined | null = isMine ? ajo : ajoInvitation?.getInvitationInvitedTo;

  useEffect(() => {
    if (isVerificationDone) {
      if (!ajo) {
        getUserAjo(1);
      }
      if (!ajoInvitation) {
        getAjoInvitation(1);
      }
    }
  }, [isVerificationDone]);

  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    // getUserGroupsActionCreator(page);
    // getUserAjo(page);
    if (isMine) {
      getUserAjoSilent(page)
    } else {
      getAjoInvitation(page)
    }
  }

  const renderNavItems = () => {
    return (
      <DashboardNavItem 
        isSecondTier 
        navItems={kontributeNavItems} 
        tab={tab}
        setTab={setTab} 
      />
    )
  }

  return (
    <div className="user-group mt-6 bg-white !w-full !p-0">
      <div className="px-6" style={{width: '100%'}}>
        {renderNavItems()}
      </div>
      {
        (!selectedGrpType || (selectedGrpType?.data && !selectedGrpType?.data.length)) ?
        (
          <>
            {
              isMine ? (
                <NotFound 
                  text1="You have no Ajo yet!"
                  text2='Begin the Journey by creating your first Ajo.'
                  btnLabel='Start new Ajo.'
                  onClick={() => props.handleCreate()}
                />
              ) : (
                <NotFound 
                  text1="You have not been invited to any Ajo!"
                  text2='Share the app with Your Friends to experience this.'
                  btnLabel='Share AzatMe.'
                  onClick={() => handleShareAzatMe(dispatch)}
                />
              )
            }
          </>
        ) : (
          <div className="wrapper !w-full">
            <Accordion
              items={selectedGrpType.data as unknown as AccordionData[]}
              handleMore={props.handleMore}
              isMine={isMine}
            />
            <TablePaginationFooter
              total={selectedGrpType?.total}
              from={selectedGrpType?.from}
              to={selectedGrpType?.to}
              nextUrl={selectedGrpType?.next_page_url}
              prevUrl={selectedGrpType?.prev_page_url}
              handlePagination={handlePagination}
            />
          </div>
        )
      }
    </div>
  )
}

export default AjoGroup;

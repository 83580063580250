import React, { type Dispatch } from "react";

interface IAuthContext {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<React.SetStateAction<boolean>>
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  setIsAuthenticated: () => { }
});
import { useEffect, useState } from "react"
import { useAppSelector } from "../app/hooks";
import useUserType from "./useUserType";
import { getMerchantKYCData, getSponsorKYCData } from "../actions/authActions";

const useVerificationDone = () => {
  const { user, merchantSponsorKYC } = useAppSelector((state) => state.auth);
  const { isIndividual } = useUserType()
  const [isVerificationDone, setIsVerificationDone] = useState(false);
  const [pendingVerification, setPendingVerification] = useState({
    bvn: true,
    nimc: true,
    fullname: true,
  });

  useEffect(() => {
    if (isIndividual) {
      if (
        user?.bvn && user?.nimc && user?.first_name && user?.last_name && user?.middle_name
        && user?.isVerified == 1
      ) {
        setIsVerificationDone(true);
        setPendingVerification({
          bvn: !user.bvn,
          nimc: !user.nimc,
          fullname: !(user?.first_name && user?.last_name && user?.middle_name),
        })
      }
    }
    else {
      if (merchantSponsorKYC?.status === "approved") {
        setIsVerificationDone(true);
      } else {
        setIsVerificationDone(false);
      }
    }
  }, [merchantSponsorKYC, user]);

  return { isVerificationDone, pendingVerification };
};

export default useVerificationDone;

import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";

const AdminSubscriptions = () => {
    return (
        <DashboardComponentWrapper
            subTitle="Subscriptions"
            pageDetails="Here's an overview of all Subscriptions on AzatMe."
        >
            <div>
                    Admin Subscriptions
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminSubscriptions;

import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import TabComponent from "../../../components/TabComponent";
import AdminWithdrawalHistory from "../../../components/AdminWithdrawals/AdminWithdrawalHistory";
import { getAllAjoWithdrawals, getAllBusinessWithdrawals, getAllExpenseWithdrawals, getAllGroupWithdrawals } from "../../../actions/adminActions";
import SidePane from "../../../components/SidePane";
import { useState } from "react";
import AdminWithdrawalInfo from "../../../components/AdminWithdrawals/AdminWithdrawalInfo";

const AdminWithdrawals = () => {
    const [withdrawalDetails, setWithdrawalDetails] = useState(null)

    return (
        <DashboardComponentWrapper
            subTitle="Withdrawals"
            pageDetails="Here's an overview of all Withdrawals on AzatMe."
        >
            <>
                <TabComponent
                    components={{
                        // @ts-ignore
                        "RefundMe History": () => <AdminWithdrawalHistory type={"RefundMe"} getFunction={getAllExpenseWithdrawals} setDetails={setWithdrawalDetails} />,
                        // @ts-ignore
                        "Kontribute History": () => <AdminWithdrawalHistory type={"Kontribute"} getFunction={getAllGroupWithdrawals} setDetails={setWithdrawalDetails} />,
                        // @ts-ignore
                        "Ajo History": () => <AdminWithdrawalHistory type={"Ajo"} getFunction={getAllAjoWithdrawals} setDetails={setWithdrawalDetails} />,
                        "Business History": () =>
                            // @ts-ignore
                            <AdminWithdrawalHistory type={"Business"} getFunction={getAllBusinessWithdrawals} setDetails={setWithdrawalDetails} />,
                    }}
                />
                <SidePane onClose={() => setWithdrawalDetails(null)} open={!!withdrawalDetails} title={"Withdrawal"}>
                    <AdminWithdrawalInfo details={withdrawalDetails} />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminWithdrawals;


const BusinessTransactions = () => {
    return (
        <div>
            Business
        </div>
    )
}
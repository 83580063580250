import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getProducts } from "../../../actions/businessActions";

export default function BusinessDetails(props) {
  const [showProducts, setShowProducts] = useState(true)
  const dispatch = useAppDispatch();
  const productsObj = useAppSelector((state) => state.business.products);
  const products = productsObj[props.business_code as string];

  useEffect(() => {
    if (!products) {
      props?.business_code && getProducts(dispatch, (props?.business_code || ""), 1);
    }
  }, [props?.business_code, products]);

  return (
    <div className="c-expense-details w-full flex !flex-col">
      <p className="text-2xl border-b-2 border-gray-300 pb-6">Business Summary</p>
      <div className="my-2 flex flex-row w-full justify-between items-center">
        <p>{props?.business_name || "Business"}</p>
        <button className="flex flex-row px-4 w-fit bg-[#0898A01A] h-10 justify-center items-center text-az-teal">Transactions</button>
      </div>
      <div className="right mt-1 p-4 w-full !rounded-sm !bg-[#0898A008]">
        <div className="right-wrapper">
          <div className="flex flex-col w-full">
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Date</p>
              <p className="text-sm text-az-black-900">{dayjs(props.created_at).fromNow()}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">RC</p>
              <p className="text-sm text-az-black-900 normal-case">{props?.business_code}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Owner</p>
              <p className="text-sm text-az-black-900 normal-case">{props?.email}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Owner ID</p>
              <p className="text-sm text-az-black-900">{props?.owner_id}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Category</p>
              <p className="text-sm text-az-black-900">{props?.type}</p>
            </div>
          </div>
        </div>
        <div className="left w-full">
          <div className="flex flex-row">
            <h3
              onClick={() => setShowProducts(true)}
              className={`my-2 p-0 mr-3 cursor-pointer ${showProducts ? 'border-b-2 border-az-teal' : ''}`}>
              Products/Services
              ({products?.length})
            </h3>
            {
              products?.length === 0 ? (
                <p>No products to show!</p>
              ) : (
                <div className="">
                  {
                    products?.map((singleProduct, index) => {
                      return (
                        <div className="">singleProduct</div>
                      )
                    })
                  }
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}
import Expense from '../../assets/expense.png';
import NQR from '../../assets/nqr.png';
import UserGroupImage from '../../assets/usergroup.png';
import HomeSVG from '../../components/SVG/Home';
import BankSVG from '../../components/SVG/Bank';
import DashboardSVG from '../../components/SVG/Dashboard';
import KontributeSVG from '../../components/SVG/Kontribute';
import RefundMeSVG from '../../components/SVG/RefundMe';
import UserSVG from '../../components/SVG/User';
import BusinessIcon from '../../assets/business1.png';
import Profile from '../../assets/profile.png';
import Overview from '../../assets/overview.png';
import './businessDashboard.scss';
import DashboardSideBar from '../../components/DashboardSideBar';
import { Outlet, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getBusinesses, getNQRMerchants } from '../../actions/businessActions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getLedger } from '../../actions/authActions';
import Bank from '../../assets/bank.png';
import ProfileSVG from '../../components/SVG/Profile';
import NQRSVG from '../../components/SVG/NQR';
import MPOSSVG from '../../components/SVG/MPOS';
import useVerificationDone from '../../hooks/useVerificationDone';

const BusinessDashboard = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const business = useAppSelector((state) => state.business);
  const { isVerificationDone } = useVerificationDone();
  const params = useParams();
  const businessId = params.id;

  useEffect(() => {
    if (isVerificationDone) {
      if (Object.entries(auth.ledger).length === 0) {
        getLedger();
      }
    }
  }, [isVerificationDone]);

  useEffect(() => {
    if (isVerificationDone) {
      !business.businesses && getBusinesses(dispatch, 1, auth?.user?.id);
      !business.nqrs && getNQRMerchants();
    }
  }, [auth?.user?.id, business.businesses, isVerificationDone]);


  const navItems = [
    {
      name: "businesses",
      path: `/business`,
      title: "All Businesses",
      img: BusinessIcon,
      Icon: HomeSVG
    },
    {
      name: "bank",
      path: `/business/bank`,
      title: "Bank",
      img: Bank,
      Icon: BankSVG
    },
    {
      name: "overview",
      path: `/business/dashboard/${businessId}`,
      title: "Overview",
      img: Overview,
      Icon: DashboardSVG
    },
    {
      name: "inventory",
      path: `/business/dashboard/${businessId}/inventory`,
      title: "Inventory",
      img: Expense,
      Icon: KontributeSVG
    },
    {
      name: "customers",
      path: `/business/dashboard/${businessId}/customers`,
      title: "Customers",
      img: UserGroupImage,
      Icon: UserSVG,
    },
    {
      name: "invoices",
      path: `/business/dashboard/${businessId}/invoices`,
      title: "Invoices",
      img: Expense,
      Icon: RefundMeSVG
    },
    {
      name: "directlinks",
      path: `/business/dashboard/${businessId}/directlinks`,
      title: "Direct Links",
      img: Expense,
      Icon: RefundMeSVG
    },
    {
      name: "nqr",
      path: `/business/dashboard/${businessId}/nqr`,
      title: "NGR",
      img: NQR,
      Icon: NQRSVG
    },
    {
      name: "mpos",
      path: `/business/dashboard/${businessId}/mpos`,
      title: "mPOS",
      img: NQR,
      Icon: MPOSSVG
    },
    {
      name: "profile",
      path: `/business/dashboard/${businessId}/profile`,
      title: "Profile",
      img: Profile,
      Icon: ProfileSVG
    },
  ];

  return (
    <div className="dashboard">
      <DashboardSideBar userType='Merchant' firstItem="Overview" items={navItems} showVerificationStatus={false} />
      <div className="outlet">
        <Outlet />
      </div>
    </div>
  );
};

export default BusinessDashboard;

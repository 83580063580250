import { useEffect } from "react";
import { getLedger } from "../../actions/authActions";
import { useAppSelector } from "../../app/hooks";
import Refund from '../../assets/new/RefundIconOnly.png';
import Kontribute from '../../assets/new/KontributeIconOnly.png';
import SidePane from "../SidePane";
import './index.scss';
import { formatCurrency } from "../../utils/common";
import useVerificationDone from "../../hooks/useVerificationDone";


export const LegderModal = ({ isOpen, handleClose, type, title = 'Ledger' }) => {
  const { ledger } = useAppSelector(state => state.auth);
  const { isVerificationDone } = useVerificationDone();

  useEffect(() => {
    if (isVerificationDone) {
      getLedger();
    }
  }, []);

  const itemsData = {
    refundme: {
      icon: Refund,
      data: [
        {
          title: 'Withdrawal Balance',
          value: ledger.AmountLeftForWithdrawRefundme || 0,
          color: '#0898A0',
          bgColor: '#0898A01A',
        },
        {
          title: 'Total Amount Withdrawn',
          value: ledger.UserExpenseTransactionsWithdrawn || 0,
          color: '#00AAE0',
          bgColor: '#00AAE01A'
        },
        {
          title: 'Total Paid by Participants',
          value: ledger.TotalSumReceivedFromPaythruOnExpense || 0,
          color: '#27AE60',
          bgColor: '#27AE601A'
        },
        {
          title: 'Total Pending Payments',
          // @ts-ignore
          value: ledger.Wallet?.amountExpectedRefundMe || 0,
          color: '#FB9701',
          bgColor: '#FB97011A'
        },
      ]
    },
    kontribute: {
      icon: Kontribute,
      data: [
        {
          title: 'Withdrawal Balance',
          value: ledger.AmountLeftForWithdrawGroup || 0,
          color: '#0898A0',
          bgColor: '#0898A01A',
        },
        {
          title: 'Total Amount Withdrawn',
          value: ledger.UserKontributeTransactionsWithdrawn || 0,
          color: '#00AAE0',
          bgColor: '#00AAE01A'
        },
        {
          title: 'Total Paid by Participants',
          value: ledger.TotalSumReceivedFromPaythruOnKontribute || 0,
          color: '#27AE60',
          bgColor: '#27AE601A'
        },
        {
          title: 'Total Pending Payments',
          // @ts-ignore
          value: ledger.Wallet?.amountExpectedKontribute || 0,
          color: '#FB9701',
          bgColor: '#FB97011A'
        },
      ]
    },
    business: {
      icon: Refund,
      data: [
        {
          title: 'Withdrawal Balance',
          value: ledger.AmountLeftForWithdrawBusiness || 0,
          color: '#0898A0',
          bgColor: '#0898A01A',
        },
        {
          title: 'Total Amount Withdrawn',
          value: ledger.UserBusinessTransactionsWithdrawn || 0,
          color: '#00AAE0',
          bgColor: '#00AAE01A'
        },
        {
          title: 'Total Paid by Participants',
          value: ledger.TotalSumReceivedFromPaythruOnBusiness || 0,
          color: '#27AE60',
          bgColor: '#27AE601A'
        },
        {
          title: 'Total Pending Payments',
          // @ts-ignore
          value: ledger.Wallet?.amountExpectedBusiness || 0,
          color: '#FB9701',
          bgColor: '#FB97011A'
        },
      ]
    },
    ajo: {
      icon: Kontribute,
      data: [
        {
          title: 'Withdrawal Balance',
          value: ledger.AmountLeftForWithdrawalAjo || 0,
          color: '#0898A0',
          bgColor: '#0898A01A',
        },
        // {
        //   title: 'Total Amount Withdrawn',
        //   value: ledger.UserBusinessTransactionsWithdrawn || 0,
        //   color: '#00AAE0',
        //   bgColor: '#00AAE01A'
        // },
        {
          title: 'Total Paid by Participants',
          value: ledger.TotalSumReceivedFromPaythruOnAjo || 0,
          color: '#27AE60',
          bgColor: '#27AE601A'
        },
        // {
        //   title: 'Total Pending Payments',
        //   // @ts-ignore
        //   value: ledger.Wallet?.amountExpectedBusiness || 0,
        //   color: '#FB9701',
        //   bgColor: '#FB97011A'
        // },
      ]
    }
  }

  const data = itemsData[type];


  return (
    <SidePane
      open={isOpen}
      onClose={handleClose}
      title={title}
      details={`Here is an overview of your ${type} activities `}
    >
      <div className="ledger w-full flex flex-col">
        {!ledger?.Wallet ? 'Loading' : (
          <>
            {
              data?.data?.map((itm, index) => (
                <div key={index} style={{ background: itm.bgColor }} className="w-full flex flex-row h-28 my-4 rounded-lg justify-start items-center px-8">
                  <div style={{ background: itm.color }} className="mr-4 w-12 h-12 rounded-full flex flex-row justify-center items-center">
                    <img src={data.icon} alt="" className="object-contain w-6/12" />
                  </div>
                  <div>
                    <p className="text-xs">{itm.title}</p>
                    <p>NGN {formatCurrency({ num: itm.value, withSymbol: false })}</p>
                  </div>
                </div>
              ))
            }
          </>
        )}
      </div>
    </SidePane>
  )
};

export default LegderModal;

import './registerBusiness.scss';
import { FormEvent, useCallback, useState } from 'react';
import Input, { Select as SelectComponent } from '../Input';
import Button from '../Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { businessCategory } from '../../utils/data';
import { completeBusinessProfile, createBusiness, optInToNQR } from '../../actions/businessActions';
import BankInput from '../BankInput';

const RegisterBusiness = (props) => {
  const { isLoading } = useAppSelector(state => state.loader);
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);
  const [bank, setBank] = useState<Record<string, string>>({});
  const [data, setData] = useState({
    name: '',
    type: '',
    logo: '',
    phone: '',
    email: '',
    description: '',
    vatId: '',
    address: '',
    businessRC: '',
    businessTIN: '',
    vat_option: '',
    enableNQR: '',
  });

  const [businessKYCVerified, setBusinessKYCVerified] = useState(true)

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const isFirst = step === 0;
  const isSecond = step === 1;
  const isThird = step === 2;
  const isForth = step === 3;

  const renderStep = () => {
    return (
      <div className="stepper my-4 !justify-start">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
        {
          data.enableNQR === 'Yes' && (
            <>
              <div className="dash" />
              <div className={`step ${isThird ? 'active' : ''}`} />
            </>
          )
        }
        {
          !businessKYCVerified && (
            <>
              <div className="dash" />
              <div className={`step ${isForth ? 'active' : ''}`} />
            </>
          )
        }
      </div>
    )
  };

  const handleCreateBusiness = () => {
    const businessData = new FormData();
    businessData.append('business_name', data.name);
    businessData.append('business_email', data.email);
    businessData.append('business_address', data.address);
    businessData.append('business_logo', data.logo);
    businessData.append('type', data.type);
    businessData.append('vat_id', data.vatId);
    businessData.append('vat_option', data.vat_option);
    businessData.append('business_phone', data.phone);
    businessData.append('description', data.description);
    businessData.append('registration_number', data.businessRC);
    createBusiness(dispatch, businessData, (resp) => {
      if (resp && data.enableNQR === 'Yes') {
        optInToNQR({
          "name": data.name,
          "tin": data.businessTIN,
          "contact": data.address,
          "phone": data.phone,
          "email": data.email,
          "address": data.address,
          "bankNo": bank.bankCode,
          "accountName": bank.account_name,
          "accountNumber": bank.account_number,
          // "referenceCode": bank.referenceId,
          "remarks": data.description
        }, (res) => {
          // if (res) {
          setData({
            name: '',
            type: '',
            logo: '',
            phone: '',
            email: '',
            description: '',
            vatId: '',
            address: '',
            businessRC: '',
            businessTIN: '',
            vat_option: '',
            enableNQR: '',
          });
          props.handleDone();
          // }
        })
      } else {
        setData({
          name: '',
          type: '',
          logo: '',
          phone: '',
          email: '',
          description: '',
          vatId: '',
          address: '',
          businessRC: '',
          businessTIN: '',
          vat_option: '',
          enableNQR: '',
        });
        props.handleDone();
      }
    },
      (error) => {
        if (error?.response?.data?.message === "This is a new business. Kindly perform KYC." && error?.response?.status === 400) {
          if (data?.name && data?.businessRC && data.businessTIN) {
            const reqData = {
              tax_id_number: data.businessTIN,
              business_registration_number: data?.businessRC,
              business_name: data?.name
            }
            completeBusinessProfile(reqData, (resp) => {
              if (resp) {
                createBusiness(dispatch, businessData, (resp) => {
                  if (resp && data.enableNQR === 'Yes') {
                    optInToNQR({
                      "name": data.name,
                      "tin": data.businessTIN,
                      "contact": data.address,
                      "phone": data.phone,
                      "email": data.email,
                      "address": data.address,
                      "bankNo": bank.bankCode,
                      "accountName": bank.account_name,
                      "accountNumber": bank.account_number,
                      // "referenceCode": bank.referenceId,
                      "remarks": data.description
                    }, (res) => {
                      // if (res) {
                      setData({
                        name: '',
                        type: '',
                        logo: '',
                        phone: '',
                        email: '',
                        description: '',
                        vatId: '',
                        address: '',
                        businessRC: '',
                        businessTIN: '',
                        vat_option: '',
                        enableNQR: '',
                      });
                      props.handleDone();
                      // }
                    })
                  } else {
                    setData({
                      name: '',
                      type: '',
                      logo: '',
                      phone: '',
                      email: '',
                      description: '',
                      vatId: '',
                      address: '',
                      businessRC: '',
                      businessTIN: '',
                      vat_option: '',
                      enableNQR: '',
                    });
                    props.handleDone();
                  }
                })
              }
            })
          } else {
            setBusinessKYCVerified(false)
            setStep(3)
          }
        }
      })
  };

  const handleAddFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    setStep(1)
  }

  const handleSecondFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    data.enableNQR === 'Yes' ? setStep(2) : handleCreateBusiness();
  }

  const handleCreateWithNQR = (event: FormEvent) => {
    event.preventDefault();
    handleCreateBusiness();
  }

  const handleVerifyAndCreate = (event: FormEvent) => {
    event.preventDefault();
    const reqData = {
      tax_id_number: data.businessTIN,
      business_registration_number: data?.businessRC,
      business_name: data?.name
    }
    completeBusinessProfile(reqData, (resp) => { if (resp) { handleCreateBusiness() } })
  }



  return (
    <div className="w-full h-full">
      <div>
        {renderStep()}
      </div>
      <div>
        {
          isFirst && (
            <form onSubmit={handleAddFormSubmit} className='l'>
              <div className='w-full flex flex-col justify-start items-start mb-4 border-b-2 border-[#22222226] pb-4'>
                <h3 className='text-2xl'>{'New Business'}</h3>
                <p className='text-sm'>Provide basic information about your business</p>
              </div>
              <Input
                label="Upload Business Logo"
                name="logo"
                required
                value={data.logo}
                onChange={handleChange}
                type="file"
                accept='.png, .jpg, .jpeg'
              />
              <Input
                label="Enter Business Name"
                placeholder="Name"
                name="name"
                required
                value={data.name}
                onChange={handleChange}
              />
              <SelectComponent
                data={businessCategory}
                label="What does your business do?"
                placeholder="Category"
                name="type"
                required
                value={data.type}
                onChange={handleChange}
              />
              <Input
                label="Business Email"
                placeholder="Email"
                name="email"
                type="email"
                required
                value={data.email}
                onChange={handleChange}
              />
              <Input
                type='tel'
                name="phone"
                label="Phone(+2347XXXXXXXXX)"
                placeholder="+2347XXXXXXXXX"
                required
                pattern="[789][0-9]{9}"
                value={data.phone}
                onChange={handleChange}
              />
              <Button
                label="Continue"
                type="flat"
                btnActionType="submit"
                overrideStyle={{
                  marginTop: '20px'
                }}
              />
            </form>
          )
        }
        {
          isSecond && (
            <form className='user-group-cont' onSubmit={handleSecondFormSubmit}>
              <div className='w-full flex flex-col justify-start items-start mb-4 border-b-2 border-[#22222226] pb-4'>
                <h3 className='text-2xl'>{'Additional Information'}</h3>
                <p className='text-sm'>Provide additional information about your business</p>
              </div>
              <Input
                label="Business Description"
                placeholder="Description"
                name="description"
                required
                value={data.description}
                onChange={handleChange}
              />
              <Input
                label="Enter Business RC No"
                placeholder="Business RC"
                name="businessRC"
                required
                value={data.businessRC}
                onChange={handleChange}
              />
              <SelectComponent
                data={['Yes', 'No']}
                label="Show VAT on Transaction?"
                placeholder=""
                name="vat_option"
                required
                value={data.vat_option}
                onChange={handleChange}
              />
              {
                data.vat_option === 'Yes' && (
                  <Input
                    label="Enter Business VAT ID"
                    placeholder="VAT ID"
                    name="vatId"
                    required
                    value={data.vatId}
                    onChange={handleChange}
                  />
                )
              }
              <Input
                label="Enter Business Address"
                placeholder="Address"
                name="address"
                required
                value={data.address}
                onChange={handleChange}
              />
              <SelectComponent
                data={['No', 'Yes']}
                label="Enable NQR?"
                placeholder=""
                name="enableNQR"
                required
                value={data.enableNQR}
                onChange={handleChange}
                info="NQR allows you to accept funds with Scan and Pay."
              />
              <div className='btn-group !w-full'>
                <Button
                  label="Go Back"
                  type="contained"
                  btnActionType="button"
                  disabled={isLoading}
                  onClick={() => setStep(0)}
                  overrideStyle={{
                    width: '45%'
                  }}
                />
                <Button
                  label={data.enableNQR === 'Yes' ? "Next" : "Create Business"}
                  type="flat"
                  btnActionType="submit"
                  disabled={isLoading}
                  overrideStyle={{
                    width: '45%'
                  }}
                />
              </div>
            </form>
          )
        }
        {
          isThird && (
            <form className='user-group-cont' onSubmit={handleCreateWithNQR}>
              <div className='w-full flex flex-col justify-start items-start mb-4 border-b-2 border-[#22222226] pb-4'>
                <h3 className='text-2xl'>{'Add Collection Account'}</h3>
                <p className='text-sm'>Provide collection account information.</p>
              </div>
              <div className='w-full'>
                <BankInput
                  setSelectedBank={(value) => {
                    setBank(value);
                    handleChange('bankName', value.bankName);
                  }}
                />
              </div>
              <Input
                label="Enter Business TIN"
                placeholder="Business TIN"
                name="businessTIN"
                required
                value={data.businessTIN}
                onChange={handleChange}
              />
              <div className='btn-group w-full' style={{ marginTop: '40px' }}>
                <Button
                  label="Go Back"
                  type="contained"
                  btnActionType="button"
                  disabled={isLoading}
                  onClick={() => setStep(1)}
                  overrideStyle={{
                    width: '45%'
                  }}
                />
                <Button
                  label={"Create Business"}
                  type="flat"
                  btnActionType="submit"
                  disabled={isLoading}
                  overrideStyle={{
                    width: '45%'
                  }}
                />
              </div>
            </form>
          )
        }
        {
          (!businessKYCVerified && isForth) && (
            <form className='user-group-cont' onSubmit={handleVerifyAndCreate}>
              <div className='w-full flex flex-col justify-start items-start mb-4 border-b-2 border-[#22222226] pb-4'>
                <h3 className='text-2xl'>Provide business KYC</h3>
              </div>
              <Input
                label="Enter Business TIN"
                placeholder="Business TIN"
                name="businessTIN"
                required
                value={data.businessTIN}
                onChange={handleChange}
              />
              <div className='btn-group w-full flex flex-wrap gap-2' style={{ marginTop: '20px' }}>
                <Button
                  label={"Verify your business KYC"}
                  type="flat"
                  btnActionType="submit"
                  disabled={isLoading}
                  overrideStyle={{
                    width: '100%'
                  }}
                />
                <Button
                  label="Go Back"
                  type="contained"
                  btnActionType="button"
                  disabled={isLoading}
                  onClick={() => {
                    setData((prev) => ({ ...prev, businessTIN: "" }))
                    data.enableNQR === 'Yes' ? setStep(2) : setStep(1)
                  }}
                  overrideStyle={{
                    width: '100%'
                  }}
                />
              </div>
            </form>
          )
        }
      </div>
    </div>
  );
};

export default RegisterBusiness;

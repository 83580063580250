import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleDeleteBusiness } from '../../actions/businessActions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import closeIcon from "../../assets/close-icon.svg";
import BusinessIcon from '../../assets/business1.png';
import { IBusiness } from '../../pages/Business/businessSlice';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import './businessList.scss';
import NotFound from '../NotFound';

const BusinessList = (props: { handleCreate: () => void }) => {
  const dispatch = useAppDispatch();
  const [toDelete, setToDelete] = useState<IBusiness | null>();

  const { businesses } = useAppSelector((state) => state.business);
  if (!businesses) {
    return (
      <NotFound
        text1="You have not profiled Any business yet!"
        text2='Create a business profile and Manage payments for your services hassle free.'
        btnLabel="Create a Business"
        onClick={() => props.handleCreate()}
      />
    )
  }

  const deleteBusiness = () => {
    handleDeleteBusiness(dispatch, toDelete?.id, () => {
      setToDelete(null);
    });
  }

  return (
    <div className='businesses'>
      {
        businesses.length === 0 && (
          <NotFound
            text1="You have not profiled Any business yet!"
            text2='Create a business profile and Manage payments and generate invoices for your products and services hassle free.'
            btnLabel="Create a Business"
            onClick={() => props.handleCreate()}
          />
        )
      }
      {
        Array.isArray(businesses) && businesses.map((business, index) => (
          <BusinessCard
            logo={business?.business_logo}
            name={business?.business_name}
            category={business?.type}
            rc={business?.business_code}
            key={`${index}${business?.business_name}`}
            setToDelete={(event) => {
              event.stopPropagation();
              setToDelete(business)
            }}
          />
        ))
      }
      <ConfirmModal
        isOpen={!!toDelete}
        text='Are you sure you want to delete this Business?'
        handleNo={() => setToDelete(null)}
        handleYes={() => deleteBusiness()}
        onClose={() => setToDelete(null)}
      />
    </div>
  )
};

export default BusinessList;

export const BusinessCard = ({
  name,
  logo = BusinessIcon,
  category,
  rc,
  setToDelete
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/business/dashboard/${rc}`, {
      state: {
        item: {
          business_businessRC: rc,
          business_name: name,
        }
      }
    });
  }
  return (
    <div
      className='business-card !w-full !max-w-[300px] bg-[#C036361A] px-4'
      onClick={handleNavigate}
    >
      {/* <div
        className="bc-overlay"
        style={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: 'revert',
        }}
      /> */}
      <img className='delete-img' src={closeIcon} alt="" onClick={setToDelete} />
      <div className='top !rounded-full !w-[70px] !h-[70px]'>
        {
          logo && (<img src={logo} alt="Business Logo" className='!w-full !h-full !object-contain !rounded-full' />)
        }
      </div>
      <div className='bottom flex flex-auto flex-grow justify-start items-center'>
        <p className='text-lg w-full text-left capitalize'>{name}</p>
      </div>
    </div>
  )
}

import { useNavigate } from 'react-router-dom'
import dummyVoucherImg from "../voucher-assets/images/dummy-voucher.jpg"
import VoucherStatusTag from './VoucherStatusTag'
import Button from '../../../components/Button'
import { formatCurrency } from '../../../utils/common'


export default function VoucherCard() {
  const navigate = useNavigate()
  return (
    <div className="w-[100%] flex flex-col shadow-sm rounded-[10px] bg-[#2222220D]">
      <div className="w-[100%] flex items-center p-[10px] gap-2 bg-[#0898A00D] rounded-t-[inherit] rounded-b-none">
        <img src={dummyVoucherImg} alt="voucher" className="w-[40px] h-[40px] rounded-full object-cover object-center" />
        <div className="flex flex-col gap-1 w-[full] text-left">
          <h4 className="text-[14px] font-[500] leading-[100%]">Ministry of Health Food stamp</h4>

          <VoucherStatusTag status={"active"} type="text" />
        </div>
      </div>
      <div className="w-[100%] py-[20px] px-[15px] flex flex-col gap-[20px]">
        <div className="w-[100%] flex justify-between">
          <div className="text-left">
            <h5 className="text-[12px] font-[400] text-[#222222CC] uppercase">Voucher amount</h5>
            <h5 className="text-[14px] font-[500]">{formatCurrency({ num: 50000000 })}</h5>
          </div>
          <div className="text-right">
            <h5 className="text-[12px] font-[400] text-[#222222CC] uppercase">amount per code</h5>
            <h5 className="text-[14px] font-[500]">{formatCurrency({ num: 5000 })}</h5>
          </div>
        </div>

        <div className="w-[100%] flex justify-between">
          <div className="text-left">
            <h5 className="text-[12px] font-[400] text-[#222222CC] uppercase">EXPIRY DATE</h5>
            <h5 className="text-[14px] font-[500]">24th Sept, 2024</h5>
          </div>
          <div className="text-right">
            <h5 className="text-[12px] font-[400] text-[#222222CC] uppercase">LOCATION</h5>
            <h5 className="text-[14px] font-[500]">Oyo State</h5>
          </div>
        </div>

        <Button type="contained" label="View Details" className="w-[100%] p-[10px] !m-0 !rounded-[20px]" onClick={() => navigate("/vouchers/all-vouchers/1")} />
      </div>
    </div>
  )
}

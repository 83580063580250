
import TabComponent from '../../../../components/TabComponent'
import GoBackBtn from '../../voucher-components/GoBackBtn'
import VoucherDetails from '../../voucher-components/VoucherDetails'
import VoucherDashboardComponentWrapper from '../../voucher-layouts/VoucherDashboardComponentWrapper'
import VoucherTopComponents from '../../voucher-layouts/VoucherTopComponents'

export default function VoucherDetailsPage() {
  return (
    <VoucherDashboardComponentWrapper
      title="AzatMe Vouchers"
    // subTitle="Here are an overview of all your vouchers"
    >
      <>
        <VoucherTopComponents
          filled={true}
          leftComponents={[<GoBackBtn />]}
          middleComponents={
            [
              <h4 className='flex sm:hidden'>Voucher Details</h4>
            ]
          }
          rightComponents={
            [
              <span className='inline sm:hidden p-[20px] invisible'></span>
            ]
          }
        />
        <TabComponent
          components={
            {
              "Voucher Information": () => <VoucherDetails />,
              "Transactions": () => <div>Transactions</div>,
            }
          }
          isVoucherTab={true}
        />
      </>
    </VoucherDashboardComponentWrapper>
  )
}

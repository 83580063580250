import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import UnAuthWrapper from "../../components/UnAuthWrapper";
import { scrollUp } from "../../utils/common";
import getBaseURL from "../../utils/getBaseURL";
import { toastWrapper, signalObj } from "../../utils/toastWrapper";
import useGetInvitationData from "../../hooks/useGetInvitationData";
import toast from "react-hot-toast";
// import useAbortCall, {useIsMountedRef}  from "../../hooks/useAbortCall";

function Login() {
  // const isMountedRef = useIsMountedRef().current;
  // useAbortCall(isMountedRef);
  const [invitationData] = useGetInvitationData();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  useEffect(() => {
    scrollUp();
    if (Object.keys(invitationData).length && invitationData.action === 'accept') {
      setData((prev) => ({
        ...prev,
        email: invitationData.email,
      }));
      toast('Provide your password to Continue');
    }
  }, [invitationData]);

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (event: React.FormEvent) => {
    const url = getBaseURL() as string;
    event.preventDefault();
    toastWrapper(
      axios.post(`${url}AttemptLogin`, data, signalObj), 
      'Trying to Authenticate you...', 
      (resp) => {
        localStorage.setItem('email', data.email);
        if ((Object.keys(invitationData)).length) {
          localStorage.setItem('pendingInvitation', JSON.stringify(invitationData));
        }
        navigate('/verify', { state: {item: data}});
        return resp.data.message || 'Login Successful!';
      }, 
      'Email or Password Incorrect.',
      () => {
        return 'Email or Password Incorrect.'
      }
    );
  }

  return (
    <UnAuthWrapper
      title="Welcome back."
      subTitle="Log in to your AzatMe account to proceed."
      ctaQuestion="Don’t have an account?"
      ctaRoute="register"
      ctaText="Sign Up"
    >
      <form onSubmit={handleSubmit} className="max-w-[400px]">
        <Input
          label="Email"
          placeholder="Email"
          name="email"
          value={data.email}
          onChange={handleChange}
          required
        />
        <Input
          label="Password"
          placeholder="Password"
          name="password"
          value={data.password}
          type="password"
          ctaText="Forgot Password?"
          ctaRoute="forgot-password"
          onChange={handleChange}
          required
        />
        <Button
          label="Log In"
          onClick={() => null}
          type="flat"
          btnActionType="submit"
        />
      </form>
    </UnAuthWrapper>
  );
}

export default Login;
import { formatCurrency } from "../../utils/common"

export const TableNormalStatus = ({ status, type = "filled" }: { status: "approved" | "pending" | "decline", type?: "filled" | "text" }) => {
  return (
    <span className={`w-fit !h-7 flex gap-1 text-[12px] justify-center items-center px-[10px] py-[5px] rounded-md capitalize
      ${status === "approved" ? "bg-[#27AE6012] text-[#27AE60]" :
        status === "pending" ? "bg-yellow-100 text-yellow-500" :
          status === "decline" ? "bg-[#ff000033] text-[red]"
            : null
      }
      ${type === "text" && "!bg-transparent !p-0"}
    `}>
      <span
        className={`w-[7px] h-[7px] rounded-full text-[13px] font-[500]
      ${status === "approved" ? "bg-[#27AE60]" :
            status === "pending" ? "bg-yellow-500" :
              status === "decline" ? "bg-[red]"
                : null
          }`}></span>
      {status}
    </span>
  )
}

export const TableStatus = ({ amountPaid, target }) => {
  return (
    <span className={`status-chip ${parseInt(amountPaid) >= parseInt(target) ? 'paid' : 'unpaid'}`}>{formatCurrency({ num: amountPaid || 0 })} of {formatCurrency({ num: target })}</span>
  )
}

export const TableAction = ({ handleMore }) => {
  return (
    <button type='button' className='status link-btn !rounded-full' onClick={() => handleMore && handleMore()}>
      <p className='!w-20 h-8 flex flex-row justify-center items-center !bg-az-light-red text-az-teal !rounded-full'>More</p>
    </button>
  )
}
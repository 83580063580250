import { useState } from "react";
import { createSubCategoriesActionCreator, delSubCategoryActionCreator, updateSubCategoryActionCreator } from "../../../actions/dashboardActions";
import SendIcon from "../../../assets/send.png";
import { AddSubCategoryForm } from "../../../components/Categories/CreateCategory";
import "./categories.scss";
import { useAppSelector } from "../../../app/hooks";
import EditIcon from "../../../assets/edit-icon.png";
import DeleteIcon from "../../../assets/delete.png";

export default function CategoryDetailsTab({ category, categoryId }) {
  const { subCategoryObj } = useAppSelector((state) => state.dashboard);


  const updateSubCategory = (name, subCategoryId, handleOnDone) => {
    updateSubCategoryActionCreator({
      name,
      subCategoryId,
      categoryId
    }, handleOnDone);
  }

  const delSubCate = (subCategoryId) => {
    delSubCategoryActionCreator({ subCategoryId, categoryId }, () => { });
  };

  const addSubCategory = (data: string) => {
    createSubCategoriesActionCreator({
      name: data,
      category_id: categoryId
    }, (resp) => {
      const newSubCates = [...subCategoryObj[categoryId]];
      newSubCates.push(resp)
      // setSubCates(newSubCates);
    });
  }

  return (
    <div>
      <div className="subcategories-wrapper">
        <p>SubCategories for {categoryId}</p>

        {subCategoryObj[categoryId]?.length ? subCategoryObj[categoryId]?.map((subCategory) => (
          <SubCategoryInput
            subCategory={subCategory}
            delSubCate={delSubCate}
            updateSubCategory={updateSubCategory}
            key={subCategory.id}
          />
        )) : <p>No Categories!</p>}
        <AddSubCategoryForm
          addSubCate={addSubCategory}
          subCates={subCategoryObj[categoryId]}
        />
      </div>
    </div>
  )
}

const SubCategoryInput = ({ subCategory, delSubCate, updateSubCategory }) => {
  const [mode, setMode] = useState('view');
  const [name, setName] = useState(subCategory.name);

  const isView = mode === 'view';

  const handleOpenEditConfirm = () => {
    updateSubCategory(name, subCategory.id, () => setMode('view'));
  }

  return (
    <div className="subcategory-input">
      <input type="text" value={name} className={`${mode}`} onChange={({ target }) => {
        setName(target.value);
      }} />
      <div className="cate-actions">
        <button onClick={() => isView ? setMode('edit') : handleOpenEditConfirm()}>
          <img src={isView ? EditIcon : SendIcon} alt="Edit" />
        </button>
        <button onClick={() => {
          delSubCate(subCategory.id);
        }}>
          <img src={DeleteIcon} alt="Delete" />
        </button>
      </div>
    </div>
  )
}
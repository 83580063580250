import { useCallback, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import EditIcon from "../../assets/edit-icon.png";
import SendIcon from "../../assets/send.png";
import DeleteIcon from "../../assets/delete.png";
import {
  createSubCategoriesActionCreator,
  delCategoryActionCreator,
  delSubCategoryActionCreator,
  getSubCategoriesActionCreator,
  updateCategoriesActionCreator,
  updateSubCategoryActionCreator,
} from "../../actions/dashboardActions";
import Button from "../Button";
import Input from "../Input";
import Modal from "../Modal";
import "./categories.scss";
import { AddSubCategoryForm } from "./CreateCategory";

const Categories = () => {
  const [isOpen] = useState(false);

  return (
    <div>
      <CateList isOpen={isOpen} />
    </div>
  );
};

export default Categories;

function CateList({ isOpen }): JSX.Element {
  const { categories } = useAppSelector((state) => state.dashboard);
  const { subCategoryObj } = useAppSelector((state) => state.dashboard);
  const {user} = useAppSelector((state) => state.auth);
  const [category, setCategory] = useState<Record<string, any>>({});
  // const navigate = useNavigate();
  const [categoryId, setCategoryId] = useState('');
  // const contentRef = useRef<HTMLDivElement>(null);
  // const [_, setHeight] = useState(0);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isSubCatOpen, setIsSubCatOpen] = useState(false);
  const isAdmin = user?.usertype === 'admin';
  const [data, setData] = useState({
    name: '',
  });
  

  const handleOpenEditConfirm = useCallback((category) => {
    setCategory(category);
    setData({
      name: category.name
    });
    setIsEditOpen(true);
  }, []);
  
  const handleCloseEditConfirm = useCallback(() => {
    setIsEditOpen(false);
  }, []);

  const handleOpenDeleteConfirm = useCallback((category) => {
    setCategory(category);
    setIsDeleteOpen(true);
  }, []);

  const handleOpenSubCat = useCallback((category) => {
    setCategory(category);
    setIsSubCatOpen(true);
  }, []);

  const handleCloseSubCat = useCallback(() => {
    setCategory({});
    setIsSubCatOpen(false);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setIsDeleteOpen(false);
  }, []);

  // useEffect(() => {
  //   if (isOpen) {
  //     const contentDiv = contentRef.current as HTMLDivElement;
  //     setHeight(contentDiv.scrollHeight);
  //   } else {
  //     setHeight(0);
  //   }
  // }, [isOpen]);

  

  //navigate to create new category
  // const createNewSubCategory = (categoryId) => {
  //   navigate("/")
  // }

  //fetch all subcategories for a category
  function getSubCates(categoryId) {
    setCategoryId(categoryId);
    if (!subCategoryObj[categoryId]) {
      getSubCategoriesActionCreator(categoryId);
    }
  }

  //delete category from the category list
  const delCate = () => {
    delCategoryActionCreator(category.id, () => {
      setIsDeleteOpen(false);
    });
  };

  const editCate = (categoryId) => {
    updateCategoriesActionCreator({
      categoryId,
      name: data.name,
    }, () => {
      setIsEditOpen(false);
    });
  };

  const delSubCate = (subCategoryId) => {
    delSubCategoryActionCreator({subCategoryId, categoryId}, () => {});
  };


  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const addSubCategory = (data: string) => {
    createSubCategoriesActionCreator({
      name: data,
      category_id: categoryId
    }, (resp) => {
      const newSubCates = [...subCategoryObj[categoryId]];
      newSubCates.push(resp)
      // setSubCates(newSubCates);
    });
  }


  const updateSubCategory = (name, subCategoryId, handleOnDone) => {
    updateSubCategoryActionCreator({
      name,
      subCategoryId,
      categoryId
    }, handleOnDone);
  }

  if (categories?.length === 0 || !categories) {
    return <p>No Categories Added!</p>
  }

  if (!isAdmin) {
    return (
      <div className="categories">
        <div className="wrapper">
        {categories?.map<any>((category) => {
          return (
            <div className="accordion" key={category.id} style={{height: '40px'}}>
              <div className="accordion-item" style={{height: '40px'}}>
                <h4
                  className="title"
                  onClick={() => {
                    getSubCates(category.id);
                    handleOpenSubCat(category);
                  }}
                >
                  {category.name}
                </h4>
              </div>
            </div>
          );
        })}
        </div>
      </div>
    );
  }

  return (
    <div className="categories">
      <div className="wrapper">
        {categories?.map<any>((category) => {
          return (
            <div className="accordion" key={category.id}>
              <div className="accordion-item" style={{minHeight: '40px'}}>
                {
                  isAdmin && (
                    <div className="cate-actions">
                      <button onClick={() => handleOpenEditConfirm(category)}>
                        <img src={EditIcon} alt="Edit" />
                      </button>
                      <button onClick={() => handleOpenDeleteConfirm(category)}>
                        <img src={DeleteIcon} alt="Delete" />
                      </button>
                    </div>
                  )
                }
                <div className="icon">
                  <p>{category.name[0]}</p>
                </div>
                <h4
                  className="title"
                  onClick={() => {
                    getSubCates(category.id);
                    handleOpenSubCat(category);
                  }}
                >
                  {category.name}
                </h4>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        open={isDeleteOpen}
        onClose={handleCloseDeleteConfirm}
        overrideStyle={{
          maxWidth: "300px",
          width: "80%",
          height: "max-content",
          padding: "20px 30px",
        }}
        title="Confirm Delete Category"
      >
        <div className="confirm-delete">
          <p>
            Are You Sure you want to delete this Category? Once
            deleted, this cateory will no longer be available to
            users.
          </p>
          <div>
            <Button
              type="contained"
              label="No"
              onClick={handleCloseDeleteConfirm}
            />
            <Button
              type="contained"
              label="Yes"
              onClick={() => delCate()}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={isEditOpen}
        onClose={handleCloseEditConfirm}
        overrideStyle={{
          maxWidth: "300px",
          width: "80%",
          height: "max-content",
          padding: "20px 30px",
        }}
        title="Confirm Edit Open"
      >
        <div className="confirm-delete edit-cat-wrapper">
          <p>Edit this Category</p>
          <form>
            <Input
              label="Category Name"
              type="text"
              required
              name="name"
              value={data.name}
              onChange={handleChange}
            />
            <div className="edit-category-btn-wrapper">
              <Button
                type="contained"
                label="Cancel"
                onClick={handleCloseEditConfirm}
              />
              <Button
                type="contained"
                label="Save"
                onClick={() => editCate(category.id)}
              />
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        open={isSubCatOpen}
        onClose={handleCloseSubCat}
        overrideStyle={{
          maxWidth: "300px",
          width: "80%",
          height: "max-content",
          padding: "20px 30px",
        }}
        title="Subcategory Modal"
      >
        <div className="subcategories-wrapper">
          <p>SubCategories for {category.name}</p>

          {subCategoryObj[categoryId]?.length ? subCategoryObj[categoryId]?.map((subCategory) => (
            <SubCategoryInput
              subCategory={subCategory}
              delSubCate={delSubCate}
              updateSubCategory={updateSubCategory}
              key={subCategory.id}
            />
          )) : <p>No Categories!</p>}
          <AddSubCategoryForm
            addSubCate={addSubCategory}
            subCates={subCategoryObj[categoryId]}
          />
        </div>
      </Modal>
    </div>
  );
}

const SubCategoryInput = ({subCategory, delSubCate, updateSubCategory}) => {
  const [mode, setMode] = useState('view');
  const [name, setName] = useState(subCategory.name);

  const isView = mode === 'view';

  const handleOpenEditConfirm = () => {
    updateSubCategory(name, subCategory.id, () => setMode('view'));
  }

  return (
    <div className="subcategory-input">
      <input type="text" value={name} className={`${mode}`} onChange={({target}) => {
        setName(target.value);
      }} />
      <div className="cate-actions">
        <button onClick={() => isView ? setMode('edit') : handleOpenEditConfirm()}>
          <img src={isView ? EditIcon : SendIcon} alt="Edit" />
        </button>
        <button onClick={() => {
            delSubCate(subCategory.id);
          }}>
          <img src={DeleteIcon} alt="Delete" />
        </button>
      </div>
    </div>
  )
}

import { useEffect } from "react";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import { getAllInquiries } from "../../../actions/adminActions";
import AllInquiry from "../../../components/Inquiry";

const AdminInquiries = () => {
    useEffect(() => {
        getAllInquiries(1);
    }, []);
    return (
        <DashboardComponentWrapper
            subTitle="Inquiries"
            pageDetails="Here's an overview of all Inquiries on AzatMe."
        >
            <div className="h-full w-full px-[2%]">
                <AllInquiry />
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminInquiries;

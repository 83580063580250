import React from 'react'

export default function LogoutSVG() {
  return (
    <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0155 7.38948V6.45648C15.0155 4.42148 13.3655 2.77148 11.3305 2.77148H6.45548C4.42148 2.77148 2.77148 4.42148 2.77148 6.45648V17.5865C2.77148 19.6215 4.42148 21.2715 6.45548 21.2715H11.3405C13.3695 21.2715 15.0155 19.6265 15.0155 17.5975V16.6545" stroke="#F20000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.8086 12.0214H9.76758" stroke="#F20000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.8809 9.10626L21.8089 12.0213L18.8809 14.9373" stroke="#F20000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

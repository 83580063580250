import { Link } from "react-router-dom";
import AllVouchersComponent from "../Voucher/voucher-components/AllVouchersComponent";
import VoucherDashboardComponentWrapper from "../Voucher/voucher-layouts/VoucherDashboardComponentWrapper";

const SponsorVouchers = () => {
  return (
    <VoucherDashboardComponentWrapper 
      title="Vouchers"
      subTitle=""
    >
      <div className="w-[95%] ">
        <div className="w-[100%] flex justify-between items-center my-5">
          <h4 className="text-[15px] font-[500]">Recent Vouchers Created</h4>
          <Link to={"/vouchers/all-vouchers"} className="underline text-[15px] text-[#0898A0]">View all</Link>
        </div>
        <AllVouchersComponent />
      </div>
    </VoucherDashboardComponentWrapper>
  )
};

export default SponsorVouchers;

import Modal from "../../../components/Modal"

const FundAccount = ({showDetails, handleClose}) => {
  const handleIPaid = () => {

  }
  return (
    <Modal
      onClose={() => handleClose(false)}
      open={showDetails}
      title={''}
      overrideStyle={{
        height: '400px',
        width: '400px',
        borderRadius: '30px',
        padding: '0'
      }}
      overrideChildrenStyle={{
        padding: '0',
        height: '100% !important'
      }}
    >
      <div className='flex flex-col justify-between items-center h-full !w-full'>
        <div className="border-b border-[#2222221A] w-full px-5 pt-6 pb-5 text-xl font-medium">Fund your account</div>
        <div className="w-[90%] border border-dashed h-[60%] bg-[#0898A00D] mt-5 rounded p-2 flex flex-col justify-between items-center">
          <p className="w-10/12 text-sm m-auto text-center">Proceed to your bank app to make transfer and fund your wallet</p>
          <div className="flex-1 flex flex-col justify-evenly w-full">
            <div className="flex flex-row justify-between items-center">
              <p>Bank name:</p>
              <p className="font-medium">Wema Bank</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Account number:</p>
              <p className="font-medium">9023474808</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Account name:</p>
              <p className="font-medium">Merhant name/Paythru</p>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-around items-center w-full  px-5 py-6'>
          <button className='text-white bg-az-teal px-4 py-2 w-full rounded-[100px]' onClick={handleIPaid}>I have made payment</button>
        </div>
      </div>
    </Modal>
  )
};

export default FundAccount;
import BlogImgLg from  '../../assets/new/blogHeroLg.png';
import BlogImgMob from  '../../assets/new/blogHeroMob.png';
import { Blog } from '../Landing/Components/Personal/Blogs';
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { scrollUp } from '../../utils/common';
import { BLOGS_QUERY } from '../../queries';

const Blogs = () => {
    const {pathname, hash} = useLocation();
    const { loading, error, data } = useQuery(BLOGS_QUERY);

    useEffect(() => {
        if (pathname && !hash) {
            scrollUp();
        }
    }, [hash, pathname]);

    return (
        <div className='w-full'>
            <div className='relative h-fit w-full'>
                <img src={BlogImgLg} alt="" className='w-full hidden lg:flex' />
                <img src={BlogImgMob} alt="" className='w-full lg:hidden flex' />
                <h1 className='absolute uppercase top-[30%] text-white text-sm max-w-[400px] text-left font-medium left-[6%]'>Stay up to date</h1>
                <h1 className='absolute top-[50%] text-white text-3xl max-w-[400px] text-left lg:text-[40px] lg:leading-[51px] font-medium left-[6%]'>
                    Insights and blog to
                    inform and inspire
                </h1>
            </div>
            <div className='px-[6%] flex flex-row flex-wrap w-full h-fit justify-center lg:justify-between py-10'>
                {
                    data?.posts?.map((blog, index) => (
                        <Blog blog={blog} key={index} />
                    ))
                }
            </div>
            {/* <div className='w-full flex justify-center items-center my-16'>
                <button className="bg-az-teal w-fit text-white text-sm font-medium flex flex-row justify-center items-center px-4 p-3 rounded">View all <img src={Arrow} alt="" className='mx-3 rotate-90' /></button>
            </div> */}
        </div>
    )
};

export default Blogs;
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useAppSelector } from '../../app/hooks'
import { getAjoWithdrawalsById, getBusinessWithdrawalsById, getExpenseWithdrawalsById, getGroupWithdrawalsById } from '../../actions/adminActions'
import { formatCurrency } from '../../utils/common'

export default function AdminWithdrawalInfo({ details }) {
  const type = details?.entity
  const data = useAppSelector(state => state.admin.AdminWithdrawalDetails)[type.toLowerCase()] || {}

  useEffect(() => {
    if (type.toLowerCase() === "refundme") {
      getExpenseWithdrawalsById(details?.id)
    }
    else if (type.toLowerCase() === "kontribute") {
      getGroupWithdrawalsById(details?.id)
    }
    else if (type.toLowerCase() === "ajo") {
      getAjoWithdrawalsById(details?.id)
    }
    else if (type.toLowerCase() === "business") {
      getBusinessWithdrawalsById(details?.id)
    }
  }, [])


  return (
    <div className="c-expense-details w-full flex !flex-col">
      <p className="text-2xl border-b-2 border-gray-300 pb-6">{type} Summary</p>
      <div className="my-2 flex flex-row w-full justify-start items-center">
        <span
          style={{
            background: data?.status && data?.status.toLowerCase() === 'success' ? 'green' : data?.status && data?.status.toLowerCase() === 'failure' ? 'red' : 'orange',
            color: '#fff',
            width: 'max-content',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            textTransform: 'capitalize',
            overflow: 'hidden',
          }}
        >
          {data?.status ? data?.status : 'pending'}
        </span>
      </div>
      <div className="right mt-1 p-4 w-full !rounded-sm !bg-[#0898A008]">
        <div className="right-wrapper">
          <div className="flex flex-col w-full">
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Date</p>
              <p className="text-sm text-az-black-900">{dayjs(data?.created_at).fromNow()}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Recieved at</p>
              <p className="text-sm text-az-black-900">{data?.recordDateTime ? data?.recordDateTime : "Not yet recieved"}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Account Name</p>
              <p className="text-sm text-az-black-900">{data?.account_name}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Account Number</p>
              <p className="text-sm text-az-black-900">{data?.account_number}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Bank</p>
              <p className="text-sm text-az-black-900">{data?.bank}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Transaction Ref.</p>
              <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">
                {data?.transactionReference}
              </p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Amount</p>
              <p className="text-sm text-az-black-900">{formatCurrency({ num: data?.amount })}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Charges</p>
              <p className="text-sm text-az-black-900">{formatCurrency({ num: data?.charges })}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Payment Amount</p>
              <p className="text-sm text-az-black-900">{formatCurrency({ num: data?.paymentAmount })}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Minus Residual</p>
              <p className="text-sm text-az-black-900">{formatCurrency({ num: data?.minus_residual })}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Recorded time</p>
              <p className="text-sm text-az-black-900">{data?.recordDateTime}</p>
            </div>
            <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Description</p>
              <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">
                {data?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { Outlet } from 'react-router-dom';
import "./dashboard.scss";
import DashboardSideBar from '../../components/DashboardSideBar';
import HomeSVG from '../../components/SVG/Home';
import DashboardSVG from '../../components/SVG/Dashboard';
import BankSVG from '../../components/SVG/Bank';
import RefundMeSVG from '../../components/SVG/RefundMe';
import KontributeSVG from '../../components/SVG/Kontribute';
import ProfileSVG from '../../components/SVG/Profile';
import UserSVG from '../../components/SVG/User';
import FeedbackSVG from '../../components/SVG/Feedback';
import KycSVG from '../../components/SVG/KYCSvg';


function AdminDashboard() {


  //Sidebar array for admin sidebar
  const adminNavItems = [
    {
      name: "overview",
      path: `/admin/overview`,
      title: "Overview",
      Icon: HomeSVG,
    },
    {
      name: "users",
      path: `/admin/users`,
      title: "Users",
      Icon: UserSVG,
    },
    {
      name: "kyc_management",
      path: `/admin/kyc_management`,
      title: "KYC Management",
      Icon: KycSVG,
    },
    {
      name: "users entities",
      path: `/admin/user_entities`,
      title: "User Entities",
      Icon: UserSVG,
    },
    {
      name: "categories",
      path: `/admin/categories`,
      title: "Categories",
      Icon: DashboardSVG,
    },
    {
      name: "feedback",
      path: `/admin/feedback`,
      title: "Feedback",
      Icon: FeedbackSVG,
    },
    // {
    //   name: "subscriptions",
    //   path: `/admin/subscriptions`,
    //   title: "Subscriptions",
    //   Icon: DashboardSVG,
    // },
    {
      name: "inquiries",
      path: `/admin/inquiries`,
      title: "Inquiries",
      Icon: DashboardSVG,
    },
    {
      name: "refundme",
      path: `/admin/refundme`,
      title: "RefundMe",
      Icon: RefundMeSVG,
    },
    {
      name: "kontribute",
      path: `/admin/kontribute`,
      title: "Kontribute",
      Icon: KontributeSVG,
    },
    {
      name: "ajo",
      path: `/admin/ajo`,
      title: "Ajo",
      Icon: KontributeSVG,
    },
    {
      name: "business",
      path: `/admin/business`,
      title: "Business",
      Icon: BankSVG,
    },
    {
      name: "transactions",
      path: `/admin/transactions`,
      title: "Transactions",
      Icon: BankSVG,
    },
    {
      name: "withdrawals",
      path: `/admin/withdrawals`,
      title: "Withdrawals",
      Icon: BankSVG,
    },
    {
      name: "charges",
      path: `/admin/charges`,
      title: "Charges",
      Icon: BankSVG,
    },
    {
      name: "referrals",
      path: `/admin/referrals`,
      title: "Referrals",
      Icon: UserSVG,
    },
    {
      name: "profile",
      path: `/admin/profile`,
      title: "Profile",
      Icon: ProfileSVG,
    },
  ];


  return (
    <div className="dashboard flex flex-row justify-between items-start !relative">
      <DashboardSideBar userType='Admin' firstItem="Overview" items={adminNavItems} />
      <div className="outlet flex flex-auto flex-grow">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminDashboard;

import DashboardComponentWrapper from '../../components/DashboardComponentWrapper';
import Input from '../../components/Input';
import Options from '../../assets/option.png';
import BankCardImg from '../../assets/new/bankCardIcon.png';
import BankCardGrpCircle from '../../assets/new/bankGrpCircle.png';
import './bank.scss';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addBank, deleteBanks, getBanks, getSystemBanks, updateBank, verifyAccount } from '../../actions/bankActions';
import { Bank as BankType } from './bankSlice';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import toast from 'react-hot-toast';
import { scrollUp } from '../../utils/common';
import { setShowVerificationPending } from '../Auth/authSlice';
import useVerificationDone from '../../hooks/useVerificationDone';
import NotFound from '../../components/NotFound';

const Bank = () => {
  const [loadingBanks, setLoadingBanks] = useState(false);
  const {user} = useAppSelector((state) => state.auth);
  const [showAddForm, setShowAddForm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [bankToDelete, setBankToDelete] = useState<BankType>();
  const dispatch = useAppDispatch();
  const [toEdit, setToEdit] = useState<BankType>();
  const {banks, systemBanks, systemBanksObj} = useAppSelector((state) => state.bank);
  const {isVerificationDone} = useVerificationDone();

  const handleAddNewBank = () => {
    if (!isVerificationDone) {
      dispatch(setShowVerificationPending(true));
      return;
    }
    if (!systemBanks && !loadingBanks) {
      setLoadingBanks(true);
      getSystemBanks(dispatch, () => {
        setShowAddForm(!showAddForm);
        setLoadingBanks(false);
      });
    } else {
      setShowAddForm(!showAddForm);
    }
    scrollUp();
  }
  const [data, setData] = useState({
    name: '',
    number: '',
    bankName: '',
  });
  const [accReference, setAccReference] = useState('');

  useEffect(() => {
    if (toEdit) {
      setData({
        name: toEdit.account_name,
        number: toEdit.account_number,
        bankName: toEdit.bankName,
      });
    }
  }, [toEdit]);

  useEffect(() => {
    if (!banks && isVerificationDone) {
      getBanks(dispatch);
    }
  }, [banks, dispatch, isVerificationDone]);

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = () => {
    // event.preventDefault();
    if (!data.bankName || ! data.name || !data.number) {
      toast('Fill all form input to continue');
      return;
    }
    if (toEdit?.id) {
      updateBank({
        ...toEdit,
        name: data.name,
        bankName: data.bankName,
        account_number: data.number,
        bankCode: systemBanksObj[data.bankName].bankCode,
        referenceId: accReference,
      }, dispatch, () => {
        setShowAddForm(!showAddForm);
        setData({
          bankName: '',
          name: '',
          number: '',
        })
      });
    } else {
      const bankExist = banks?.find((bank) => ((bank.bankName === data.name) && (bank.account_number === data.number)));
      if (bankExist) {
        toast.error('Bank with supplied details already added!')
        return;
      }
      addBank({
        account_name: data.name,
        name: data.bankName,
        account_number: data.number,
        bankCode: systemBanksObj[data.bankName].bankCode,
        referenceId: accReference,
        user_id: user?.id,
      }, dispatch, () => {
        setShowAddForm(!showAddForm);
        setData({
          bankName: '',
          name: '',
          number: '',
        });
      });
    }
  }

  const removeBank = () => {
      deleteBanks(bankToDelete, dispatch, () => {
        handleCloseDeleteModal()
      });
  }

  const handleEditOpen = (bank: BankType) => {
    if (!systemBanks) {
      getSystemBanks(dispatch, () => {
        setToEdit(bank);
        setData({
          name: data.name,
          bankName: data.bankName,
          number: bank.account_number,
        });
        setShowAddForm(true);
      });
    } else {
      setToEdit(bank);
      setData({
        name: data.name,
        bankName: data.bankName,
        number: bank.account_number,
      });
      setShowAddForm(true);
    }
  }

  const handleCloseDeleteModal = () => {
    setConfirmDelete(false);
  }

  const handleDelete = (bank: BankType) => {
    setConfirmDelete(true);
    setBankToDelete(bank);
  }

  const handleAcctFieldChange = (name: string, value: string) => {
    handleChange(name, value);
    if (value.length < 10 && data.name) {
      setData((prevState) => ({
        ...prevState,
        name: '',
      }));
    }
    if (value.length === 10) {
      verifyAccount({
        bankCode: systemBanksObj[data.bankName].bankCode,
        account_number: value,
      }, (resp: Record<string, string>) => {
        setData((prevState) => ({
          ...prevState,
          name: resp.data || resp.message,
        }));
        setAccReference(resp.referenceId);
      })
    }
  }

  return (
    <DashboardComponentWrapper
      title='Bank Accounts'
      pageDetails="Here's an overview of all the bank added on AzatMe."
    >
      <div className='bank-wrapper justify-center'>
        {
          banks && banks.length > 0 ? (
            <div className='bank-card-wrapper flex flex-row justify-center md:justify-start items-start flex-wrap w-full'>
              <div
                className='bank-card add-bank max-w-[340px] w-full h-[200px] flex flex-col justify-center items-center'
                onClick={handleAddNewBank}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                  <path d="M22.4942 45C34.9082 45 44.9884 34.9313 44.9884 22.5C44.9884 10.0688 34.9082 0 22.4942 0C10.0661 0 0 10.0688 0 22.5C0 34.9313 10.0661 45 22.4942 45ZM14.0589 21.0938H21.0883V14.0625C21.0883 13.2891 21.721 12.6562 22.4942 12.6562C23.2674 12.6562 23.9001 13.2891 23.9001 14.0625V21.0938H30.9295C31.7027 21.0938 32.3354 21.7266 32.3354 22.5C32.3354 23.2734 31.7027 23.9062 30.9295 23.9062H23.9001V30.9375C23.9001 31.7109 23.2674 32.3438 22.4942 32.3438C21.721 32.3438 21.0883 31.7109 21.0883 30.9375V23.9062H14.0589C13.2856 23.9062 12.653 23.2734 12.653 22.5C12.653 21.7266 13.2856 21.0938 14.0589 21.0938Z" fill="#0898A0"/>
                </svg>
                <span className='mt-3 text-az-teal'>New Banking Details</span>
              </div>
              {
                banks?.map((bank) => (
                  <BankCard
                    bank={bank}
                    key={bank?.id}
                    handleEditOpen={handleEditOpen}
                    handleDelete={handleDelete}
                  />
                ))
              }
            </div>
          ) : (
            <NotFound 
              text1="No Bank Details added Yet!"
              text2='Add your banking details to recieve your fund'
              btnLabel='Add Banking Details'
              onClick={handleAddNewBank}
            />
          )
        }
        <Modal
          open={showAddForm}
          onClose={() => {
            setToEdit(undefined);
            setData({
              bankName: '',
              name: '',
              number: '',
            });
            setShowAddForm(!showAddForm);
          }}
          overrideStyle={{
            maxWidth: '400px',
            width: '80%',
            height: '550px',
          }}
          title={`${toEdit ? 'Edit' : 'Add '} Bank Account`}
          footer={() => (
            <>
              <Button type='contained' onClick={() => {
                // @ts-ignore
                setToEdit();
                setData({
                  bankName: '',
                  name: '',
                  number: '',
                });
                setShowAddForm(!showAddForm);
              }} label="Cancel" />
              {
                data.bankName && (
                  <Button
                    onClick={handleSubmit}
                    type="flat"
                    btnActionType="submit"
                    label={toEdit?.id ? 'Save' : 'Add'}
                    disabled={!data.name || data.name === 'An error has occurred.'}
                  />
                )
              }
            </>
          )}
        >
          <form className="form !h-max-content" onSubmit={handleSubmit}>
            <div className='select-wrapper'>
              <label>Select Bank Name</label>
              <Select
                className="react-select-container"
                value={{
                  label: data.bankName,
                  value: data.bankName,
                }}
                options={systemBanks?.map((item) => ({
                  label: item.bankName,
                  value: item.bankName,
                }))}
                onChange={(item) => {
                  if (item) {
                    handleChange('bankName', item.value) 
                  }
                }}
                placeholder="Select Bank Name"
              />
            </div>
            {
              (data.bankName || toEdit) && (
                <Input
                  label="Enter 10 digits Account Number"
                  placeholder="10 digit account number"
                  name="number"
                  required
                  value={data.number}
                  pattern={'([0-9]){10}'}
                  onChange={handleAcctFieldChange}
                  type='number'
                />
              )
            }
            {
              (data.name || toEdit) && (
                <Input
                  label="Account Name"
                  placeholder=""
                  name="name"
                  required
                  readonly
                  value={data.name}
                  onChange={handleChange}
                />
              )
            }
          </form>
        </Modal>
        <Modal
          open={confirmDelete}
          onClose={handleCloseDeleteModal}
          overrideStyle={{
            maxWidth: '400px',
            width: '80%',
            height: 'max-content',
          }}
          title="Confirm Bank Delete"
          footer={() => (
            <>
              <Button
                onClick={() => {
                  setConfirmDelete(false);
                }}
                label='No'
                type="contained"
              />
              <Button
                type="contained"
                onClick={() => {
                  removeBank();
                }}
                label="Yes"
              />
            </>
          )}
        >
          <div className="confirm-show-details">
            <p>Are you sure you want to remove this bank? This action will stop the payment of all pending RefundMe using this details as receiving bank.</p>
          </div>
        </Modal>
      </div>
    </DashboardComponentWrapper>
  );
};

export default Bank;

const BankCard = ({bank, handleEditOpen, handleDelete}: {
  bank: BankType
  handleEditOpen: (bank: BankType) => void;
  handleDelete: (bank: BankType) => void;
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [confirmOpenDetails, setConfirmOpenDetails] = useState(false); 

  const handleCloseDetailModal = () => {
    setConfirmOpenDetails(false);
  }
  return (
    <div className='bank-card bg-[#00585D] text-white relative' key={bank.bankName}>
      <img src={BankCardImg} alt="" />
      <svg className='absolute top-0 right-0' width="177" height="196" viewBox="0 0 177 196" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="136.712" cy="58.7116" r="99.1247" transform="rotate(-165 136.712 58.7116)" stroke="#0898A0" stroke-opacity="0.3" stroke-width="25"/>
        <circle cx="151.014" cy="40.75" r="61.9164" transform="rotate(-165 151.014 40.75)" stroke="#0898A0" stroke-opacity="0.3" stroke-width="25"/>
      </svg>
      <div className='details'>
        <p className='text-md capitalize font-normal'>{bank.bankName.toLowerCase()}</p>
        <p className='text-sm capitalize font-normal text-[#FFFFFFB2]'>{bank.account_number ? bank.account_number : ''}</p>
        <p className='text-sm capitalize font-normal text-[#FFFFFFB2]'>{ bank.account_name ? bank.account_name : ''}</p>
      </div>
      <div className='controls'>
        {/* <button className='view' onClick={() => {setShowDetails(!showDetails)}}>
          <img src={showDetails ? Unhide: Hide} alt="" />
        </button> */}
        <div className='options' onClick={() => setShowOptions(!showOptions)}>
          <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 2C4 0.9 3.1 -3.93402e-08 2 -8.74228e-08C0.9 -1.35505e-07 -3.93402e-08 0.9 -8.74228e-08 2C-1.35505e-07 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2ZM4 14C4 12.9 3.1 12 2 12C0.9 12 -5.63877e-07 12.9 -6.11959e-07 14C-6.60042e-07 15.1 0.899999 16 2 16C3.1 16 4 15.1 4 14ZM4 8C4 6.9 3.1 6 2 6C0.9 6 -3.01609e-07 6.9 -3.49691e-07 8C-3.97774e-07 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8Z" fill="white"/>
          </svg>
          {
            showOptions && (
              <div className='actions text-black !text-xm'>
                <span className='!text-xm' onClick={() => handleDelete(bank)}>Delete</span>
                <div className='!text-xm' onClick={() => handleEditOpen(bank)}>Edit</div>
                <button>x</button>
              </div>
            )
          }
        </div>
      </div>
      <Modal
        open={confirmOpenDetails}
        onClose={handleCloseDetailModal}
        overrideStyle={{
          maxWidth: '300px',
          width: '80%',
          height: 'max-content',
        }}
        title="Confirm Open Account Balance"
      >
        <div className="confirm-show-details">
          <h3>Confirm Open Account balance</h3>
          <p>
            By Clicking continue you are giving us permission to fetch you account balance from your bank and keep this balance; this comes at a cost of NGN 10.00. <br />
            <a href="/#">Terms and Conditions</a> apply
          </p>
          <div className="btn-wrapper">
            <Button
              onClick={() => {
                setConfirmOpenDetails(false);
                setShowDetails(true);
              }}
              label='Account Number Only'
              type="contained"
            />
            <Button
              type="contained"
              onClick={() => {
                setConfirmOpenDetails(false);
                setShowDetails(true);
              }}
              label="Continue"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const AddBankForm = ({showForm, acct}: {showForm: boolean, acct?: string}) => {
  const [loadingBanks, setLoadingBanks] = useState(false);
  const {user} = useAppSelector((state) => state.auth);
  const [showAddForm, setShowAddForm] = useState(false);
  const dispatch = useAppDispatch();
  const [toEdit, setToEdit] = useState<BankType>();
  const {banks, systemBanks, systemBanksObj} = useAppSelector((state) => state.bank);

  const handleAddNewBank = () => {
    if (!systemBanks && !loadingBanks) {
      setLoadingBanks(true);
      getSystemBanks(dispatch, () => {
        setShowAddForm(!showAddForm);
        setLoadingBanks(false);
      });
    } else {
      setShowAddForm(!showAddForm);
    }
    scrollUp();
  }

  useEffect(() => {
    setShowAddForm(showForm);
    handleAddNewBank();
  }, [showForm]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      number: acct || ''
    }))
  }, [acct]);
  const [data, setData] = useState({
    name: '',
    number: '',
    bankName: '',
  });
  const [accReference, setAccReference] = useState('');

  useEffect(() => {
    if (toEdit) {
      setData({
        name: toEdit.account_name,
        number: toEdit.account_number,
        bankName: toEdit.bankName,
      });
    }
  }, [toEdit]);

  useEffect(() => {
    if (!banks) {
      getBanks(dispatch);
    }
  }, [banks, dispatch]);

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = () => {
    // event.preventDefault();
    if (!data.bankName || ! data.name || !data.number) {
      toast('Fill all form input to continue');
      return;
    }
    if (toEdit?.id) {
      updateBank({
        ...toEdit,
        name: data.name,
        bankName: data.bankName,
        account_number: data.number,
        bankCode: systemBanksObj[data.bankName].bankCode,
        referenceId: accReference,
      }, dispatch, () => {
        setShowAddForm(!showAddForm);
        setData({
          bankName: '',
          name: '',
          number: '',
        })
      });
    } else {
      const bankExist = banks?.find((bank) => ((bank.bankName === data.name) && (bank.account_number === data.number)));
      if (bankExist) {
        toast.error('Bank with supplied details already added!')
        return;
      }
      addBank({
        account_name: data.name,
        name: data.bankName,
        account_number: data.number,
        bankCode: systemBanksObj[data.bankName].bankCode,
        referenceId: accReference,
        user_id: user?.id,
      }, dispatch, () => {
        setShowAddForm(!showAddForm);
        setData({
          bankName: '',
          name: '',
          number: '',
        });
      });
    }
  }

  const handleVerify = (value: string) => {
    verifyAccount({
      bankCode: systemBanksObj[data.bankName].bankCode,
      account_number: value,
    }, (resp: Record<string, string>) => {
      setData((prevState) => ({
        ...prevState,
        name: resp.data || resp.message,
      }));
      setAccReference(resp.referenceId);
    })
  }

  const handleAcctFieldChange = (name: string, value: string) => {
    handleChange(name, value);
    if (value.length < 10 && data.name) {
      setData((prevState) => ({
        ...prevState,
        name: '',
      }));
    }
    if (value.length === 10) {
      handleVerify(value);
    }
  }

  useEffect(() => {
    if (acct && data.bankName && (data.number.length === 10 || (acct.length === 10))) {
      handleVerify(data.number || acct);
    }
  }, [data.bankName, acct]);
  
  return (
    <Modal
      open={showAddForm}
      onClose={() => {
        setToEdit(undefined);
        setData({
          bankName: '',
          name: '',
          number: '',
        });
        setShowAddForm(!showAddForm);
      }}
      overrideStyle={{
        maxWidth: '400px',
        width: '80%',
        height: '550px',
      }}
      title={`${toEdit ? 'Edit' : 'Add '} Bank Account`}
      subTitle=''
      footer={() => (
        <>
          <Button type='contained' onClick={() => {
            // @ts-ignore
            setToEdit();
            setData({
              bankName: '',
              name: '',
              number: '',
            });
            setShowAddForm(!showAddForm);
          }} label="Cancel" />
          {
            data.bankName && (
              <Button
                onClick={handleSubmit}
                type="flat"
                btnActionType="submit"
                label={toEdit?.id ? 'Save' : 'Add'}
                disabled={!data.name || data.name === 'An error has occurred.'}
              />
            )
          }
        </>
      )}
    >
      <form className="form h-[500px]" onSubmit={handleSubmit}>
        <div className='select-wrapper'>
          <label>Select Bank Name</label>
          <Select
            className="react-select-container"
            value={{
              label: data.bankName,
              value: data.bankName,
            }}
            options={systemBanks?.map((item) => ({
              label: item.bankName,
              value: item.bankName,
            }))}
            onChange={(item) => {
              if (item) {
                handleChange('bankName', item.value);
              }
            }}
            placeholder="Select Bank Name"
          />
        </div>
        {
          (data.bankName || toEdit) && (
            <Input
              label="Enter 10 digits Account Number"
              placeholder="10 digit account number"
              name="number"
              required
              value={data.number}
              pattern={'([0-9]){10}'}
              onChange={handleAcctFieldChange}
              type='number'
            />
          )
        }
        {
          (data.name || toEdit) && (
            <Input
              label="Account Name"
              placeholder=""
              name="name"
              required
              readonly
              value={data.name}
              onChange={handleChange}
            />
          )
        }
      </form>
    </Modal>
  )
}


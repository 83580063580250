import { useState, useCallback } from "react";
import "./expenseDetails.scss";
import RefDetailsCard from "./RefDetailsCard";
import Modal from "../Modal";
import { useAppSelector } from "../../app/hooks";
import { getAllReferralSettings } from "../../actions/adminActions";
import { AdminCreateReferral } from "../../pages/Admin/Referrals/AdminCreateReferal";


interface IExpenseDetails {
    referral: Record<string, any> | null;
    setReferral:(referral:any)=> void
    handleEditDone: () => void;
    handleCloseDetail?: ()=>void;
    isMine?: boolean
}

const ExpenseDetails = (props: IExpenseDetails) => {
    const referral = props.referral;
    console.log("props: ", props);
    
    const expenseMembers = useAppSelector((state) => state.dashboard.expensesUsers[referral?.id])
    const [isEditOpen, setIsEditOpen] = useState(false);

    const handleOpenEditConfirm = useCallback(() => {
        setIsEditOpen(true);
    }, []);

    const handleCloseEditConfirm = useCallback(() => {
        setIsEditOpen(false);
    }, []);

    const handleEditDone = () => {
        getAllReferralSettings(1);
        handleCloseEditConfirm();
        props.handleEditDone();
    }

    return (
        <div className="c-expense-details w-full flex !flex-col">
            <p className="text-2xl border-b-2 border-gray-300 pb-6">Referral Details</p>
            <div className="right mt-1 p-4 w-full !rounded-sm !bg-[#0898A008]">
                <RefDetailsCard
                    title={referral?.title || referral?.name}
                    description={referral?.description}
                    target={referral?.amount}
                    openEdit={handleOpenEditConfirm}
                    id={referral?.id}
                    handleCloseDetail={props.handleCloseDetail}
                    uniqueCode={referral?.uique_code}
                    referral={referral}
                    hasMembers={false}
                    isMine={props.isMine}
                    members={expenseMembers}
                    pendingPayment={false}
                />
            </div>
            <Modal
                open={isEditOpen}
                onClose={handleCloseEditConfirm}
                overrideStyle={{
                    maxWidth: '400px',
                    width: '100%',
                    height: 'max-content',
                }}
                title="Edit Referral"
            >
                <div className="confirm-delete">
                    <AdminCreateReferral referral={referral} handleDone={handleEditDone} />
                </div>
            </Modal>
        </div>
    );
};

export default ExpenseDetails;

import { Link } from "react-router-dom";
import VoucherDashboardComponentWrapper from "../../voucher-layouts/VoucherDashboardComponentWrapper";
import AllVouchersComponent from "../../voucher-components/AllVouchersComponent";

export default function VoucherIndividualDashboardPage() {
  return (
    <VoucherDashboardComponentWrapper 
    title="Welcome to AzatMe Voucher 🥳"
    subTitle="Here is an overview of your account"
    >
      <div className="w-[95%] ">
        <div className="w-[100%] flex justify-between items-center my-5">
          <h4 className="text-[15px] font-[500]">Recent Vouchers Created</h4>
          <Link to={"/vouchers/all-vouchers"} className="underline text-[15px] text-[#0898A0]">View all</Link>
        </div>
        <AllVouchersComponent />
      </div>
    </VoucherDashboardComponentWrapper>
  )
}
import { useEffect, useState } from "react";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import { getAllMerchants, getAllSponsors, getAllUsers } from "../../../actions/adminActions";
import AdminUsersList from "../../../components/AdminUsersList";
import SidePane from "../../../components/SidePane";
import AdminUserDetails from "../../../components/AdminUserDetails";
import TabComponent from "../../../components/TabComponent";
import { useAppSelector } from "../../../app/hooks";
import BusinessOwnersList from "../../../components/AdminUsersList/BusinessOwnersList";

const AdminUsers = () => {
    const { users, merchants, sponsors } = useAppSelector((state) => state.admin)
    const [user, setUser] = useState<Record<string, any> | null>(null);
    useEffect(() => {
        getAllUsers(1);
        getAllMerchants(1);
        getAllSponsors(1);
    }, []);
    return (
        <DashboardComponentWrapper
            subTitle="Users"
            pageDetails="Here's an overview of all Users on AzatMe."
        >
            <>
                <TabComponent
                    components={{
                        "All Users": () => <AdminUsersList getAllUsers={getAllUsers} setUser={(user) => setUser(user)}
                            users={users} />,
                        "Merchants": () => <BusinessOwnersList getAllUsers={getAllMerchants} setUser={(user) => setUser(user)}
                            data={merchants}
                        />,
                        "Sponsors": () => <BusinessOwnersList getAllUsers={getAllSponsors} setUser={(user) => setUser(user)}
                            data={sponsors}
                        />,
                    }}
                />
                <SidePane onClose={() => setUser(null)} open={!!user} title={""}>
                    <AdminUserDetails user={user} setUser={(user) => setUser(user)} />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminUsers;

import VoucherCard from './VoucherCard'

export default function AllVouchersComponent() {
  return (
    <div className="w-[100%] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 bg-[white] p-[20px] rounded-[10px]"
    >
      <VoucherCard />
      <VoucherCard />
      <VoucherCard />
      <VoucherCard />
      <VoucherCard />
      <VoucherCard />
      <VoucherCard />
      <VoucherCard />
    </div>
  )
}

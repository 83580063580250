/* eslint-disable eqeqeq */
import "./details.scss";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Button from "../Button";
dayjs.extend(relativeTime);


const ExpenseDetailsCard = ({
  user,
  kyc,
  handleOpenConfirm
}: {
  user: Record<string, any> | null
  kyc?: Record<string, any> | null
  handleOpenConfirm?: () => void
}) => {

  return (
    <div className="right-wrapper">
      <div className="flex flex-col w-full">
        {
          kyc &&
          (
            <>
              <h4 className="my-3 text-lg font-bold">KYC Details</h4>
              <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
                <p className="text-sm text-az-black-900">Identity Card</p>
                {
                  kyc?.identity_card ?
                    (
                      <a href={kyc?.identity_card} target="__blank" className="text-az-teal underline opacity-80 hover:opacity-100 text-sm" >See File</a>
                    ) :
                    (
                      <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">Not Provided</p>
                    )
                }
              </div>
              <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
                <p className="text-sm text-az-black-900">Utility Bill</p>
                {
                  kyc?.utility_bill ?
                    (
                      <a href={kyc?.utility_bill} target="__blank" className="text-az-teal underline opacity-80 hover:opacity-100 text-sm" >See File</a>
                    ) :
                    (
                      <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">Not Provided</p>
                    )
                }
              </div>
              <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
                <p className="text-sm text-az-black-900">Proof  Of Address</p>
                {
                  kyc?.proof_of_address ?
                    (
                      <a href={kyc?.proof_of_address} target="__blank" className="text-az-teal underline opacity-80 hover:opacity-100 text-sm" >See File</a>
                    ) :
                    (
                      <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">Not Provided</p>
                    )
                }
              </div>
              <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
                <p className="text-sm text-az-black-900">Business Reg. Cert.</p>
                {
                  kyc?.business_registration_certificate ?
                    (
                      <a href={kyc?.business_registration_certificate} target="__blank" className="text-az-teal underline opacity-80 hover:opacity-100 text-sm" >See File</a>
                    ) :
                    (
                      <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">Not Provided</p>
                    )
                }
              </div>

              <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
                <p className="text-sm text-az-black-900">Business Reg. No.</p>
                <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{kyc?.business_registration_number}</p>
              </div>
              <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300 ">
                <p className="text-sm text-az-black-900">Company Name</p>
                <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{kyc?.company_name}</p>
              </div>
              <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300 mb-3">
                <p className="text-sm text-az-black-900">Description</p>
                <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{kyc?.description}</p>
              </div>
              <Button label="Update KYC Status" type="flat" onClick={handleOpenConfirm} overrideStyle={{ margin: "0" }} />
            </>
          )
        }
        <h4 className="my-3 text-lg font-bold mt-5">User Details</h4>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Name</p>
          <p className="text-sm text-az-black-900">{user?.first_name || user?.name} {user?.last_name} {user?.middle_name}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Email</p>
          <p className="text-sm text-az-black-900">{user?.email}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Phone</p>
          <p className="text-sm text-az-black-900 uppercase">{user?.phone}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Address</p>
          <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{user?.address}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">LGA</p>
          <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{user?.lga_of_origin}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">State</p>
          <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{user?.state}</p>
        </div>

      </div>
    </div>
  )
}

export default ExpenseDetailsCard
import { type Dispatch, type SetStateAction, useCallback, useEffect, useState } from 'react';
import NotFound from '../NotFound';
import Input from '../Input';
import Button from '../Button';
import './createTransaction.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useGetBusinessCode from '../../hooks/useGetBusinessCode';
import { createProduct,  getCustomers, getProducts, getTransactionOptions } from '../../actions/businessActions';
import Order from '../Order';
import { IProduct } from '../../pages/Business/businessSlice';
import TablePaginationFooter from '../TablePaginationFooter/TablePaginationFooter';
import { useNavigate } from 'react-router-dom';


const CreateCatalogue = (props:{handleClose:()=>void, isOpen:boolean}) => {
  const {isLoading} = useAppSelector(state => state.loader);
  const [order, setOrder] = useState<IProduct | null>(null);
  const dispatch = useAppDispatch();
  const customerObj = useAppSelector((state) => state.business.customers);
  const productsObj = useAppSelector((state) => state.business.products);
  const transactionOptions = useAppSelector((state) => state.business.transactionOptions);
  const [businessCode] = useGetBusinessCode();
  const customers = customerObj[businessCode as string];
  const [step, setStep] = useState(0);
  // const [selectedBank, setSelectedBank] = useState<Bank>();
  const [data, setData] = useState({
    name: '',
    total: '',
    category: '',
    subcategory: '',
    description: '',
    id: '',
    link: '',
    splittingId: '',
    moto: '',
    dueDate: '',
    bankName: '',
    transactionSendType: [],
    uniqueCode: '',
    customerName: '',
    customerEmail: '',
    quantity: '0',
  });
  const products = productsObj[businessCode as string];

  useEffect(() => {
    if (!products) {
      businessCode && getProducts(dispatch, businessCode, 1);
    }
  }, [businessCode, products]);

  useEffect(() => {
    if (step === 2 && !transactionOptions) {
      getTransactionOptions(dispatch);
    }
  }, [dispatch, step, transactionOptions]);

  useEffect(() => {
    if (!customers) {
      businessCode && getCustomers(dispatch, businessCode);
    }
  }, [businessCode, customers]);

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const isFirst = step === 1;
  const isSecond = step === 2;
  const isThird = step === 3;
  const renderStep = () => {
    return (
      <div className="stepper">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isThird ? 'active' : ''}`} />
      </div>
    )
  };

  const handleCreateProduct = (event) => {
    event.preventDefault();
    createProduct({
      name: data.name,
      description: data.description,
      amount: data.total,
      // categoryId: 1,
      // subCategoryId: 2,
      // quantity: data.quantity,
      business_code: businessCode,
    }, dispatch, businessCode, (resp) => {
      setOrder(resp);
      setStep(0);
    })
  }

  return (
    <div className="w-full h-full">
      <div className='w-full flex flex-col justify-center items-start'>
        {renderStep()}
        <h3 className='mb-5 text-3xl'>{`New Service/Product`}</h3>
      </div>
      <form onSubmit={handleCreateProduct}  >
        <Input
          label="Name"
          placeholder="Product Name e.g IPhone 12"
          name="name"
          required
          value={data.name}
          onChange={handleChange}
        />
        <Input
          label="Amount"
          placeholder="0"
          name="total"
          required
          value={data.total}
          onChange={handleChange}
        />
        <Input
          label="Quantity"
          placeholder="Quantity e.g 10"
          name="quantity"
          required
          value={data.quantity}
          onChange={handleChange}
        />
        <Input
          label="Description"
          placeholder="Description"
          name="description"
          required
          value={data.description}
          onChange={handleChange}
          maxLength={100}
        />
        <Button
          label="Create"
          type="flat"
          btnActionType="submit"
          disabled={isLoading}
          overrideStyle={{
            marginTop: '30px'
          }}
        />
      </form>
    </div>
  );
};


export const CatalogueList = (props:{handleCreate:Dispatch<SetStateAction<boolean>>}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const productsObj = useAppSelector((state) => state.business.products);
  const [businessCode] = useGetBusinessCode();
  const products = productsObj[businessCode as string] as Record<string, any>;

  useEffect(() => {
    if (!products) {
      businessCode && getProducts(dispatch, businessCode, 1);
    }
  }, [businessCode, products]);

  const handlePagination = () => {
    getProducts(dispatch, businessCode, 1);
  }

  return (
    <div className="orders">
      {
        Array.isArray(products?.data) && products?.data.length > 0 ? (
          <>
            <p className='title'>All Your Services/Products.</p>
            <div className='flex flex-row justify-center lg:justify-start items-start flex-wrap'>
              {
                Array.isArray(products?.data) && products?.data.map((order, index) =>  (
                  <Order
                    order={order} 
                    setOrder={() => {
                      navigate(`/business/dashboard/${businessCode}/transactions`, {state: {order}})
                    }}
                    key={index}
                  />))
              }
            </div>
            <div style={{
              width: '92%',
            }}>
                {
                  products && (
                    <TablePaginationFooter 
                      total={products.total}
                      from={products.from}
                      to={products.to}
                      nextUrl={products.next_page_url}
                      prevUrl={products.prev_page_url}
                      handlePagination={handlePagination}
                    />
                  )
                }
            </div>
          </>
        ) : (
          <NotFound 
            text1="You have not created a Service or Product yet!"
            text2={`Create your first Service or Product to continue your transaction creation journey.`}
            btnLabel="Create a Service/Product"
            onClick={() => props.handleCreate(true)}
          />
        )
      }
    </div>
  );
}

export default CreateCatalogue;

import TabComponent from "../../components/TabComponent";
import { ManualKYCVerification } from "../BusinessKYC";
import VoucherDashboardComponentWrapper from "../Voucher/voucher-layouts/VoucherDashboardComponentWrapper";

export default function SponsorKYC() {
  return (
    <VoucherDashboardComponentWrapper
      title="Sponsor KYC Verification"
      subTitle=""
    >
      <div className="py-5 w-full">
        <TabComponent
          isVoucherTab={true}
          components={{
            "Manual Verification": () => <ManualKYCVerification />,
          }}
        />
      </div>
    </VoucherDashboardComponentWrapper>
  )
}
import axios from "axios";
import { logout, getWithdrawalHistory } from '../pages/Auth/authSlice';
import { 
  addExpenses,
  addExpense,
  deleteExpense,
  updateExpense,
  addCategories,
  addCategory,
  addSubCategories,
  addUserGroup,
  addUserGroups,
  addSplitFormula,
  addUsers,
  addUser,
  updateExpensesMetaData,
  addSubCategory,
  deleteCategory,
  updateCategory,
  deleteSubCategory,
  addExpenseMembers,
  addUserGroupMembers,
  updateExpenseBalance,
  updateGroupBalance,
  updateIssue,
  addIssues,
  updateSubCategory,
  deleteGroup,
  updateKontribute,
  updateKontributeMember,
  updateRefundMember,
  toggleSideNav,
  getAjos,
  getAjoInvitations,
  addOpenUserGroups,
  setDraftKontribute,
  setDraftRefund,
} from "../pages/Dashboard/dashboardSlice";
import {updateLoader} from '../pages/Loader/loaderSlice';
import { silentHTTPCaller, toastWrapper } from "../utils/toastWrapper";
import {appDispatch} from '../app/store';
import getBaseURL from "../utils/getBaseURL";

const url = getBaseURL() as string;

export const handleSideNavToggle = (state) => {
  appDispatch(toggleSideNav(state));
}

export const handleLogout = (navigate?: any) => {
  toastWrapper(
    axios.get(`${url}logout`), 
    'Logging you out...', 
    (resp) => {
      appDispatch(logout());
      localStorage.removeItem('tazat');
      localStorage.clear();
      return 'Logged out Successful!';
    }, 
    'Error logging you out!'
  );
}


export const getExpenses = (page = 1, isAdmin = false) => {
  const login = isAdmin ? axios.get(`${url}getAllExpenses?page=${page}`)
   : axios.get(`${url}getUserExpense?page=${page}`);

  silentHTTPCaller(login, 'Getting your RefundMes...', (resp) => {
    appDispatch(addExpenses(resp.data.data));
    appDispatch(updateExpensesMetaData(resp.data));
    if (resp.data.length === 0) {
      return 'No RefundMe!';
    }
    return resp.data.message || 'RefundMes gotten successfully!';
  }, 'Error getting RefundMe.');
}

export const addExpenseActionCreator = (data, handleNext) => {
  appDispatch(updateLoader(true));
  toastWrapper(
    axios.post(`${url}createExpense`, data), 
    'Creating your RefundMe...', 
    (resp) => {
      appDispatch(addExpense(resp.data));
      handleNext(resp.data.id, resp.data.uique_code);
      return resp.data.message || 'RefundMe created successfully!';
    }, 
    'Error creating RefundMe.',
  );
}

export const addUserToExpenseActionCreator = (data, handleNext) => {
  toastWrapper(
    axios.post(`${url}userExpense/${data.expenseId}`, data.payload), 
    'Inviting User(s) to your RefundMe...', 
    (resp) => {
      // appDispatch(addUserToExpense({data: resp?.datasplitPayResult?.result, expenseId: data.expenseId}));
      handleNext(resp.data);
      return resp.data.message || 'Invite sent successfully!';
    }, 
    'Error inviting user.',
  );
}

export const removeUserFromExpenseActionCreator = () => {}

export const deleteExpensesActionCreator = (data, closeForm) => {
  toastWrapper(
    axios.delete(`${url}deleteExpense/${data.id}`), 
    'Deleting RefundMe...', 
    (resp) => {
      appDispatch(deleteExpense(data));
      closeForm();
      return resp.data.message || 'RefundMe deleted successfully!';
    }, 
    'Error deleting RefundMe.',
  );
}

export const deleteUserGroupActionCreator = (data, closeForm) => {
  toastWrapper(
    axios.delete(`${url}deleteGroup/${data.id}`), 
    'Deleting Kontribute...', 
    (resp) => {
      appDispatch(deleteGroup(data));
      closeForm();
      return resp.data.message || 'Kontribute deleted successfully!';
    }, 
    'Error deleting Kontribute.',
  );
}

export const updateKontributeActionCreator = (data, closeForm) => {
  toastWrapper(
    axios.put(`${url}updateGroup/${data.id}`, data), 
    'Updating your kontribute...',
    (resp) => {
      appDispatch(updateKontribute(resp.data));
      closeForm();
      return resp.data.message || 'Kontribute updated successfully!';
    }, 
    'Error updating kontribute.',
  );
}

export const updateExpenseActionCreator = (data, closeForm) => {
  toastWrapper(
    axios.put(`${url}updateExpense/${data.id}`, data), 
    'Updating your refundMe...',
    (resp) => {
      appDispatch(updateExpense(resp.data));
      closeForm();
      return resp.data.message || 'RefundMe updated successfully!';
    }, 
    'Error updating refundMe.',
  );
}

export const updateRefundMemberActionCreator = (data, closeForm) => {
  toastWrapper(
    axios.put(`${url}update-payback-transaction/${data.id}`, data), 
    'Updating member...',
    (resp) => {
      appDispatch(updateRefundMember(data));
      closeForm();
      return resp.data.message || 'Member updated successfully!';
    }, 
    'Error updating member.',
  );
}

export const updateKontributeMemberActionCreator = (data, closeForm) => {
  toastWrapper(
    axios.put(`${url}update-kontribute-transaction/${data.id}`, data), 
    'Updating member...',
    (resp) => {
      appDispatch(updateKontributeMember(data));
      closeForm();
      return resp.data.message || 'Member updated successfully!';
    }, 
    'Error updating member.',
  );
}

export const getCategoriesActionCreator = () => {
  silentHTTPCaller(
    axios.get(`${url}getCateList`), 
    'Loading categories...', 
    (resp) => {
      appDispatch(addCategories(resp.data));
      return resp.data.message || 'Categories loaded';
    }, 
    'Error Loading categories',
  );
}

export const createCategoriesActionCreator = (data, handleNext) => {
  toastWrapper(
    axios.post(`${url}category`, data), 
    'Creating your category...', 
    (resp) => {
      appDispatch(addCategory({data: resp.data}));
      handleNext(resp.data.id)
      return resp.data.message || 'Category created successfully!';
    }, 
    'Error creating category.'
  );
}

export const updateCategoriesActionCreator = (data, handleOnDone) => {
  toastWrapper(
    axios.put(`${url}updateCategory/${data.categoryId}`, {name: data.name}),
    'Updating category...',
    (resp) => {
      appDispatch(updateCategory({ data: resp.data }));
      handleOnDone();
      return resp.data.message || 'Category updated'
    },
    'Error updating category'
  )
}

export const delCategoryActionCreator = (categoryId, handleOnDone) => {
  toastWrapper(
    axios.delete(`${url}deleteExpenseCategory/${categoryId}`),
    'Deleting category...',
    (resp) => {
      appDispatch(deleteCategory({ categoryId }));
      handleOnDone()
      return resp.data.message || 'Category deleted'
    },
    'Error deleting category'
  )
}

export const getSubCategoriesActionCreator = (categoryId) => {
  toastWrapper(
    axios.get(`${url}getSubCateListPerCategory/${categoryId}`), 
    'Loading sub categories...', 
    (resp) => {
      appDispatch(addSubCategories({data: resp.data, categoryId }));
      return resp.data.message || 'Subcategories Loaded';
    }, 
    'Error Loading sub categories',
  );
}

export const createSubCategoriesActionCreator = (data, handleOnDone) => {
  toastWrapper(
    axios.post(`${url}SubCategory`, {...data}), 
    'Creating your sub category...', 
    (resp) => {
      appDispatch(addSubCategory({data: resp.data[0], categoryId: data.category_id }));
      handleOnDone(resp.data[0]);
      return resp.data.message || 'Sub category created successfully!';
    }, 
    'Error creating sub category.'
  );
}

export const updateSubCategoryActionCreator = (data, handleOnDone) => {
  toastWrapper(
    axios.put(`${url}updateSubCategory/${data.subCategoryId}`, {name: data.name}), 
    'Updating your Sub Category...', 
    (resp) => {
      appDispatch(updateSubCategory({data: resp.data, categoryId: data.categoryId }));
      handleOnDone();
      return resp.data.message || 'Sub category updated successfully!';
    }, 
    'Error updating sub category.'
  );
}

export const delSubCategoryActionCreator = ({subCategoryId, categoryId}, handleOnDone) => {
  toastWrapper(
    axios.delete(`${url}deleteExpenseSubCategory/${subCategoryId}`),
    'Deleting sub category...',
    (resp) => {
      appDispatch(deleteSubCategory({ subCategoryId, categoryId }));
      handleOnDone();
      return resp.data.message || 'Sub category deleted'
    },
    'Error deleting sub category'
  )
}

export const addSubCategoriesActionCreator = () => {}

export const getUserGroupsActionCreator = (page = 1) => {
  const login = axios.get(`${url}getUserGroup?page=${page}`);
  silentHTTPCaller(login, 'Getting your user kontributes...', (resp) => {
    appDispatch(addUserGroups(resp.data));
    if (resp.data.length === 0) {
      return 'No kontribute found!';
    }
    return resp.data.message || 'Kontributes gotten successfully!';
  }, 'Error getting user kontributes.');
}

export const addUserGroupActionCreator = (data, handleNext) => {
  toastWrapper(
    axios.post(`${url}createGroup`, data), 
    'Creating user Kontribute...', 
    (resp) => {
      if (data.openKontribution === 'Yes') {

      } else {
        appDispatch(addUserGroup(resp.data));
      }
      handleNext(resp.data.id);
      return resp.data.message || 'User kontribute created successfully!';
    }, 
    'Error creating User Kontribute.'
  );
}

export const addParticipantToGroupActionCreator = (data, handleNext) => {
  toastWrapper(
    axios.post(`${url}inviteUsersToGroup/${data.groupId}`, data.payload), 
    'Inviting users to your Kontribute...', 
    (resp) => {
      if (!resp.data.successful) {
        throw new Error(resp.data.message);
      }
      handleNext(resp.data);
      return resp.data.message || 'Invite sent successfully!';
    }, 
    'Error inviting user.'
  );
}

export const createLinkForOpenGroup = (data, handleNext) => {
  toastWrapper(
    axios.post(`${url}inviteUsersToGroup/${data.groupId}`, data.payload), 
    'Creating sharable payment Link...', 
    (resp) => {
      if (!resp.data.successful) {
        throw new Error(resp.data.message);
      }
      handleNext(resp.data);
      return resp.data.message || 'Link successfully created!';
    }, 
    'Error creating open kontribution.'
  );
}


export const getPaymentDetailsForOpenGroup = (data, handleNext) => {
  // merchantReference is transactionReference
  toastWrapper(
    axios.get(`${url}get-donor/${data.merchantReference}`, data.payload), 
    'Getting transaction details for this Kontribution...', 
    (resp) => {
      handleNext(resp.data);
      return resp.data.message || 'Transactions gotten successfully!';
    }, 
    'Error loading transactions.'
  );
}

export const getPaymentOpenGroups = (page = 1, handleNext) => {
  silentHTTPCaller(
    axios.get(`${url}get-openLink-transactions?page=${page}`), 
    'Getting transaction details for this Kontribution...', 
    (resp) => {
      handleNext(resp.data);
      appDispatch(addOpenUserGroups(resp.data));
      return resp.data.message || 'Transactions gotten successfully!';
    }, 
    'Error loading transactions.'
  );
}

export const getOpenGroups = (page = 1, handleNext) => {
  silentHTTPCaller(
    axios.get(`${url}get-funds?page=${page}`), 
    'Getting transaction details for this Kontribution...', 
    (resp) => {
      handleNext(resp.data);
      appDispatch(addOpenUserGroups(resp.data));
      return resp.data.message || 'Transactions gotten successfully!';
    }, 
    'Error loading transactions.'
  );
}

export const removeParticipantToGroupActionCreator = () => {}

export const getSplitformula = () => {
  const login = axios.get(`${url}getSplittingMethods`);
  silentHTTPCaller(login, 'Getting your split methods...', (resp) => {
    appDispatch(addSplitFormula(resp.data));
    if (resp.data.length === 0) {
      return 'No Split Method found!';
    }
    return resp.data.message || 'Split methods loaded successfully!';
  }, 'Error loading Split Methods.');
}

export const getContacts = () => {
  const login = axios.get(`${url}get-invited-users`);
  toastWrapper(login, 'Getting your Contacts...', (resp) => {
    appDispatch(addUsers(resp.data));
    if (resp.data.length === 0) {
      return 'No contact found!';
    }
    return resp.data.message || 'Contacts Loaded Successfully!';
  }, 'Error loading contact.');
}

export const inviteContact = (data, handleDone) => {
  const login = axios.post(`${url}add-users`, data);
  toastWrapper(login, 'Inviting your Contact...', (resp) => {
    handleDone(resp);
    appDispatch(addUser(resp.data));
    return resp.data.message || 'Contact added Successfully!';
  }, 'Error Inviting contact.');
}

export const getExpenseMembers = (expenseId) => {
  const login = axios.get(`${url}getAmountsPaidPerExpense/${expenseId}`);
  toastWrapper(login, 'Getting RefundMe members...', (resp) => {
    appDispatch(addExpenseMembers({members: resp.data, expenseId}));
    if (resp.data.length === 0) {
      return 'No member found!';
    }
    return resp.data.message || 'Members Loaded Successfully!';
  }, 'Error loading members.');
}

export const getUserGroupsMembers = (groupId) => {
  const login = axios.get(`${url}list-users-per-Group/${groupId}`);
  toastWrapper(login, 'Getting Kontribute members...', (resp) => {
    appDispatch(addUserGroupMembers({members: resp.data, groupId}));
    if (resp.data.length === 0) {
      return 'No member found!';
    }
    return resp.data.message || 'Members Loaded Successfully!';
  }, 'Error loading members.');
}

export const getExpenseBalance = (expenseId) => {
  const login = axios.get(`${url}getAmountsPaidPerExpense/${expenseId}`);
  toastWrapper(login, 'Getting RefundMe balance...', (resp) => {
    appDispatch(updateExpenseBalance({data: resp.data, expenseId}));
    return resp.data.message || 'Balance Loaded Successfully!';
  }, 'Error loading Balance.');
}

export const getGroupBalance = (groupId) => {
  const login = axios.get(`${url}list-users-per-Group/${groupId}`);
  toastWrapper(login, 'Getting Kontribute balance...', (resp) => {
    appDispatch(updateGroupBalance({data: resp.data, groupId}));
    return resp.data.message || 'Balance Loaded Successfully!';
  }, 'Error loading Balance.');
}

export const addIssue = (data, handleOnDone) => {
  const login = axios.post(`${url}makeComplain`, data);
  toastWrapper(login, 'Sending Feedback...', (resp) => {
    appDispatch(updateIssue({data: resp.data}));
    handleOnDone()
    return resp.data.message || 'Feedback sent Successfully!';
  }, 'Error Sendiing Issue.');
}

export const getIssues = (page = 1) => {
  const login = axios.get(`${url}get-complains-per-user?page=${page}`);
  silentHTTPCaller(login, 'Getting Feedback...', (resp) => {
    appDispatch(addIssues({data: resp.data}));
    return resp.data.message || 'Feedback sent Successfully!';
  }, 'Error getting Issues.');
}

export const getExpenseReport = (data, handleOnDone) => {
  const call = axios({
    method:'post',
    url: `${url}export-${data.exportType}`,      
    responseType: 'blob',
    data: {
      startDate: data.startDate,
      endDate: data.endDate
    },
    headers: { 
      'Content-Type': 'blob',
    }
  })
  toastWrapper(call, 'Generating your Report...', (resp) => {
    handleOnDone(resp)
    return resp.data.message || 'Report Downloaded!';
  }, 'Error generating report.');
}

export const getGroupReport = (data, handleOnDone) => {
  const call = axios({
    method:'post',
    url: `${url}export-${data.exportType}`,      
    responseType: 'blob',
    data,
    headers: { 
      'Content-Type': 'blob',
    }
  })
  toastWrapper(call, 'Generating your Report...', (resp) => {
    handleOnDone(resp)
    return resp.data.message || 'Report Downloaded!';
  }, 'Error generating report.');
}

export const getOTPForWithdrawal = (data, handleOnDone) => {
  const call = axios({
    method:'post',
    url: `${url}send-otp`,      
    data,
  })
  toastWrapper(call, `Sending OTP to you via ${data.medium}...`, (resp) => {
    handleOnDone(resp)
    return resp.data.message || 'OTP sent!';
  }, 'Error sending OTP!.');
}

export const verifyWithdrawalOTP = (data, handleOnDone) => {
  const call = axios({
    method:'post',
    url: `${url}check-mobile-number`,      
    data,
  })
  toastWrapper(call, 'Verifying the OTP...', (resp) => {
    if (resp.data.status !== 200) {
      throw new Error(resp.data.message); 
    }
    handleOnDone(resp);
    return resp.data.message || 'OTP Verified!';
  }, 'Error verifying OTP!.');
}

export const verifyEmailWithdrawalOTP = (data, handleOnDone) => {
  const call = axios({
    method:'put',
    url: `${url}confirm-email`,      
    data,
  })
  toastWrapper(call, 'Verifying the OTP...', (resp) => {
    if (resp.data.status !== 200) {
      throw new Error(resp.data.message); 
    }
    handleOnDone(resp);
    return resp.data.message || 'OTP Verified!';
  }, 'Error verifying OTP!.');
}

export const withDrawFundGroup = (data, handleOnDone) => {
  const payload = {...data};
  delete payload['id'];
  const call = axios.post(`${url}group-settlement`,payload);
  toastWrapper(call, 'Initiating the Fund Withdrawal...', (resp) => {
    handleOnDone(resp)
    return resp.data.message || 'Fund withdraw Request sent successfully!';
  }, 'Error initiating fund withdrawal!');
}

export const withDrawFundExpense = (data, handleOnDone) => {
  const payload = {...data};
  delete payload['id'];
  const call = axios.post(`${url}collection`,  payload)
  toastWrapper(call, 'Initiating the Fund Withdrawal...', (resp) => {
    handleOnDone(resp)
    return resp.data.message || 'Fund withdraw Request sent successfully!';
  }, 'Error initiating fund withdrawal!');
}

export const withdrawAjo = (data, handleOnDone) => {
  const payload = {...data};
  delete payload['id'];
  const call = axios.post(`${url}ajo-payout`,  payload)
  toastWrapper(call, 'Initiating the Fund Withdrawal...', (resp) => {
    handleOnDone(resp)
    return resp.data.message || 'Fund withdraw Request sent successfully!';
  }, 'Error initiating fund withdrawal!');
}

export const getRefundMeBalance = (data, handleOnDone) => {
  const call = axios.get(`${url}recreate-underpaid-transactions/${data.id}/${data.uid}`)
  toastWrapper(call, 'Creating a balance link...', (resp) => {
    handleOnDone(resp)
    return resp.data.message || 'Balance link sent to user!';
  }, 'Error creating balance link!');
}

export const getKontributeBalance = (data, handleOnDone) => {
  const call = axios.get(`${url}re-initiate-transaction/${data.id}/${data.uid}`)
  toastWrapper(call, 'Creating a balance link...', (resp) => {
    handleOnDone(resp)
    return resp.data.message || 'Balance link sent to user!';
  }, 'Error creating balance link!');
}

export const getWithdrawalHistoryKontribute = () => {
  const call = axios.get(`${url}get-kontribute-withdrawal-response`)
  toastWrapper(call, 'Getting Kontribute withdrawal History...', (resp) => {
    appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'kontribute'}));
    return resp.data.message || 'Withdrawal History Loaded!';
  }, 'Error loading withdrawal History!');
}

export const getWithdrawalHistorAjo = () => {
  const call = axios.get(`${url}get-kontribute-withdrawal-response`)
  toastWrapper(call, 'Getting Kontribute withdrawal History...', (resp) => {
    appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'ajo'}));
    return resp.data.message || 'Withdrawal History Loaded!';
  }, 'Error loading withdrawal History!');
}

export const getWithdrawalHistoryRefundMe = () => {
  const call = axios.get(`${url}get-refund-withdrawal-response`)
  toastWrapper(call, 'Getting RefundMe withdrawal History...', (resp) => {
   appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'refundme'}));
    return resp.data.message || 'Withdrawal History Loaded!';
  }, 'Error loading withdrawal History!');
}

export const createAjo = (data, handleDone) => {
  const call = axios.post(`${url}create-ajo`, data)
  toastWrapper(call, 'Creating your ajo...', (resp) => {
  //  appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'refundme'}));
  handleDone(resp.data);
    return resp.data.message || 'Ajo created successfully!';
  }, 'Error creating ajo!');
}

export const acceptAjoWithDD = (data, handleDone, errorCB) => {
  const call = axios.post(`${url}create-dd-mandate/${data.ajoId}`, data)
  toastWrapper(call, 'Configuring direct debit on this ajo...', (resp) => {
  handleDone(resp.data);
    return resp.data.message || 'Direct debit configuration succeeded!';
  }, 'Error configuring direct debit!', undefined, errorCB);
}

export const inviteUserAjo = (data, handleDone) => {
  const call = axios.post(`${url}invitation/${data.ajoId}`, data)
  toastWrapper(call, 'Inviting users to the ajo...', (resp) => {
  //  appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'refundme'}));
  handleDone(resp.data);
    return resp.data.message || 'Users invited!';
  }, 'Error inviting users!');
}

export const getUserAjo = (page) => {
  const call = axios.get(`${url}get-ajo-per-user?page=${page}`)
  toastWrapper(call, 'Getting your ajos...', (resp) => {
   appDispatch(getAjos(resp.data || null));
    return resp.data.message || 'Your ajo loaded successfully!';
  }, 'Error loading ajo!');
}
// I could refactor this to use recieve an extra argument the http caller to user, silent or toastWRAPPER IN PLACE OF THE REPEATION
export const getUserAjoSilent = (page) => {
  const call = axios.get(`${url}get-ajo-per-user?page=${page}`)
  silentHTTPCaller(call, 'Getting your ajos...', (resp) => {
   appDispatch(getAjos(resp.data || null));
    return resp.data.message || 'Your ajo loaded successfully!';
  }, 'Error loading ajo!');
}

export const getAjoInvitation = (page) => {
  const call = axios.get(`${url}get-invitation?page=${page}`)
  silentHTTPCaller(call, 'Getting ajo details...', (resp) => {
    appDispatch(getAjoInvitations(resp.data || null));
    return resp.data.message || 'Ajo details loaded successfully!';
  }, 'Error loading ajo details!');
}

export const getAjoById = (id, handleDone) => {
  const call = axios.get(`${url}get-ajo-by-id/${id}`)
  silentHTTPCaller(call, 'Getting ajo details...', (resp) => {
  //  appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'refundme'}));
    handleDone(resp.data)
    return resp.data.message || 'Ajo details loaded successfully!';
  }, 'Error loading ajo details!');
}

export const getAjoMembers = (id, handleDone) => {
  const call = axios.get(`${url}get-ajo-user-bank-details/${id}`)
  silentHTTPCaller(call, 'Getting ajo details...', (resp) => {
  //  appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'refundme'}));
    handleDone(resp.data)
    return resp.data.message || 'Ajo details loaded successfully!';
  }, 'Error loading ajo details!');
}

export const getAjoContributors = (id, handleDone) => {
  const call = axios.get(`${url}get-ajo-contributors/${id}`)
  silentHTTPCaller(call, 'Getting ajo payment details...', (resp) => {
  //  appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'refundme'}));
    handleDone(resp.data)
    return resp.data.message || 'Ajo details loaded successfully!';
  }, 'Error loading ajo payment details!');
}

export const getAjoContributor = (merchantReference, email, handleDone) => {
  const call = axios.get(`${url}get-ajo-contributor/${merchantReference}/${email}`)
  silentHTTPCaller(call, 'Getting ajo details...', (resp) => {
  //  appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'refundme'}));
    handleDone(resp.data)
    return resp.data.message || 'Ajo details loaded successfully!';
  }, 'Error loading ajo details!');
}

export const declineAjoInvite = (data: any, handleDone) => {
  const call = axios.post(`${url}decline-ajo`, data)
  toastWrapper(call, 'Sending your response...', (resp) => {
    handleDone();
    return resp.data.message || 'Thank you, your response was sent successfully!';
  }, 'Error sending your response.');
}


export const acceptAjoInvite = (ajoId: string, data: any, handleDone) => {
  const call = axios.post(`${url}request-manual-paylink/${ajoId}`, data)
  toastWrapper(call, 'Sending your response...', (resp) => {
    handleDone()
    return resp.data.message || 'Thank you, your response was sent successfully!';
  }, 'Error sending your response!');
}


export const getKontributeReport = (handleDone) => {
  const call = axios.get(`${url}getKontributeReport`)
  silentHTTPCaller(call, 'Sending your response...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Thank you, your response was sent successfully!';
  }, 'Error sending your response!');
}

export const getRefundMeReport = (handleDone) => {
  const call = axios.get(`${url}getExpenseReport`, )
  silentHTTPCaller(call, 'Sending your response...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Thank you, your response was sent successfully!';
  }, 'Error sending your response!');
}

export const getRefundDraft = (page = 1, handleDone) => {
  const call = axios.get(`${url}get-draft-refundme?page=${page}`, )
  silentHTTPCaller(call, 'Sending your response...', (resp) => {
    appDispatch(setDraftRefund(resp.data));
    handleDone(resp);
    return resp.data.message || 'Thank you, your response was sent successfully!';
  }, 'Error sending your response!');
}

export const getKontributeDraft = (page = 1, handleDone) => {
  const call = axios.get(`${url}get-draft-kontribute?page=${page}`, )
  silentHTTPCaller(call, 'Sending your response...', (resp) => {
    appDispatch(setDraftKontribute(resp.data));
    handleDone(resp);
    return resp.data.message || 'Thank you, your response was sent successfully!';
  }, 'Error sending your response!');
}

export const getAjoWithdrawalhistory = (page = 1, handleDone) => {
  const call = axios.get(`${url}get-ajo-withdrawal?page=${page}`, )
  silentHTTPCaller(call, 'Fetching your ajo withdrawal history...', (resp) => {
    appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'ajo'}));
    handleDone(resp);
    return resp.data.message || 'Withdrawal history loaded successful!';
  }, 'Error loading withdrawal history!');
}

export const getFeedbackComment = (feedbackId, handleDone) => {
  const call = axios.get(`${url}feedback-by-id/${feedbackId}`, )
  silentHTTPCaller(call, 'Fetching your comments...', (resp) => {
    appDispatch(getWithdrawalHistory({data: resp.data.data || null, entity: 'ajo'}));
    handleDone(resp.data);
    return resp.data.message || 'Comments loaded successful!';
  }, 'Error loading comments!');
}

export const addCommentToFeedback = (feedbackId, data, handleDone) => {
  const call = axios.post(`${url}create/comment/${feedbackId}`, data)
  toastWrapper(call, 'Saving your comment...', (resp) => {
    // appDispatch(getWithdrawalHistory({data: resp.data.data || null, entity: 'ajo'}));
    handleDone(resp.data);
    return resp.data.message || 'Comment saved successfully!';
  }, 'Error saving comment!');
}

export const getDirectDebitBanks = (handleDone) => {
  const call = axios.get(`${url}get-dd-bankList`)
  silentHTTPCaller(call, 'Loading banks...', (resp) => {
    handleDone(resp.data);
    return resp.data.message || 'Comment saved successfully!';
  }, 'Error loading Direct Debit banks!');
}

export const getUserMandates = (handleDone) => {
  const call = axios.get(`${url}user/mandates`)
  silentHTTPCaller(call, 'Loading mandates...', (resp) => {
    handleDone(resp.data);
    return resp.data.message || 'Loaded successfully!';
  }, 'Error loading ajo direct debit records!');
}

export const getMandatesStatus = (mandateId, ajoId, handleDone) => {
  const call = axios.get(`${url}getMandateStatus/${mandateId}/${ajoId}`)
  silentHTTPCaller(call, 'Loading mandates...', (resp) => {
    handleDone(resp.data);
    return resp.data.message || 'Loaded successfully!';
  }, 'Error loading ajo direct debit records!');
}


// https://web.dev/learn/pwa/update/

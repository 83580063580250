import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const TabComponent = (
    { components, isVoucherTab = false, defaultTab }:
        { components: Record<string, () => JSX.Element>, isVoucherTab?: boolean, defaultTab?: number }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const defaultTabParam = searchParams.get('tab');
    const tabs = Object.keys(components);
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const Component = defaultTab !== undefined ? components[defaultTab] : components[currentTab];

    useEffect(() => {
        if (defaultTabParam) {
            setCurrentTab((tabs[parseInt(defaultTabParam)]))
        }
    }, [])
    return (
        <div className="w-full h-full flex flex-col gap-[10px] px-5 md:px-8">
            {
                isVoucherTab ?
                    (
                        <div className="w-full flex justify-center md:justify-start">
                            <div className="w-fit h-fit flex flex-row flex-wrap gap-2 justify-start items-center bg-white p-[10px] rounded-[30px]">
                                {
                                    tabs.map((tab) => (
                                        <button
                                            key={tab} onClick={() => setCurrentTab(tab)}
                                            className="w-fit !rounded-full h-full p-[10px] flex justify-center items-center ease-in-out transition-all text-[14px] font-[500]"
                                            style={{
                                                backgroundColor: currentTab === tab ? '#0898A01A' : "transparent",
                                            }}
                                        >
                                            {tab}
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className="w-full h-16 flex flex-row justify-start items-center border-b border-az-gray-120">
                            {
                                tabs.map((tab) => (
                                    <button
                                        key={tab} onClick={() => setCurrentTab(tab)}
                                        className="w-fit min-w-[120px] mr-3 !rounded-none h-full"
                                        style={{
                                            borderBottom: currentTab === tab ? '4px solid #0898A0' : '4px solid transparent',
                                        }}
                                    >
                                        {tab}
                                    </button>
                                ))
                            }
                        </div>
                    )
            }

            <div className="w-full flex flex-auto flex-grow">
                <Component />
            </div>
        </div >
    )
}

export default TabComponent;

import { FormEvent, useCallback, useEffect, useState } from 'react';
import Input, { Select } from '../Input';
import Button from '../Button';
import './index.scss';
import { useAppSelector } from '../../app/hooks';
import { addIssue } from '../../actions/dashboardActions';
import { getAllInquiries } from '../../actions/adminActions';

const CreateIssues = (props:{
  handleDone:()=>void,
  name:string,
  uniqueCode: string}
) => {
  const {user} = useAppSelector((state) => state.auth);
  const {isLoading} = useAppSelector(state => state.loader);
  const [data, setData] = useState({
    name: '',
    severity: '',
    expense_name: '',
    description: '',
    uniqueCode: '',
  });

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      expense_name: props.name,
      // uniqueCode: props.uniqueCode,
      name: user?.name || '',
    }));
  }, [props.name, props.uniqueCode, user?.name]);


  const handleAddFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    addIssue({
      name: data.name,
      severity: data.severity.toLowerCase(),
      description: data.description,
      complain_reference_code: data.uniqueCode,
      expense_name: data.expense_name
    }, (id) => {
      setData({
        name: '',
        severity: '',
        expense_name: '',
        description: '',
        uniqueCode: '',
      });
      getAllInquiries(1);
      props.handleDone();
    });
  }

  return (
    <form onSubmit={handleAddFormSubmit} className='h-fit'>
      <div className="input-grp-wrapper">
        <Select
          data={['','General','Ajo', 'RefundMe', 'Kontribute', 'Business']}
          label="Select Product"
          placeholder="Product Name"
          name="expense_name"
          required
          value={data.expense_name}
          onChange={handleChange}
        />
        <Input
          label="Reference Code"
          placeholder=""
          name="uniqueCode"
          value={data.uniqueCode}
          onChange={handleChange}
        />
      </div>
      <Select
        data={['High', 'Medium','Low']}
        label="Severity"
        placeholder="Severity"
        name="severity"
        required
        value={data.severity}
        onChange={handleChange}
      />
      <Input
        label="Description"
        placeholder="Description"
        name="description"
        required
        value={data.description}
        onChange={handleChange}
        maxLength={100}
      />
      <Button
        label="Send Feedback"
        type="flat"
        btnActionType="submit"
        disabled={isLoading}
      />
    </form>
  );
};

export default CreateIssues;

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import FacebookIcon from '../../assets/facebook.png';
import WhatsappIcon from '../../assets/whatsapp.png';
import Button from '../Button';
import Input from '../Input';
import './template.scss';

const Link = () => {
  const {businesses} = useAppSelector((state) => state.business);
  const {pathname} = useLocation() as Record<string, any> || {};
  const business = businesses?.find((busi) => busi.business_code === pathname.split('/')[3]);

  const [editMode, setMode] = useState(false);
  const [data, setData] = useState({
    genericImg: '',
    body: '',
    greating1: '',
    greating2: '',
    whatsapp: '',
    facebook: '',
    twitter: '',
    instagram: '',
  });

  useEffect(() => {
    setData({
      genericImg: '',
      body: 'Thank You for patronising us, this is the payment details for your recent purchase of at our office/shop. You can click the link to make you payment or download the attached invoice for payment details',
      greating1: 'Hello',
      greating2: 'Thank You and see you again.',
      whatsapp: '',
      facebook: '',
      twitter: '',
      instagram: '',
    });
  }, []);

  const handleOnChange = (field, value) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }

  return (
    <div className="email-template">
      <Button
        label={editMode ? 'Save' : 'Edit'}
        type="flat"
        onClick={() => {
          setMode(!editMode);
        }}
      />
      <header>
        <img src={business?.business_logo} alt="Logo" className='logo' />
        <h3>{business?.business_name}</h3>
        <p>{business?.description}</p>
        <p>{business?.business_address}</p>
        <p>{business?.business_phone}</p>
      </header>
      <div className="generic-img-wrapper">
        {editMode ? (
          <div className="input-wrapper">
            <Input value="" name="genericImg" type='file' label="Select a Business Banner" />
          </div>
        ) : <img src="" alt="Generic" />}
      </div>
      <div className="body">
        {
          editMode ? (
            <Input
              name='greating1'
              label=""
              onChange={handleOnChange}
              value={data.greating1}
            />
          ) : <h3>{data.greating1}</h3>
        }
        <h4 className='customer-name'>Customer Name</h4>
        {
          editMode ? (
            <textarea name="" id="" cols={30} onChange={(value) => handleOnChange('body', value)}>
              {data.body}
            </textarea>
          ) : <p>{data.body}</p>
        }
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#">Payment Link</a>
        {
          editMode ? (
            <Input
              name='greating2'
              label=""
              value={data.greating2}
              onChange={handleOnChange}
            />
          ) : <p>{data.greating2}</p>
        }
      </div>
      <footer>
        {
          editMode ? (
            <Input
              name='facebook'
              placeholder="Link to Facebook"
              label=""
              value={data.facebook}
              onChange={handleOnChange}
            />
          ) : <img src={FacebookIcon} alt="F" className='socia-icon' />
        }
        {
          editMode ? (
            <Input
              name='whatsapp'
              placeholder="Link to Whatsapp"
              label=''
              value={data.whatsapp}
            />
          ) : <img src={WhatsappIcon} alt="W"  className='socia-icon' />
        }
      </footer>
      <div className="powered-by">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href=''>Powered By AzatMe Business</a>
      </div>
    </div>
  )
};

export default Link;

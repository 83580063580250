import { useEffect, useState } from "react";
import { getAjoCount, getRefundMeCount, getKontributeCount, getBusinessCount } from "../../../actions/adminActions";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";

const AdminOverview = () => {
    const [counts, setCounts] = useState({
        ajo: 0,
        refundme: 0,
        kontribute: 0,
        business: 0
    });
    const handleSetCount = (product, count) => {
        setCounts((prev) => ({
            ...prev,
            [product]: count
        }))
    }
    useEffect(() => {
        getAjoCount((resp) => {
            handleSetCount('ajo', resp?.data?.count)
        });
        getRefundMeCount((resp) => {
            handleSetCount('refundme', resp?.data?.count)
        });
        getKontributeCount((resp) => {
            handleSetCount('kontribute', resp?.data?.count)
        });
        getBusinessCount((resp) => {
            handleSetCount('business', resp?.data?.count)
        });
      }, []);
    const products = [
        {
            name: 'Ajo',
            key: 'ajo'
        },
        {
            name: 'RefundMe',
            key: 'refundme'
        },
        {
            name: 'Kontribute',
            key: 'kontribute'
        },
        {
            name: 'Business',
            key: 'business'
        },
    ]
    return (
        <DashboardComponentWrapper
            subTitle="Overview"
            pageDetails="Here's an overview of all products on AzatMe."
        >
            <div className="p-[2%]">
                    <div className="w-full flex flex-wrap flex-row">
                        {
                            products.map((itm) => (
                                <div className="mb-4 w-36 m-2 p-2 rounded h-20 border border-black" key={itm.name}>
                                    <div className='flex flex-row w-full justify-between items-center'>
                                        <span className="">{counts[itm.key]}</span>
                                    </div>
                                    <div className='flex flex-row flex-wrap'>
                                        <p className='text-xl font-light'>{itm.name}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminOverview;

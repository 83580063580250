export default function MerchantVoucherSVG() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#00A1EC" />
      <path d="M21.5417 13.5419V15.5585" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.5417 24.8V26.4867" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.5417 21.9371V17.9196" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5864 26.6666C27.1048 26.6666 28.3346 25.4521 28.3346 23.9526V21.7922C27.3299 21.7922 26.5207 20.9931 26.5207 20.0008C26.5207 19.0086 27.3299 18.2086 28.3346 18.2086L28.3338 16.0474C28.3338 14.5479 27.1031 13.3333 25.5855 13.3333H14.4171C12.8995 13.3333 11.6688 14.5479 11.6688 16.0474L11.668 18.279C12.6727 18.279 13.4819 19.0086 13.4819 20.0008C13.4819 20.9931 12.6727 21.7922 11.668 21.7922V23.9526C11.668 25.4521 12.8978 26.6666 14.4162 26.6666H25.5864Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
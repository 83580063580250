import { useEffect, useState } from "react";
import { getUserReferrals, getUserReferrees, joinReferrerCall } from "../../actions/authActions";
import { useAppDispatch } from "../../app/hooks";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import { navigatorShare } from "../../utils/common";

import './profile.scss';
import TabComponent from "../../components/TabComponent";
import useVerificationDone from "../../hooks/useVerificationDone";
import NotFound from "../../components/NotFound";
import { setShowVerificationPending } from "../Auth/authSlice";
import TablePaginationFooter from "../../components/TablePaginationFooter/TablePaginationFooter";

export const ReferrerComponent = () => {
  const dispatch = useAppDispatch();
  const {isVerificationDone} = useVerificationDone();
  const [referrees, setReferrees] = useState<Record<string, string | [] | number>>({});
  const [referrals, setReferrals] = useState<Record<string, string>[]>([]);
  const getReferrees = (page: number) => {
    getUserReferrees(page, (resp) => {
      setReferrees(resp.data.referrals);
    });
  }

  const getReferrals = () => {
    getUserReferrals((resp) => {
      setReferrals(resp.data);
    });
  }
  
  const handleJoin = () => {
    if (!isVerificationDone) {
      dispatch(setShowVerificationPending(true));
      return
    } else {
      joinReferrerCall((resp) => {
        getReferrals();
        getReferrees(1);
      });
    }
  }
  useEffect(() => {
    getReferrals();
    getReferrees(1);
  }, []);

  const handleShare = () => {
    navigatorShare({
      title: 'AzatMe',
      text: `Use this code to join AzatMe: ${referrals[0].ref_url}, code: ${referrals[0].ref_code}`,
      url: referrals[0].ref_url,
      email: '',
    }, dispatch);
  }

  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    getReferrees(page);
  }
  return (
    <div className="w-full h-full min-h-[70vh] flex flex-col justify-start items-center">
      {
        referrals.length ? (
          <div className="w-full !h-full flex flex-col justify-center">
            <div className="flex flex-row justify-between flex-wrap items-center my-3 bg-[#0898A01A] p-3 rounded">
              <div>
                <p className="font-bold"> Your Referral Points: {referrals[0].point || 0}</p>
              </div>
              <div className="flex flex-row flex-wrap justify-center items-center">
                <div className="flex flex-col justify-start items-start">
                  <p className="font-bold my-2">Your Referral link: {referrals[0].ref_url}</p>
                </div>
                <button onClick={handleShare} className="bg-az-teal px-3 py-2 text-white rounded-sm mx-3">Share</button>
              </div>
            </div>
            <div>
              <div className='head bg-[#0898A01A] h-14 hidden lg:flex'>
                <div className='row flex flex-row justify-between !w-full items-center !h-14'>
                  <div className='!w-[20%] pl-3'>Referral Code</div>
                  <div className='!w-[30%] pl-3'>Referree Name</div>
                  <div className='!w-[30%] pl-3'>Referree Email</div>
                  <div className='!w-[20%] pl-3'>Joined Date</div>
                </div>
              </div>
              <div>
                {
                  (referrees.data as Array<Record<string, string>>)?.map((item, index) => {
                    return (
                      <ReferralRow
                        key={item.id}
                        referral={item}
                        setReferral={() => null}
                      />
                    );
                  })
                }
              </div>
              {
                (referrees?.data as Array<Record<string, any>>)?.length !== 0 && (
                <TablePaginationFooter 
                    total={referrees?.total as number}
                    from={referrees?.from as number}
                    to={referrees?.to as number}
                    nextUrl={referrees?.next_page_url as string}
                    prevUrl={referrees?.prev_page_url as string}
                    handlePagination={handlePagination}
                />
                )
            }
            </div>
          </div>
        ) : (
          <NotFound 
            text1="You have not joined AzatMe referral service"
            text2='Begin the Journey by creating your referral link.'
            btnLabel='Join Referral'
            onClick={() => handleJoin()}
          />
        )
      }
    </div>
  )
}


const Referrer = () => {
  return (
    <DashboardComponentWrapper title="Referrer" pageDetails="You can manage your referrer and promotions here">
      <TabComponent
        components={{
          Referrer: ReferrerComponent,
        }}
      />
    </DashboardComponentWrapper>
  );
};

export default Referrer;

export const ReferralRow = ({ referral, setReferral }: any) => {
  return (
    <>
      <div className='row flex-row w-full  hidden lg:flex cursor-pointer hover:bg-az-faint-red' onClick={() => setReferral(referral)}>
        <div className='w-[20%] pl-3'>{referral.ref_code}</div>
        <div className='w-[30%] pl-3'>{referral.referee_name}</div>
        <div className='w-[30%] pl-3'>{referral.referee_email}</div>
        <div className='w-[20%] pl-3'>{referral.created_at}</div>
      </div>
      <div  onClick={() => setReferral(referral)} className='row rounded !flex-col !p-4 !justify-start !items-start !m-auto flex my-2 lg:hidden border border-gray !min-h-[100px] !h-max !w-[96%] cursor-pointer'>
        <div className='w-[100%] pl-3'>{referral.ref_code}</div>
        <div className='w-[100%] pl-3'>{referral.referee_name}</div>
        <div className='w-[100%] pl-3'>{referral.referee_email}</div>
        <div className='w-[100%] pl-3'>{referral.created_at.split('T')[0]}</div>
      </div> 
    </>
  )
}

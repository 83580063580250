import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getOTPForWithdrawal, verifyEmailWithdrawalOTP, verifyWithdrawalOTP } from "../../actions/dashboardActions";
import { emailPattern } from "../../hooks/useAuthRedirect";
import Button from "../Button";
import Input from "../Input";
import Modal from "../Modal";
import './withdrawMFA.scss';
import BankInput from "../BankInput";
import { Bank } from "../../pages/Bank/bankSlice";
import { useAppSelector } from "../../app/hooks";
import { getLedger, getWithdrawalCharges } from "../../actions/authActions";
import { formatCurrency } from "../../utils/common";
import useVerificationDone from "../../hooks/useVerificationDone";

const WithdrawMFA = ({ openWithdrawMFA, handleCloseModal, handleDone, item, source = '' }) => {
  const [adminCharges, setAdminCharges] = useState(0);
  const [step, setStep] = useState(0);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [otpSource, setOTPSource] = useState<'SMS' | 'email'>('email');
  const [data, setData] = useState({
    phone: '',
    email: '',
    otp: '',
    amount: '',
    bankName: '',
  });
  const { ledger } = useAppSelector((state) => state.auth);
  const { isVerificationDone } = useVerificationDone();
  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  useEffect(() => {
    if (isVerificationDone) {
      if (!ledger.Wallet) {
        getLedger();
      }
    }
    getWithdrawalCharges((data) => {
      if (data.charges) {
        setAdminCharges(data.charges);
      }
    });
  }, []);

  const getSourceBalance = () => {
    let available = 0;
    if (source === 'refundme') {
      available = ledger.AmountLeftForWithdrawRefundme;
    } else if (source === 'kontribute') {
      available = ledger.AmountLeftForWithdrawGroup;
    } else if (source === 'business') {
      available = ledger.AmountLeftForWithdrawBusiness;
    } else if (source === 'ajo') {
      available = ledger.AmountLeftForWithdrawalAjo;
    }
    return parseInt(available?.toLocaleString()) || 0;
  }

  const handleGetOtp = () => {
    const payload: Record<string, string> = {};
    if (otpSource === 'email' && emailPattern.test(data.email)) {
      payload.email = data.email;
    } else if (otpSource === 'SMS' && /^(\+)?(234)[0-9]{10}$/.test(data.phone)) {
      payload.phone = data.phone;
    } else {
      toast.error(`Please enter a valid ${otpSource === 'SMS' ? 'Phone number' : otpSource} to continue!`);
      return;
    }
    payload.medium = otpSource;
    getOTPForWithdrawal(payload, () => {
      setStep(1);
    });
  };

  const verifySMSOtp = () => {
    verifyWithdrawalOTP({
      otp: data.otp,
      phone: data.phone,
    }, () => {
      handleDone(data.amount, selectedBank, () => {
        closeModal();
      });
      // closeModal()
    });
  }

  const verifyEmailOtp = () => {
    verifyEmailWithdrawalOTP({
      otp: data.otp,
      email: data.email,
    }, () => {
      handleDone(data.amount, selectedBank, () => {
        closeModal();
      });
      // closeModal();
    });
  }

  const handleVerifyOtp = () => {
    if (!data.otp) {
      toast.error(`Please enter OTP sent to your ${otpSource}!`);
      return
    }
    if (!data.amount) {
      toast.error(`Please enter amount to withdraw!`);
      return
    }
    if (parseInt(data.amount) < 100) {
      toast.error(`Withdraw amount must be more than NGN 99.00`);
      return
    }
    if (parseInt(data.amount) > getSourceBalance()) {
      toast.error(`You can not withdraw more than your balance!`);
      return
    }
    if (!selectedBank) {
      toast.error(`Please select a collection Bank to continue!`);
      return
    }
    otpSource === 'SMS' ? verifySMSOtp() : verifyEmailOtp();
  }

  const closeModal = () => {
    setData({
      phone: '',
      email: '',
      otp: '',
      amount: '',
      bankName: '',
    });
    setStep(0);
    handleCloseModal();
  };

  const getExactAmount = () => {
    const amountNum = parseInt(data.amount);
    return amountNum - adminCharges;
  }

  const sources: Record<string, string> = {
    refundme: 'RefundMe',
    kontribute: 'Kontribute',
    ajo: "Ajo"
  }

  return (
    <Modal
      open={openWithdrawMFA}
      onClose={closeModal}
      overrideStyle={{
        maxWidth: '400px',
        width: '80%',
        height: 'max-content',
        marginTop: '70px',
        border: '1px solid red',
        paddingBottom: '30px'
      }}
      title="Withdrawal Verification"
      footer={() => (
        <>
          <Button
            type="contained"
            onClick={closeModal}
            label="Cancel"
          />
          <Button
            type="flat"
            onClick={() => {
              step === 0 ? handleGetOtp() : handleVerifyOtp();
            }}
            label={step === 0 ? 'Get OTP' : 'Withdraw'}
          />
        </>
      )}
    >
      {
        !ledger.Wallet ? (
          <div>
            <p>Loading ledger...</p>
          </div>
        ) : (
          <div className="confirm-show-details withdrawal-wrapper">
            <p className="text-sm">
              {`Provide Your ${otpSource === 'email' ? 'Email Address' : 'Phone number'} to get a verification code via your ${otpSource}.`}
            </p>
            <div className="withdraw-balance">
              <p className="text-sm">{source === 'transactions' ? 'Business' : sources[source]} Balance: </p>
              <b className="text-sm">NGN {getSourceBalance()}</b>
            </div>
            <div className="btn-wrapper">
              {
                step === 0 && (otpSource === 'SMS' ? (
                  <Input
                    type="tel"
                    label="Phone Number"
                    placeholder="+2347011223344"
                    name="phone"
                    pattern="[789][0-9]{9}"
                    required
                    value={data.phone}
                    onChange={handleChange}
                    ctaText="Use Email"
                    handleCTAClick={() => setOTPSource('email')}
                  />
                ) : (
                  <Input
                    label="Email"
                    placeholder=""
                    type="email"
                    name="email"
                    required
                    value={data.email}
                    onChange={handleChange}
                    // ctaText="Use Phone"
                    handleCTAClick={() => setOTPSource('SMS')}
                  />
                ))
              }
              {
                step === 1 && (
                  <>
                    <Input
                      label="Enter OTP"
                      placeholder="3344"
                      name="otp"
                      required
                      value={data.otp}
                      onChange={handleChange}
                      ctaText="Resend OTP"
                      handleCTAClick={handleGetOtp}
                    />
                    <div style={{
                      width: '100%',
                      textAlign: 'left'
                    }}>
                      <BankInput
                        setSelectedBank={(value) => {
                          setSelectedBank(value);
                          handleChange('bankName', value.bankName);
                        }}
                      />
                    </div>
                    <Input
                      label="Enter withdraw amount"
                      placeholder="0"
                      name="amount"
                      required
                      value={data.amount}
                      onChange={handleChange}
                    />
                    {
                      adminCharges > 0 && parseInt(data.amount) >= 100 && (
                        <h5 className="bg-[#0898A026] p-3 rounded-md">
                          Withdraw charge of {formatCurrency({ num: adminCharges })} applies, NGN {formatCurrency({ num: getExactAmount() })} will be withdrawn to your account.
                        </h5>
                      )
                    }
                  </>
                )
              }
            </div>
          </div>
        )
      }
    </Modal>
  );
};

export default WithdrawMFA;

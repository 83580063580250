const prod = process.env.REACT_APP_BASE_URL_PROD;
const dev = process.env.REACT_APP_BASE_DEV;

const env = process.env.REACT_APP_ENV

const getBaseURL = () => {
  return (env && (env === 'development' || env === 'staging')) ? prod : prod;
};

export default getBaseURL;

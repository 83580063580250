import { Link, useNavigate } from 'react-router-dom';
import Button from '../Button';
import UnAuthGroupImg from '../../assets/new/unauth-circles.png';
import RightSphere from '../../assets/new/rightSphere.png';
import Logo from '../../assets/Logo.svg';

import './unAuthwrapper.scss';

interface IUnAuthWrapper {
  title: string;
  subTitle: string;
  ctaQuestion: string;
  ctaText: string;
  ctaRoute: string;
  children: React.ReactElement;
  subTitleComponent?: () => React.ReactElement;
  overrideContentClass?: string;
  ctaAction?: () => void;
  img?: string;
}

function UnAuthWrapper({
  title, subTitle, children, ctaQuestion, ctaRoute, ctaText, subTitleComponent, ctaAction, img
}: IUnAuthWrapper) {
  const navigate = useNavigate();
  const testimonials = [
    {
      testimony: 'AzatMe is the quickest method for sending and ensuring rapid receipt by the beneficiary account.',
      name: 'Temitope Alex',
      type: 'Student',
      img: ''
    },
    // {
    //   testimony: 'AzatMe is the quickest method for sending and ensuring rapid receipt by the beneficiary account.',
    //   name: 'Temitope Alex',
    //   type: 'Student',
    //   img: ''
    // },
    // {
    //   testimony: 'AzatMe is the quickest method for sending and ensuring rapid receipt by the beneficiary account.',
    //   name: 'Temitope Alex',
    //   type: 'Student',
    //   img: ''
    // },
    // {
    //   testimony: 'AzatMe is the quickest method for sending and ensuring rapid receipt by the beneficiary account.',
    //   name: 'Temitope Alex',
    //   type: 'Student',
    //   img: ''
    // }
  ];

  const handleGoBack = () => {
    navigate(-1);
  }
  return (
    <div className="w-screen h-screen  p-2 flex flex-row">
      <div className='hidden unauth-left-wrapper lg:flex w-4/12 un-auth-left bg-[#470401] flex-col justify-between px-12 py-10 rounded-md relative overflow-hidden'>
        <img src={UnAuthGroupImg} alt="" className='absolute top-[-40px] right-[-100px] object-cover' />
        <Link to="/"><img src={Logo} alt="" className='w-40 object-contain' /></Link>
        <div className='flex flex-col justify-start items-start'>
          <p className='text-4xl text-white text-left leading-normal'>
            Start your journey to
            payment and finance
            solution with us
          </p>
          <p className=' text-base text-white text-left leading-normal'>
            Join the numerous satisfied individuals and Businesses, 
            managing their Finances and collecting funds with
            AzatMe.
          </p>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <div className='w-full h-[200px] bg-[#2E8184] rounded-md p-8 flex flex-col justify-between items-center'>
            <p className=' text-base text-white text-left leading-normal'>{testimonials[0].testimony}</p>
            <div className='flex flex-row justify-start items-start w-full'>
              {/* <img src={testimonials[0].img} className='w-10 h-10 rounded-full mr-4' /> */}
              <p className='w-10 h-10 text-3xl text-white font-extrabold rounded-full mr-4'>
                {testimonials[0].name.split(' ')[0][0].toUpperCase()}{testimonials[0].name.split(' ')[1][1].toUpperCase()}
              </p>
              <div className='flex flex-col justify-start items-start'>
                <p className='text-sm text-white text-left leading-normal'>{testimonials[0].name}</p>
                <p className='text-sm text-white text-left leading-normal'>{testimonials[0].type}</p>
              </div>
            </div>
          </div>
          <div className='flex flex-row mt-4'>
            {testimonials.map((itm, index) => (<div key={index} className='w-3 h-3 ml-1 bg-white rounded-full' />))}
          </div>
        </div>
      </div>
      <div className="h-full flex flex-col flex-grow px-5 py-10 lg:px-20">
        <div className='w-full flex flex-row justify-between items-center mb-4'>
          <div className='flex flex-row justify-start items-center cursor-pointer ' onClick={handleGoBack}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
              <path d="M16.8115 6.125L3.30608 6.125L8.13141 1.23375L6.90566 1.20742e-06L-1.24053e-06 7L6.90566 14L8.12278 12.7663L3.30608 7.875L16.8115 7.875L16.8115 6.125Z" fill="#0898A0"/>
            </svg>
            <span className='mx-4 text-az-teal'>Go back</span>
          </div>
          <span className='form-cta'>{ctaQuestion} 
            {
              ctaAction ? (
                <Button
                  label={ctaText}
                  type='text'
                  overrideStyle={{
                    color: 'rgba(80,44,43,.882)',
                    width: 'max-content',
                    marginLeft: '0.5rem'
                  }}
                  onClick={ctaAction}
                />
              ) : (
                <Link className='ml-2' to={`/${ctaRoute}`}>
                  <Button
                    label={ctaText}
                    type='text'
                    overrideStyle={{
                      color: 'rgba(80,44,43,.882)',
                      width: 'max-content',
                    }}
                  />
                </Link>
              )
            }
          </span>
        </div>
        <div className='flex flex-col  m-auto min-w-[350px]'>
          {img && <img src={img} alt="" className='!w-16 !h-16 mb-6' />}
          <div className='w-full flex flex-col justify-start items-start'>
            <h1 className='text-3xl text-az-black-900'>{title}</h1>
            {subTitleComponent ? subTitleComponent() : <p className='text-az-200 max-w-[400px] text-left text-base my-3 w-full'>{subTitle}</p>}
          </div>
          <div className='children'>
            {
              children
            }
          </div>
        </div>
      </div>
      <img src={RightSphere} alt="" className='absolute bottom-[-0px] right-[-0px] object-cover' />
    </div>
  );
}

export default UnAuthWrapper;
import { Outlet } from "react-router-dom";
import "./IndividualDashboard.scss"
import DashboardSideBar from "../../../../../components/DashboardSideBar";
import DashboardSVG from "../../../../../components/SVG/Dashboard";
import ReportsSVG from "../../../../../components/SVG/ReportsSVG";
import SettingsSVG from "../../../../../components/SVG/Settings";
import TransactionsSVG from "../../../../../components/SVG/TransactionsSVG";
import VoucherSVG from "../../../../../components/SVG/Voucher";

export default function IndividualDashboard() {
  const navItems = [
    {
      name: "dashboard",
      path: `/vouchers`,
      title: "Dashboard",
      Icon: DashboardSVG,
    },
    {
      name: "all-vouchers",
      path: `/vouchers/all-vouchers`,
      title: "AzatMe Vouchers",
      Icon: VoucherSVG,
    },
    {
      name: "transactions",
      path: `#`,
      title: "Transactions",
      Icon: TransactionsSVG,
    },
    {
      name: "reports",
      path: `#`,
      title: "Reports",
      Icon: ReportsSVG,
    },
  ]
  const downNavItems = [
    {
      name: "settings",
      path: `#`,
      title: "Settings",
      Icon: SettingsSVG,
    },
  ]

  return (
    <div className="voucherDashboard flex flex-row justify-between items-start !relative">
      <DashboardSideBar
        userType="individual"
        items={navItems}
        firstItem={"Home"}
        voucherSideBar={true}
        downItemes={downNavItems}
        showBusinessSwitch={false}
        showVerificationStatus={false}
      />
      <div className="outlet flex flex-auto flex-grow">
        <Outlet />
      </div>
    </div>
  )
}
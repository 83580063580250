import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import { getAllComplains } from "../../../actions/adminActions";
import IssueDetail from "../../../components/IssueDetails";
import SidePane from "../../../components/SidePane";
import IssuesListTab from "../../Issues/IssuesList";

const AdminFeedback = () => {
    const {issues} = useAppSelector((state) => state.admin);
    const [issue, setIssue] = useState<null | Record<string, any>>(null);
    useEffect(() => {
        getAllComplains(1)
    }, []);
    return (
        <DashboardComponentWrapper
            subTitle="Feedback"
            pageDetails="Here's an overview of all Feedback on AzatMe."
        >
            <>
                <IssuesListTab handleCreate={() => {}} issues={issues} setIssue={(issue) => setIssue(issue)} />
                <SidePane onClose={() => setIssue(null)} open={!!issue} title={""}>
                    <IssueDetail issue={issue} setIssue={(issue) => setIssue(issue)} />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminFeedback;

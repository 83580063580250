import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import CreateTransaction from "../../components/CreateTransaction";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import SwitchBusiness from "../../components/SwitchBusiness";
import TransactionListTab from "../../components/TransactionList";
import CreateIcon from '../../assets/new/createIcon.png';
import SidePane from "../../components/SidePane";
import TransactionDetails from "../../components/TransactionDetails";

const transactionNavItems = [
  'My Transactions', 
  // 'Recieved', 
  'New', 'Withdrawal History'];

const BusinessTransactions = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [tab, setTab] = useState(transactionNavItems[0]);
  const {allExpenses} = useAppSelector((state) => state.dashboard);
  const location = useLocation() as any;

  const expenseNavItemsObj = [
    {
      label: 'New Transaction',
      action: () => setOpenCreate(true),
      bgColor: '#0898A0',
      img: CreateIcon,
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        navItems={[]}
        navItemsObj={expenseNavItemsObj}
        tab={tab}
        setTab={() => {}}
        dontShowWithdraw
      />
    )
  }

  const splittedPath = location.pathname.split('/')
  const pageTitle = splittedPath[splittedPath.length - 1] === 'directlinks' ? 'Direct Links' : 'Invoices';

  return (
    <DashboardComponentWrapper
      overrideName={() => <SwitchBusiness />}
      title={pageTitle}
      renderNavItems={renderNavItems}
    >
      <>
        {/* @ts-ignore */}
        <TransactionListTab handleMore={(itm) => setTransaction(itm)} handleCreate={() => setOpenCreate(true)} allExpenses={allExpenses} expense={location.state?.expense} />
        <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
          <CreateTransaction handleDone={() => setTab('All ')} />
        </SidePane>
        <SidePane
          onClose={() => setTransaction(null)}
          open={!!transaction}
          title={""}
        >
          <TransactionDetails expense={transaction} handleCloseDetail={() => setTransaction(null)} />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  )
};

export default BusinessTransactions;

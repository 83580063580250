import { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import Input from '../Input';
import Button from '../Button';
import {
  createCategoriesActionCreator,
  createSubCategoriesActionCreator,
  delSubCategoryActionCreator
} from '../../actions/dashboardActions';

const CreateCategory = (props:{handleDone?:()=>void}) => {
  const [subCates, setSubCates] = useState<Array<Record<string, any>>>([]);
  const [step, setStep] = useState(0);
  const [categoryId, setCategoryId] = useState('');
  const [data, setData] = useState({
    name: '',
    total: '',
    category: '',
    subcategory: '',
    description: '',
  });

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const isFirst = step === 0;
  const isSecond = step === 1;
  const renderStep = () => {
    return (
      <div className="stepper">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
      </div>
    )
  };

  const handleAddCategory = (event: FormEvent) => {
    event.preventDefault();
    createCategoriesActionCreator({
      name: data.name,
    }, (categoryId) => {
      setStep(1);
      setCategoryId(categoryId);
    });
  };

  const addSubCategory = (data: string) => {
    createSubCategoriesActionCreator({
      name: data,
      category_id: categoryId
    }, (resp) => {
      const newSubCates = [...subCates];
      newSubCates.push(resp)
      setSubCates(newSubCates);
    });
  }

  const removeSubCategory = (data) => {
    delSubCategoryActionCreator({subCategoryId: data.id, categoryId}, () => {
      setSubCates(subCates.filter((subCate) => subCate.id !== data.id));
    });
  }

  return (
    <div className="create-expense">
      <div>
        <h3>{step === 0 ? 'Create Category' : 'Add Sub Categories'}</h3>
        {renderStep()}
      </div>
      {
        isFirst && (
          <form onSubmit={handleAddCategory}>
            <Input
              label="Category Name"
              placeholder="Category Name"
              name="name"
              required
              value={data.name}
              onChange={handleChange}
            />
            <Button
              label="Create Category"
              type="flat"
              btnActionType="submit"
            />
          </form>
        )
      }
      {
        isSecond && (
          <div className='user-group-cont'>
            <div className='user-group-wrapper'>
             {subCates.length > 0 && ( <div>
                {
                  subCates.reverse().map((subCate) => (
                    <div
                      className='particpant-wrapper admin'
                      key={subCate.name}
                    >
                      <p>{subCate.name}</p>
                      <button
                        onClick={() => removeSubCategory(subCate)}
                      >
                        x
                      </button>
                    </div>
                  ))
                }
              </div>)}
            </div>
            <AddSubCategoryForm
              addSubCate={addSubCategory}
              subCates={subCates}
            />
            <div className='btn-group'>
              <Button
                label="Done"
                onClick={props.handleDone && props.handleDone}
                type="flat"
                btnActionType="button"
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CreateCategory;

interface IAddSubCategoryForm{
  addSubCate: (data:string)=>void,
  subCates:Record<string, any>
}
export const AddSubCategoryForm = ({ addSubCate, subCates }:IAddSubCategoryForm) => {
  const [data, setData] = useState('');

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const added = subCates.indexOf(data);
    if (added >= 0) {
      toast.error('Sub Category already added!');
      return
    }
    addSubCate(data);
    setData('');
  }

  return (
    <form className='add-participant-wrapper' onSubmit={handleSubmit}>
      <input
        placeholder="Add Sub Category"
        name="subCategory"
        type="text"
        required
        value={data}
        onChange={({target}) => setData(target.value)}
      />
      <Button
        label="Add"
        type="flat"
        btnActionType="submit"
        overrideStyle={{
          width: '50px',
          margin: '0 !important',
        }}
      />
    </form>
  )
}

import { useState, useEffect } from "react";
import "./transactionDetails.scss";
import { ProductCard, TransactionUserCard } from '../UserCard';
import ExpenseDetailsCard from "./TransactionDetailsCard";
import { getSubCategoriesActionCreator } from "../../actions/dashboardActions";
import { useAppSelector } from "../../app/hooks";
import { SubCategry, Category } from "../../pages/Dashboard/dashboardSlice";
import { useNavigate } from "react-router-dom";

export const getAggregatedMembers = (members: Array<any>) => {
    const aggregatedMembers = {};
    members.forEach((member) => {
        if (aggregatedMembers[member.email]) {
            const newItem = {...aggregatedMembers[member.email]};
            newItem.hasBalanceLink = !member.residualAmount;
            const amount = parseInt(newItem.totalAmount) + parseInt(member.residualAmount || '0');
            newItem.totalAmount = amount;
            newItem.hasPendingPayment = newItem.defaultPayable > amount;
            aggregatedMembers[member.email] = newItem;
        } else {
            const newItem = {...member}
            newItem.defaultPayable = newItem.payable;
            newItem.totalAmount = member.residualAmount || 0;
            aggregatedMembers[member.email] = newItem;
        }
    });
    return aggregatedMembers;
}

export const getBalance = (users, key) => {
    let pendingPayment = false;
    let balance = 0;
    if (!users) {
        return {balance, pendingPayment};
    }
    users.forEach((user) => {
        const val = parseFloat(user[key]) || 0;
        balance += val;
        if (val <= 0) {
            pendingPayment = true;
        }
    });
    return {
        balance, 
        pendingPayment
    };
}

const ExpenseDetails = (props) => {
    const expense = props.expense;
    const amtPaid = expense.amount - expense.commission;
    const realPaid = expense.isInvoice ? expense.paid : expense.amount ? amtPaid : 0;

    const totalAmount = parseInt(expense.Grand_total);
    const isComplete = totalAmount === realPaid;
    const isInComplete = totalAmount !== realPaid && realPaid > 0;
    const statusColor = isComplete ? '!bg-[#008000]' : isInComplete ? '!bg-[#FFA500]' : '!bg-[#FF474C]';

   return (
        <div className="c-expense-details w-full flex !flex-col">
            <p className="text-2xl border-b-2 border-gray-300 pb-6">Transaction Summary</p>
            <div className="my-2 flex flex-row w-full justify-between items-center">
                <p>Transaction Summary</p>
                <button className={`flex flex-row px-4 w-fit ${statusColor} h-10 justify-center items-center text-white`}>
                    {expense.Grand_total === realPaid ? 'Completed' : realPaid > 0 ? 'Incomplete' : 'Pending'}
                </button>
            </div>
            <div className="right mt-1 p-4 w-full !rounded-sm !bg-[#0898A008]">
                <ExpenseDetailsCard 
                    title={expense.title || expense.name}
                    category="personal"
                    description={expense.description}
                    paid={realPaid}
                    target={expense.Grand_total}
                    openEdit={() => {}}
                    id={expense.id}
                    handleCloseDetail={props.handleCloseDetail}
                    uniqueCode={expense.uique_code}
                    expense={expense}
                />
            </div>
            {
                props.expense.data && (
                    <div className="w-full">
                        <h3 style={{margin: '10px 0', padding: 0}}>Products [{props.expense.data.length}]</h3>
                        <div className="users w-full overflow-auto max-h-[100px]">
                            {
                                props.expense.data.map((product, index) => {
                                    return (
                                        <ProductCard
                                            key={index}
                                            name={product.name}
                                            qty={product.qty}
                                            unitPrice={product.transaction_amount}                                            
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
            <div className="left w-full">
                <h3 style={{margin: '10px 0', padding: 0}}>Customer</h3>
                <div className="users w-full overflow-auto max-h-[300px]">
                    <TransactionUserCard
                        username={expense.customerName}
                        email={expense.email}
                        hasPaid={expense.status === 'Successful' && (
                            (((amtPaid) || 0) >= expense.Grand_total)
                        )}
                        payable={expense.Grand_total}
                        amtPaid={(amtPaid)  || 0}
                        expense={expense}
                        user={{
                            paymentReference: expense.paymentReference,
                            totalAmount: expense.isInvoice ? expense.paid : amtPaid || 0
                        }}
                        type="Transaction"
                        paid={amtPaid || 0}
                        id={expense.id}
                        isMine
                    />
                </div>
            </div>
        </div>
    );
};

export default ExpenseDetails;

import "./details.scss";
import DetailsCard from "./DetailCard";
import { TableNormalStatus } from "../ExpenseList";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import Modal from "../Modal";
import Input, { Select } from "../Input";
import Button from "../Button";
import toast from "react-hot-toast";
import { getAllUsersWithKYC, getUserKYC, UpdateKYCStatus } from "../../actions/adminActions";

const AdminUserDetails = (props: {
    user: (Record<string, any> | null),
    setUser: (user: any) => void, withKYC?: boolean
}) => {
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const user = props.user;

    return (
        <div className="c-user-details w-full flex !flex-col">
            <div className="flex flex-row flex-wrap w-full justify-between items-center border-b-2 border-gray-300 pb-6">
                <p className="text-2xl">User Details</p>
                <img className="w-20 h-20" src={user?.image} alt="" />
            </div>
            <div className="my-2 flex flex-row w-full justify-between items-center">
                <p className="capitalize">{user?.usertype}</p>
                {
                    props?.withKYC ?
                        (<span className="flex gap-1 items-center">
                            <h5>KYC Status:</h5>
                            {' '}
                            <TableNormalStatus status={user?.kyc?.status} />
                        </span>
                        )
                        : (
                            <span className="flex capitalize flex-row px-4 w-fit bg-[#0898A01A] h-10 justify-center items-center text-az-teal">
                                {user?.isVerified ? 'Verified' : 'UnVerified'}
                            </span>
                        )
                }
            </div>
            <div className="right mt-1 p-4 w-full !rounded-sm !bg-[#0898A008]">
                <DetailsCard
                    user={user}
                    kyc={user?.kyc}
                    handleOpenConfirm={() => setOpenConfirmModal(true)}
                />
            </div>
            <UpdateKYCStatusModal userId={user?.id} open={openConfirmModal} setOpen={setOpenConfirmModal} kyc={user?.kyc} setUser={props.setUser} />
        </div>
    );
};

export default AdminUserDetails;

const UpdateKYCStatusModal = ({ userId, open, setOpen, kyc, setUser }: { userId: string | null, open: boolean, setOpen: Dispatch<SetStateAction<boolean>>, kyc: any, setUser: (user: any) => void }) => {
    const [updateData, setUpdateData] = useState({
        status: kyc?.status || "",
        description: kyc?.description || ""
    });

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault()
        if (updateData.status === "") {
            toast.error("Please select a valid status")
            return;
        }
        // update kyc status here
        UpdateKYCStatus(userId, updateData, (resp) => {
            handleClose()
            getAllUsersWithKYC(1)
            getUserKYC(userId, (resp) => {
                setUser(resp.data)
            })
        })
    }

    const handleChange = (name, value) => {
        setUpdateData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }
    return (
        <Modal open={open} onClose={handleClose} title="KYC Status" overrideStyle={{ height: "auto" }}>
            <form className="w-full" onSubmit={handleSubmit}>
                <Select
                    name="status"
                    label="Status"
                    data={
                        [
                            "pending",
                            'approved',
                            'decline'
                        ]
                    }
                    onChange={handleChange}
                    value={updateData.status}
                    required
                />
                <Input
                    type="text"
                    name="description"
                    label="description"
                    maxLength={500}
                    onChange={handleChange}
                    value={updateData.description}
                // required
                />
                <Button label="Update" type="flat" btnActionType="submit" />
            </form>
        </Modal>
    )
}
import axios from "axios";
import { updateUserType, update, updateLedger, setWithdrawalSource, setBVNDetails, login, User, setMandateMessage, updateMerchantSponsorKYC } from "../pages/Auth/authSlice";
import { toastWrapper, silentHTTPCaller } from "../utils/toastWrapper";
import getBaseURL from "../utils/getBaseURL";
import { appDispatch } from '../app/store';

const url = getBaseURL() as string;

export const switchUser = (data, dispatch, closeForm) => {
  const call = axios.put(`${url}updateUsertype`, data);
  toastWrapper(
    call,
    `Switching to ${data.usertype}...`,
    (resp) => {
      dispatch(updateUserType(resp.data[0].usertype));
      closeForm();
      return resp.data.message || 'Switch Successful!';
    },
    `Error switching to ${data.usertype}.`
  );
}

export const updateUserCall = (data, dispatch, closeForm) => {
  const call = axios.post(`${url}updateProfile`, data);
  toastWrapper(
    call,
    'Updating your profile...',
    (resp) => {
      dispatch(update(resp?.data?.data));
      closeForm();
      return resp?.data?.message || 'Profile updated Successfully!';
    },
    'Profile Update failed.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Profile Update failed.';
    }
  );
}

export const updateUserImage = (data, dispatch, userData, closeForm) => {
  const call = axios.post(`${url}image`, data);
  toastWrapper(
    call,
    'Updating your profile Image...',
    (resp) => {
      dispatch(update({ ...userData, image: resp.data.image }));
      closeForm();
      return resp?.data?.message || 'Profile Image updated Successfully!';
    },
    'Profile Image Update failed.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Profile Image Update failed.';
    }
  );
}

export const getLedger = () => {
  const call = axios.get(`${url}get-ledger`);
  silentHTTPCaller(
    call,
    'Getting Ledger...',
    (resp) => {
      appDispatch(updateLedger(resp?.data));
      return resp?.data?.message || 'Ledger Loaded!';
    },
    'Ledger fetch failed.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Ledger fetch failed.';
    }
  );
};

export const handleSetWithdrawalSource = (source: 'refundMe' | 'kontribute' | 'transaction' | '') => {
  appDispatch(setWithdrawalSource(source));
}

export const getBVNDetails = (accessToken: string, handleDone, errorCB) => {
  const instance = axios.create({
    baseURL: 'https://www.sandbox.paythru.ng/BvnIgree/api/v1/bvn/get-bvn-details',
    headers: { 'Authorization': `Bearer ${accessToken}` }
  });
  const call = instance.get('');
  toastWrapper(
    call,
    'Getting your details...',
    (resp) => {
      handleDone(resp.data);
      appDispatch(setBVNDetails({ ...resp.data.data.validationDataRes[0], accessToken }))
      return 'Details loaded.';
    },
    'Error fetching details.',
    (err) => {
      errorCB();
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error fetching your details.';
    }
  );
}

export const completVerification = (bvnDetails: Record<string, any>, handleDone, errorCB?: () => void) => {
  const call = axios.put(`${url}get-verifiedd`, bvnDetails);
  toastWrapper(
    call,
    'Updating your details...',
    (resp) => {
      handleDone();
      return resp?.data?.message || 'Details updated!';
    },
    'Error updating your details.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message || err?.response?.data?.error;
      errorCB && errorCB();
      return msg || 'Error updating your details.';
    }
  );
}

export const submitInquiry = (data, handleDone, setLoading, typ = "Inquiry") => {
  const call = axios.post(`${url}makeInquiry`, data);
  toastWrapper(
    call,
    `Sending your ${typ}...`,
    (resp) => {
      handleDone();
      return resp?.data?.message || `${typ} saved successfully!`;
    },
    `${typ} saved successfully`,
    (err) => {
      setLoading(false);
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || `${typ} not failed.`;
    }
  );
}

export const getBVNLink = (handleDone) => {
  const call = axios.get(`${url}auth`);
  toastWrapper(
    call,
    'Initiating your verification process...',
    (resp) => {
      handleDone(resp);
      return resp?.data?.message || 'Redirecting your to BVN portal';
    },
    '',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'BVN verification process failed.';
    }
  );
}

export const joinReferrerCall = (handleDone) => {
  const call = axios.get(`${url}generate-link`);
  toastWrapper(
    call,
    'Initiating your request...',
    (resp) => {
      handleDone(resp);
      return resp?.data?.message || 'You have successfully joined the referral service';
    },
    '',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error joining referral service';
    }
  );
}

export const getUserReferrals = (handleDone) => {
  const call = axios.get(`${url}get-refPoint-per-user`);
  silentHTTPCaller(
    call,
    'Initiating your request...',
    (resp) => {
      handleDone(resp);
      return resp?.data?.message || 'Referral details loaded successfully';
    },
    '',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error loading referral details';
    }
  );
}

export const getUserReferrees = (page, handleDone) => {
  const call = axios.get(`${url}get-referred-count?page=${page}`);
  silentHTTPCaller(
    call,
    'Getting your referrees...',
    (resp) => {
      handleDone(resp);
      return resp?.data?.message || 'Referrees loaded successfully';
    },
    '',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error loading referrees';
    }
  );
}

export const getWithdrawalCharges = (handleDone) => {
  const call = axios.get(`${url}get-lastUpdated-charges`);
  silentHTTPCaller(
    call,
    'Getting withdrawal charges...',
    (resp) => {
      handleDone(resp.data);
      return resp?.data?.message || 'Details updated!';
    },
    'Error getting withdrawal charges.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message || err?.response?.data?.error;
      return msg || 'Error getting withdrawal charges.';
    }
  );
}

export const geProfileSilent = (handleDone) => {
  const call = axios.get(`${url}getProfile`);
  silentHTTPCaller(
    call,
    '',
    (resp) => {
      appDispatch(login(resp.data[0] as unknown as User));
      handleDone(resp.data);
      return '';
    },
    'Error getting profile.',
    (err) => {
      // @ts-ignore
      return msg || 'Error getting profile.';
    }
  );
}

export const resolveFeedback = (id: string, handleDone) => {
  const call = axios.put(`${url}update-feedback/${id}`, { status: 'resolved' });
  toastWrapper(
    call,
    'Marking feedback as resolved...',
    (resp) => {
      handleDone(resp);
      return resp?.data?.message || 'Feedback resolved successfully';
    },
    '',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error updating feedback';
    }
  );
}

export const handleMandateMessage = (message: string) => {
  return appDispatch(setMandateMessage(message));
}

export const getSingleMerchant = (merchantId, handleDone) => {
  const call = axios.get(`${url}getMerchantById/${merchantId}`);
  silentHTTPCaller(
    call,
    '',
    (resp) => {
      handleDone(resp.data);
      return resp?.data?.message || 'Merchant gotten successfully';
    },
    'Error getting merchant.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error getting merchant.';
    }
  );
}

export const getMerchantKYCData = (handleDone?) => {
  const call = axios.get(`${url}merchant/data`);
  silentHTTPCaller(
    call,
    '',
    (resp) => {
      const kycDetails = resp?.data?.data?.kyc_details;
      if (kycDetails) {
        const merchanKYCtData = {
          identity_card: kycDetails?.identity_card,
          utility_bill: kycDetails?.utility_bill,
          business_registration_certificate: kycDetails?.business_registration_certificate,
          proof_of_address: kycDetails?.proof_of_address,
          business_registration_number: kycDetails?.business_registration_number,
          status: kycDetails?.status,
          description: kycDetails?.description,
        }
        appDispatch(updateMerchantSponsorKYC(merchanKYCtData));
        handleDone && handleDone(resp.data);
      }
      return resp?.data?.message || 'KYC retrieved successfully';
    },
    'Error getting merchant KYC data.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error getting merchant KYC data.';
    }
  );
}

export const getSponsorKYCData = (handleDone?) => {
  const call = axios.get(`${url}sponsor/data`);
  silentHTTPCaller(
    call,
    '',
    (resp) => {
      const kycDetails = resp?.data?.data?.kyc_details;
      if (kycDetails) {
        const sponsorKYCtData = {
          identity_card: kycDetails?.identity_card,
          utility_bill: kycDetails?.utility_bill,
          business_registration_certificate: kycDetails?.business_registration_certificate,
          proof_of_address: kycDetails?.proof_of_address,
          business_registration_number: kycDetails?.business_registration_number,
          status: kycDetails?.status,
        }
        appDispatch(updateMerchantSponsorKYC(sponsorKYCtData));
        handleDone && handleDone(resp.data);
      }
      return resp?.data?.message || 'KYC retrieved successfully';
    },
    'Error getting sponsor KYC data.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error getting sponsor KYC data.';
    }
  );
}
import { useState } from 'react'
import VoucherStatusTag from './VoucherStatusTag'
import dummyVoucherImg from "../voucher-assets/images/dummy-voucher.jpg"
import { formatCurrency } from '../../../utils/common'

const initialDetailsData = [
  {
    title: "Voucher amount",
    data: formatCurrency({ num: 50000000 })
  },
  {
    title: "amount per code",
    data: formatCurrency({ num: 5000 })
  },
  {
    title: "PURPOSE",
    data: "Special Event"
  },
  {
    title: "LIMIT",
    data: formatCurrency({ num: 500000 })
  },
  {
    title: "type",
    data: "Multiple Times"
  },
  {
    title: "CREATED DATE",
    data: "24th Sept, 2024"
  },
  {
    title: "EXPIRY DATE",
    data: "24th Sept, 2024"
  },
  {
    title: "VOUCHER CODE",
    data: "19AGVCBQOA"
  },
  {
    title: "SPONSOR ID",
    data: "#S-920"
  },
  {
    title: "location",
    data: "Oyo State"
  },
  {
    title: "Code generation method",
    data: "QR_Code"
  },
  // {
  //   title: "Status",
  //   data: <VoucherStatusTag status="active" />
  // },
]

export default function VoucherDetails() {
  const [details, setDetails] = useState(initialDetailsData)

  return (
    <div className="w-full flex flex-col gap-[20px]">
      <div className='w-[100%] rounded-md bg-[#FFFFFF]'>
        <div className="w-full flex justify-between p-[20px] bg-[#0898A00D] rounded-t-[inherit] rounded-b-none">
          {/* <h4 className='text-[14px] font-[500]'>Voucher Details</h4> */}
          <div className="w-fit flex items-center gap-2">
            <img src={dummyVoucherImg} alt="voucher" className="w-[40px] h-[40px] rounded-full object-cover object-center" />
            <div className="flex flex-col gap-1 w-[full] text-left">
              <h4 className="text-[14px] font-[500] leading-[100%]">Ministry of Health Food stamp</h4>
              <VoucherStatusTag status={"active"} type="text" />
            </div>
          </div>
        </div>
        <div className="w-full p-[20px] flex flex-wrap gap-x-5 gap-y-10">
          {
            details.map((detail, index) => {
              return (
                <div key={index} className='flex flex-col justify-center items-start gap-2 min-w-[45%] sm:min-w-[200px] w-fit'>
                  <h5 className='text-[12px] font-[400] uppercase'>{detail.title}</h5>
                  <div className='text-[13px] font-[500]'>{detail.data}</div>
                </div>
              )
            })
          }
        </div>
      </div>


      <div className='w-[100%] rounded-md bg-[#FFFFFF]'>
        <div className="w-full flex justify-between p-[20px] bg-[#0898A00D] rounded-t-[inherit] rounded-b-none">
          <h4 className='text-[14px] font-[500]'>Merchant Details</h4>
        </div>

        <div className="w-full p-[20px]">
          {/* dektop */}
          <table className="hidden md:block w-fit min-w-[600px] rounded-md ">
            <thead className='!rounded-t-[inherit] bg-[#0898A00D] text-left'>
              <tr>
                <th className='text-[12px] text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>STORE NAME</th>
                <th className='text-[12px] text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>MERCHANT ID</th>
                <th className='text-[12px] text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>STORE DESCRIPTION</th>
              </tr>
            </thead>
            <tbody>
              {
                Array.from({ length: 5 }).map((_, index) => {
                  return (
                    <tr key={index} className='border-b border-[#2222220D]'>
                      <td className='text-[12px] text-left text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>Olaoluwa Stores</td>
                      <td className='text-[12px] text-left text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>#M-920</td>
                      <td className='text-[12px] text-left font-[400] uppercase p-[15px] pr-[60px]'>We feed the nation.</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          {/* mobile */}
          <div className="flex md:hidden w-full flex-wrap gap-x-5 gap-y-10">
            {Array.from({ length: 5 }).map((_, index) => {
              return (
                <div key={index} className="flex flex-wrap gap-5 border-b border-[#2222220F] py-2">
                  <div className='flex flex-col justify-center items-start gap-0 min-w-[45%] sm:min-w-[200px] w-fit'>
                    <h5 className='text-[12px] font-[400] uppercase'>MERCHANT ID</h5>
                    <div className='text-[13px] font-[500]'>#M-920</div>
                  </div>
                  <div className='flex flex-col justify-center items-start gap-0 min-w-[45%] sm:min-w-[200px] w-fit'>
                    <h5 className='text-[12px] font-[400] uppercase'>STORE NAME</h5>
                    <div className='text-[13px] font-[500]'>Olaoluwa Stores</div>
                  </div>
                  <div className='flex flex-col justify-center items-start gap-0 min-w-[45%] sm:min-w-[200px] w-fit'>
                    <h5 className='text-[12px] font-[400] uppercase'>STORE DESCRIPTION</h5>
                    <div className='text-[13px] font-[500]'>We feed the nation</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import AzatsListWrapper from "../Azats/AzatsListWrapper";
import './withdrawalhistory.scss';
import NotFound from "../NotFound";
import useVerificationDone from "../../hooks/useVerificationDone";
import Table from "../Table";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatCurrency } from "../../utils/common";
import { useAppSelector } from "../../app/hooks";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import { TableAction } from "../ExpenseList";
dayjs.extend(relativeTime);

interface Props {
  getFunction: (page?: number) => void;
  type: 'RefundMe' | 'Kontribute' | 'Business' | 'Ajo';
  setDetails: Dispatch<SetStateAction<Record<string, any> | null>>
}


const AdminWithdrawalHistory = ({ getFunction, type, setDetails }: Props) => {
  const history = useAppSelector(state => state.admin.AdminWithdrawalHistory)[type.toLowerCase()] || [];

  useEffect(() => {
    getFunction();
  }, []);

  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    getFunction(page);
  }

  const header = [
    {
      field: 'name', headerName: 'Bank Details', cellRenderer: ({ data }) => (
        <div className='title flex flex-col justify-center items-start'>
          <p className="text-base">{data.account_number}</p>
          <p className="text-xs text-az-teal">{data.bank}</p>
        </div>
      )
    },
    { field: 'amount', headerName: 'Amount', cellRenderer: ({ data }: any) => `${formatCurrency({ num: data.amount || 0 })}` },
    { field: 'charges', headerName: 'Charges', cellRenderer: ({ data }: any) => `${formatCurrency({ num: data.charges || 0 })}` },
    { field: 'created_at', headerName: 'Created At' },
    { field: 'recordDateTime', headerName: 'Recieved At', 
      cellRenderer: ({ data }: any) => (data.recordDateTime ? data.recordDateTime : "Not yet recieved")
    },
    {
      field: 'Status',
      headerName: 'Status',
      cellRenderer: ({ data }: any) => (
        <span
          style={{
            background: data.status && data.status.toLowerCase() === 'success' ? 'green' : data.status && data.status.toLowerCase() === 'failure' ? 'red' : 'orange',
            color: '#fff',
            width: 'max-content',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            textTransform: 'capitalize',
            overflow: 'hidden',
          }}
        >
          {data.status ? data.status : 'pending'}
        </span>
      )
    },
    {
      field: "id",
      headerName: 'Action',
      cellRenderer: ({ data }: any) => (
        <TableAction handleMore={() => setDetails({ entity: type, id: data.id })} />
      )
    }
  ];

  const mobileConfig = {
    logo: {
      value: '',
      renderer: (data: any) => (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="22.5" cy="22.5" r="22.5" fill="#FFE6E4" />
          <path d="M22.7256 15.25V30.25" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.701 21.2988L22.725 15.2488L28.75 21.2988" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      )
    },
    topLeft: {
      renderer: (data: any) => data.account_number
    },
    topRight: {
      renderer: (data: any) => `${formatCurrency({ num: data.amount || 0 })}`
    },
    bottomLeft: {
      renderer: (data: any) => <span className="text-xs opacity-55">{`${data.bank} - ${dayjs(data.created_at).fromNow()}`}</span>
    },
    bottomRight: {
      field: '',
      renderer: (data: any) => (
        <span
          style={{
            background: data.status && data.status.toLowerCase() === 'success' ? 'green' : data.status && data.status.toLowerCase() === 'failure' ? 'red' : 'orange',
            color: '#fff',
            width: 'max-content',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            textTransform: 'capitalize',
            overflow: 'hidden',
          }}
        >
          {data.status ? data.status.toLowerCase() === 'request processing' ? 'pending' : data.status : 'pending'}
        </span>
      )
    },
    more: {
      clickHandler: (data: any) => setDetails({ entity: type, id: data.id })
    }
  }


  return (
    <AzatsListWrapper renderNavItems={() => <div />}>
      <div className="withdrawal-container !p-0">
        {
          !history ? (
            <div className="w-full h-full flex flex-row justify-center items-center">
              <NotFound
                text1="No withdrawals found!"
                text2=''
              // btnLabel='Create RefundMe'
              // onClick={() => props.handleCreate()}
              />
            </div>
          ) : (
            <div className="">
              <div className="w-full h-[65vh] text-left">
                <Table
                  data={history?.data || []}
                  header={header}
                  fitStrategy="fitGridWidth"
                  mobileConfig={mobileConfig}
                />
              </div>
              {
                history?.data?.length !== 0 && (
                  <TablePaginationFooter
                    total={history?.total}
                    from={history?.from}
                    to={history?.to}
                    nextUrl={history?.next_page_url}
                    prevUrl={history?.prev_page_url}
                    handlePagination={handlePagination}
                  />
                )
              }
            </div>
          )
        }

      </div>
    </AzatsListWrapper>
  );
};

export default AdminWithdrawalHistory;

import { useState } from "react";
import AllVouchersComponent from "../Voucher/voucher-components/AllVouchersComponent";
import VoucherDashboardComponentWrapper from "../Voucher/voucher-layouts/VoucherDashboardComponentWrapper";
import FundAccount from "./Components/FundAccount";
import KYC from "./Components/KYC";

const SponsorOverview = () => {
  const overviewData = [
    {
      icon: '',
      title: 'Account Balance',
      value: '0'
    },
    {
      icon: '',
      title: 'Total Vouchers Created',
      value: '0'
    },
    {
      icon: '',
      title: 'Total Active Vouchers',
      value: '0'
    },
    {
      icon: '',
      title: 'Total Expired Vouchers',
      value: '0'
    }
  ];
  const [showDetails, hideDetails] = useState(false);
  const [showKYC, hideKYC] = useState(false);
  return (
    <VoucherDashboardComponentWrapper 
      title="Welcome to AzatMe Voucher 🥳"
      subTitle="Here is an overview of your account"
    >
      <div className="w-[95%] ">
        <div className="relative w-[100%] flex flex-col p-7 justify-between items-start my-5 rounded-[10px] bg-[#00373A] text-white">
          <p className="font-medium text-2xl">Complete your account setup</p>
          <p className="text-sm mb-7 mt-3">Kindly proceed to provide your business information</p>
          <button className="flex flex-row justify-between items-center bg-[#FFFFFF26] text-sm font-medium px-6 py-2 rounded-md border border-[#FFFFFF33]" onClick={() => hideKYC(true)}>
            Complete account setup
            <svg className="ml-5" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.25 12.2257L5.25 12.2257" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.2012 6.20131L20.2512 12.2253L14.2012 18.2503" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <svg className="absolute right-0 bottom-0 h-[90%]" width="202" height="186" viewBox="0 0 202 186" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_279_2552" fill="white">
            <path d="M255.322 18.6549C241.364 9.48101 225.554 3.50034 209.019 1.13956C192.484 -1.22121 175.632 0.0960897 159.664 4.99749C143.697 9.8989 129.008 18.2636 116.645 29.495C104.282 40.7263 94.5505 54.5475 88.1438 69.9726C81.737 85.3977 78.8132 102.047 79.5809 118.732C80.3487 135.417 84.7891 151.727 92.5853 166.498C100.382 181.27 111.342 194.139 124.684 204.188C138.025 214.236 153.421 221.217 169.771 224.631L174.094 203.926C160.791 201.147 148.265 195.468 137.409 187.292C126.553 179.115 117.635 168.644 111.292 156.625C104.948 144.606 101.335 131.335 100.711 117.759C100.086 104.183 102.465 90.6369 107.678 78.0861C112.891 65.5353 120.809 54.2895 130.868 45.151C140.928 36.0125 152.879 29.2064 165.871 25.2184C178.863 21.2303 192.575 20.1584 206.029 22.0793C219.483 24.0002 232.348 28.8664 243.705 36.3309L255.322 18.6549Z"/>
            </mask>
            <path d="M255.322 18.6549C241.364 9.48101 225.554 3.50034 209.019 1.13956C192.484 -1.22121 175.632 0.0960897 159.664 4.99749C143.697 9.8989 129.008 18.2636 116.645 29.495C104.282 40.7263 94.5505 54.5475 88.1438 69.9726C81.737 85.3977 78.8132 102.047 79.5809 118.732C80.3487 135.417 84.7891 151.727 92.5853 166.498C100.382 181.27 111.342 194.139 124.684 204.188C138.025 214.236 153.421 221.217 169.771 224.631L174.094 203.926C160.791 201.147 148.265 195.468 137.409 187.292C126.553 179.115 117.635 168.644 111.292 156.625C104.948 144.606 101.335 131.335 100.711 117.759C100.086 104.183 102.465 90.6369 107.678 78.0861C112.891 65.5353 120.809 54.2895 130.868 45.151C140.928 36.0125 152.879 29.2064 165.871 25.2184C178.863 21.2303 192.575 20.1584 206.029 22.0793C219.483 24.0002 232.348 28.8664 243.705 36.3309L255.322 18.6549Z" stroke="#0898A0" stroke-width="24" mask="url(#path-1-inside-1_279_2552)"/>
            <mask id="path-2-inside-2_279_2552" fill="white">
            <path d="M225.938 181.423C228.671 161.843 226.243 141.889 218.894 123.536C211.545 105.182 199.531 89.0665 184.039 76.7836C168.548 64.5007 150.117 56.4767 130.572 53.5059C111.026 50.5351 91.0438 52.7205 72.6025 59.8457C54.1612 66.971 37.9006 78.789 25.4304 94.13C12.9601 109.471 4.7127 127.803 1.50455 147.311C-1.7036 166.818 0.238768 186.826 7.13938 205.353C14.04 223.879 25.6595 240.282 40.8478 252.938L54.3883 236.688C42.0303 226.39 32.5759 213.044 26.9611 197.969C21.3464 182.895 19.7659 166.616 22.3763 150.743C24.9866 134.87 31.6972 119.954 41.8438 107.472C51.9903 94.9897 65.221 85.3738 80.2259 79.5763C95.2309 73.7787 111.49 72.0006 127.393 74.4178C143.297 76.8351 158.293 83.3639 170.898 93.358C183.502 103.352 193.278 116.465 199.258 131.398C205.237 146.332 207.213 162.568 204.989 178.499L225.938 181.423Z"/>
            </mask>
            <path d="M225.938 181.423C228.671 161.843 226.243 141.889 218.894 123.536C211.545 105.182 199.531 89.0665 184.039 76.7836C168.548 64.5007 150.117 56.4767 130.572 53.5059C111.026 50.5351 91.0438 52.7205 72.6025 59.8457C54.1612 66.971 37.9006 78.789 25.4304 94.13C12.9601 109.471 4.7127 127.803 1.50455 147.311C-1.7036 166.818 0.238768 186.826 7.13938 205.353C14.04 223.879 25.6595 240.282 40.8478 252.938L54.3883 236.688C42.0303 226.39 32.5759 213.044 26.9611 197.969C21.3464 182.895 19.7659 166.616 22.3763 150.743C24.9866 134.87 31.6972 119.954 41.8438 107.472C51.9903 94.9897 65.221 85.3738 80.2259 79.5763C95.2309 73.7787 111.49 72.0006 127.393 74.4178C143.297 76.8351 158.293 83.3639 170.898 93.358C183.502 103.352 193.278 116.465 199.258 131.398C205.237 146.332 207.213 162.568 204.989 178.499L225.938 181.423Z" stroke="#0898A0" stroke-width="24" mask="url(#path-2-inside-2_279_2552)"/>
          </svg>
        </div>
        <div className="w-[100%] flex flex-col justify-between items-center my-5">
          <div className="flex flex-row w-full justify-between items-center pb-5">
            <p className="w-max">Voucher Overview</p>
            <button
              className="flex flex-row justify-between items-center bg-az-teal text-white rounded-[100px] w-fit px-[30px] py-[10px]"
              onClick={() => hideDetails(true)}
            >
              <svg className="mr-5" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.8 7.69932H1.2C0.8136 7.69932 0.5 7.38572 0.5 6.99932C0.5 6.61292 0.8136 6.29932 1.2 6.29932H13.8C14.1864 6.29932 14.5 6.61292 14.5 6.99932C14.5 7.38572 14.1864 7.69932 13.8 7.69932Z" fill="white"/>
                <path d="M7.50078 14C7.11438 14 6.80078 13.6864 6.80078 13.3V0.7C6.80078 0.3136 7.11438 0 7.50078 0C7.88718 0 8.20078 0.3136 8.20078 0.7V13.3C8.20078 13.6864 7.88718 14 7.50078 14Z" fill="white"/>
              </svg>
              Fund Account
            </button>
          </div>
          <div className="bg-white flex flex-row justify-between items-center w-full p-5 rounded-[10px]">
            {
              overviewData.map((itm) => (
                <div className="flex flex-col justify-between items-start h-[133px] w-[260px] border border-[#2222221A] rounded-[10px] px-4 py-7">
                  <div>
                    <img src={itm.icon} alt="" />
                    <p className="text-sm font-normal">{itm.title}</p>
                  </div>
                  <p className="font-medium text-xl">{itm.value}</p>
                </div>
              ))
            }
          </div>
          <div className="w-full rounded-[10px] bg-white mt-5">
            <div className="flex flex-row w-full justify-between items-center p-5">
              <p className="w-max">Recent Vouchers Published</p>
              <button className="flex flex-row justify-between items-center text-az-teal underline rounded-[100px] w-fit px-[30px] py-[10px]">
                View all
              </button>
            </div>
            <AllVouchersComponent />
          </div>
        </div>
        <FundAccount showDetails={showDetails} handleClose={hideDetails} />
        <KYC showKYC={showKYC} handleClose={hideKYC} />
      </div>
    </VoucherDashboardComponentWrapper>
  )
};

export default SponsorOverview;

import { useEffect, useState } from "react";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import TabComponent from "../../../components/TabComponent";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {updateUserImage } from "../../../actions/authActions";
import toast from "react-hot-toast";
import EditIcon from '../../../assets/edit.png';
import SaveIcon from '../../../assets/saveWhite.png';
import RegisterNewAdmin from "./RegiterNewAdmin";

const AdminProfile = () => {
    return (
        <DashboardComponentWrapper
            subTitle="Profile"
            pageDetails="Here's your admin profile."
        >
            <TabComponent
                components={{
                    "AdminProfile": AdminProfileDetails,
                    "Register new Admin": RegisterNewAdmin
                }}
            />
        </DashboardComponentWrapper>
    )
}

export default AdminProfile;


const AdminProfileDetails = () => {
    const dispatch = useAppDispatch();
    const profile = useAppSelector((state) => state.auth.user);
    const [data, setData] = useState({
        email: '',
        name: '',
        phone: '',
        image: '',
        usertype: 'admin',
        imageData: '',
    });
    useEffect(() => {
        if (profile) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setData({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                email: profile.email,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                phone: profile.phone,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                image: profile.image,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                name: profile.name,
            });
        }
    }, [profile]);

    const handleChange = (name: string, value: string) => {
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleImageChange = ({ target }) => {
        if (target.files[0].size > 500000) {
            toast.error('Logo must be less than 500kb');
            return;
        }
        handleChange('image', URL.createObjectURL(target.files[0]));
        handleChange('imageData', target.files[0]);
    }

    const handleSaveImg = () => {
        const profileData = new FormData();
        profileData.append('image', data.imageData);
        updateUserImage(profileData, dispatch, {
            email: data.email,
            phone: data.phone,
            name: data.name,
        }, () => {
            setData(prevState => ({
                ...prevState,
                imageData: '',
            }));
        })
    }

    return (
        <div className="profile-wrapper relative">
            <div className="profile-container mt-8">
                <div className="profile-card">
                    <div className="details-left">
                        <div className="img-wrapper">
                            {
                                data.image ? <img className="profile-img" src={data.image} alt="" /> : (
                                    <span className="img-placeholder">
                                        {data.name && data.name[0]}
                                    </span>
                                )
                            }
                            {
                                data.imageData ? (
                                    <div className="file-upload" onClick={handleSaveImg}>
                                        <img src={SaveIcon} alt="" />
                                    </div>
                                ) : (
                                    <label htmlFor="image" className="file-upload">
                                        <img src={EditIcon} alt="" />
                                        <input type="file" name="image" id="image" onChange={handleImageChange} />
                                    </label>
                                )
                            }
                        </div>
                        <div>
                            <b className="detail-username">{data.name}</b>
                            {/* <span className="detail-email">{data.phone}</span> */}
                            <span className="detail-email">{data.email}</span>
                        </div>
                    </div>
                    <div className="details-right">
                        <div className="detail">
                            <span className="detail-title">Name</span>
                            <span className="detail-value">{data.name}</span>
                        </div>
                        <div className="detail">
                            <span className="detail-title">Email</span>
                            <span className="detail-value">{data.email}</span>
                        </div>
                        <div className="detail">
                            <span className="detail-title">Phone Number</span>
                            <span className="detail-value">{data.phone}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
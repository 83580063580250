import VoucherDashboardComponentWrapper from '../../voucher-layouts/VoucherDashboardComponentWrapper'
import AllVouchersComponent from '../../voucher-components/AllVouchersComponent'
import FilterVouchersBtn from '../../voucher-components/FilterVouchersBtn'
import SearchVoucherInput from '../../voucher-components/SearchVoucherInput'
import VoucherTopComponents from '../../voucher-layouts/VoucherTopComponents'

export default function IndividualAllVouchersPage() {
  return (
    <VoucherDashboardComponentWrapper
      title="AzatMe Vouchers"
      subTitle="Here is an overview of all your vouchers"
    >
      <>
        <VoucherTopComponents
          leftComponents={[<SearchVoucherInput />]}
          rightComponents={
            [
              <FilterVouchersBtn />,
              // <Button
              //   type='flat'
              //   label='Create Voucher'
              //   className='!rounded-full !m-0 !w-auto !p-[25px]'
              //   leftIcon={<span>+</span>}
              // />
            ]}
          filled={true}
        />
        <div className="w-[95%] flex flex-col gap-[20px] mt-">
          <AllVouchersComponent />
        </div>
      </>
    </VoucherDashboardComponentWrapper>
  )
}

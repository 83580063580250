import { useCallback, useEffect, useState } from "react";
import CreateExpense from "../../components/CreateExpense";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import AzatsListTab from "./AzatsListTab";
import { useAppSelector } from '../../app/hooks';
import './expenses.scss';
import { useLocation } from "react-router-dom";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import useVerificationDone from "../../hooks/useVerificationDone";
import { setShowVerificationPending } from "../Auth/authSlice";
import { useAppDispatch } from "../../app/hooks";
import WithdrawalHistory from "../../components/WithdrawalHistory";
import { getWithdrawalHistoryRefundMe } from "../../actions/dashboardActions";
import CreateIcon from '../../assets/new/createIcon.png';
import TabComponent from "../../components/TabComponent";
import SidePane from "../../components/SidePane";
import ExpenseDetails from "../../components/ExpenseDetails";


const expenseNavItems = [
  'My RefundMe','Create', 'Withdrawal History'
];


const Expenses = () => {
  const [tab] = useState(expenseNavItems[0]);
  const [openCreate, setOpenCreate] = useState(false);
  const allRefundMes = useAppSelector((state) => state.dashboard.expensesMetaData);
  const location = useLocation() as any;
  const dispatch = useAppDispatch();
  const {isVerificationDone} = useVerificationDone();
  const [expense, setExpense] = useState<Record<string, any> | null>(null);

  const expenseNavItemsObj = [
    {
      label: 'New RefundMe',
      action: () => handleCreateRefundMe(),
      bgColor: '#0898A0',
      img: CreateIcon,
    }
  ];

  const handleCreateRefundMe = () => {
    if (!isVerificationDone) {
      dispatch(setShowVerificationPending(true));
      return;
    }
    setOpenCreate(true);
  }

  useEffect(() => {
    if (location.state?.expense) {
      setExpense(location.state?.expense);
    }
  }, [location?.state]);

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        navItems={[]}
        navItemsObj={expenseNavItemsObj}
        tab={tab}
        setTab={handleCreateRefundMe}
      />
    )
  }

  const getHistory = useCallback(() => {
    if (isVerificationDone) {
      getWithdrawalHistoryRefundMe();
    }
  }, [isVerificationDone]);

  const renderWithdrawHistory = useCallback(() => <WithdrawalHistory type="RefundMe" getFunction={getHistory} />, [getHistory]);
  
  return (
    <DashboardComponentWrapper
      title="RefundMe"
      renderNavItems={renderNavItems}
      pageDetails="Manage all your expenses payback using refundme"
    >
      <>
        <TabComponent
          components={{
            'RefundMe History': () => <AzatsListTab setExpense={setExpense} allRefundMes={allRefundMes} handleCreate={handleCreateRefundMe}  />,
            'Withdrawal History': renderWithdrawHistory
          }}
        />
        <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
          <CreateExpense handleDone={() => setOpenCreate(false)} />
        </SidePane>
        <SidePane
          onClose={() => setExpense(null)}
          open={!!expense}
          title={""}
        >
          <ExpenseDetails expense={expense} handleCloseDetail={() => setExpense(null)} isMine />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  );
};

export default Expenses;

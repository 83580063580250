import PageBuilder from "./ProductBuilder";
import Hero1 from "../../assets/new/productImgs/voucher.jpg";
import Icon from "../../assets/new/productImgs/voucher.svg";
import { Reason } from "../../types";
import RefProd2 from "../../assets/new/refProd2.png";
import RefProd1 from "../../assets/new/refProd1.png";
import RefProd3 from "../../assets/new/refProd3.png";
import Convenience from '../../assets/new/productImgs/investment-report.png';
import CardPaymeny from '../../assets/new/productImgs/card-payment.png';
import Security from '../../assets/new/productImgs/security.png';
import Ring from '../../assets/new/productImgs/ring-abstract-shape.png';
import Why1 from '../../assets/new/why1.png';
import Why2 from '../../assets/new/why1.png';
import { voucherForIndividual } from "../../data/faqData";

function VoucherIndividual() {
	const reasons: Array<Reason> = [
		{
			title: `Convenience`,
			details: `Say goodbye to physical vouchers and manual tracking. With our app, you can easily store, manage, and redeem your vouchers anytime, anywhere.`,
			img: RefProd2,
			bg: "#FFF9F0",
		},
		{
			title: `Exclusive Offers`,
			details: ` Unlock access to special deals and discounts at partnered merchants, giving you more value for your vouchers.`,
			img: RefProd1,
			bg: "#FFEFFE",
		},
		{
			title: `Credibility, Transparency,
        and Accountability`,
			details: `Keep track of every redemption with detailed records at your fingertips, ensuring you always know where your vouchers are used.`,
			img: RefProd3,
			bg: "#F5F8FF",
		},
	];

	const howItWorks = [
		{
			title: 'Sign up',
			details: `Create your account by providing basic details and verifying your identity. It’s quick, secure, and ensures your access to all features`
		},
		{
			title: 'Explore Voucher Options',
			details: `Browse available vouchers, check out special deals, and select the ones that match your needs and preferences.`
		},
		{
			title: 'Redeem with Ease',
			details: `Visit any of our partnered merchants and redeem your vouchers seamlessly, ensuring you always get the best value.`
		},
		{
			title: 'Track Your Redemptions',
			details: `Monitor your voucher usage and balance through your personal dashboard, keeping everything organized and transparent.`
		},
	];

	const benefits = [
		{
			title: 'Convenience',
			details: `No more fiddling with cash or complicated 
				calculations.`,
			img: Convenience
		},
		{
			title: 'Payment Flexibility',
			details: `AzatMe voucher supports various payment methods
				(cards, mobile wallets, bank transfers etc.), giving
				users flexibility in how they refund.`,
			img: CardPaymeny
		},
		{
			title: 'Time Savings',
			details: `It saves a lot of time and hassle compared to
				traditional methods of splitting bills.`,
			img: Why1
		},
		{
			title: 'Security',
			details: `Integrates secure payment gateways to protect
				users' financial information during refund 
				transactions.`,
			img: Security
		},
		{
			title: 'Record Keeping',
			details: `The app records refund requests, and amounts
				paid/owed for better expense tracking across
				the group.`,
			img: Why2
		},
		{
			title: 'Transparency',
			details: `Everyone sees the total bill and their share 
				clearly within the app.`,
			img: Ring
		}
	]

	return (
		// @ts-ignore
		<PageBuilder
			title='Your Vouchers, Your Control!'
			name={"Voucher"}
			titleIcon={Icon}
			tagline={`Take control of your vouchers and enjoy easy redemption at partnered merchants. Register to track, redeem, and make the most of exclusive deals created just for you!`}
			image={Hero1}
			imgText={[]}
			summary={`Make your financial dreams a
    reality with AzatMe Voucher!`}
			reasons={reasons}
			howItWorks={howItWorks}
			benefits={benefits}
			faq={voucherForIndividual}
		/>
	);
}

export default VoucherIndividual;


export default function VoucherStatusTag({ status, type = "filled" }: { status: "active" | "pending" | "revoked", type?: "filled" | "text" }) {
  return (
    <span className={`w-fit flex gap-1 text-[12px] justify-center items-center px-[10px] py-[5px] rounded-md capitalize
      ${status === "active" ? "bg-[#27AE6012] text-[#27AE60]" :
        status === "pending" ? "bg-yellow-200 text-yellow-500" :
          status === "revoked" ? "bg-[#ff000033] text-[red]"
            : null
      }
      ${type === "text" && "!bg-transparent !p-0"}
    `}>
      <span
        className={`w-[7px] h-[7px] rounded-full text-[13px] font-[500]
      ${status === "active" ? "bg-[#27AE60]" :
            status === "pending" ? "bg-yellow-500" :
              status === "revoked" ? "bg-[red]"
                : null
          }`}></span>
      {status}
    </span>
  )
}

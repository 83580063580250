import { useState, useEffect } from "react";
import "./issueDetails.scss";
import IssueDetailsCard from "./IssueDetailCard";
import Input from "../Input";
import { addCommentToFeedback, getFeedbackComment } from "../../actions/dashboardActions";
import dayjs from "dayjs";
import { useAppSelector } from "../../app/hooks";
import useIsAdmin from "../../hooks/useIsAdmin";

export const getBalance = (users, key) => {
    let pendingPayment = false;
    let balance = 0;
    if (!users) {
        return {balance, pendingPayment};
    }
    users.forEach((user) => {
        const val = parseFloat(user[key]) || 0;
        balance += val;
        if (val <= 0) {
            pendingPayment = true;
        }
    });
    return {
        balance, 
        pendingPayment
    };
}

const IssueDetail = (props) => {
    const issue = props.issue;
    const [comments, setComments] = useState<Array<Record<string, any>>>([]);
    const [comment, setComment] = useState('');


      useEffect(() => {
        getFeedbackComment(issue.id, (data) => {
            setComments(data?.data || [])
        });
      }, []);

      const handleAddComment = (event) => {
        event.preventDefault();
        addCommentToFeedback(issue.id, {content: comment}, (data) => {
            const newComments = comments.concat([data.data])
            setComments(newComments);
            setComment('');
        })
      }
    
   return (
        <div className="c-issue-details w-full flex !flex-col">
            <p className="text-2xl border-b-2 border-gray-300 pb-6">Feedback Details</p>
            <div className="my-2 flex flex-row w-full justify-between items-center">
                <p>Status</p>
                <button className="flex capitalize flex-row px-4 w-fit bg-[#0898A01A] h-10 justify-center items-center text-az-teal">
                    {issue.status}
                </button>
            </div>
            <div className="right mt-1 p-4 w-full !rounded-sm !bg-[#0898A008]">
                <IssueDetailsCard 
                    title={issue.title || issue.expense_name}
                    description={issue.description}
                    id={issue.id}
                    issue={issue}
                />
            </div>
            <form onSubmit={handleAddComment} className="w-full !my-0 !flex !flex-row !justify-between !items-start">
                <div className="w-11/12">
                    <Input required name={""} value={comment} onChange={(name, value) => {
                        setComment(value);
                    }} maxLength={200} placeholder="Enter your comment" />
                </div>
                <button type="submit" className="w-2/12 border border-black !mt-5 !ml-2">Send</button>
            </form>
            <div className="left w-full">
                {
                    comments?.length === 0 ? (
                        <p className="mt-2">No comment yet!</p>
                    ) : (
                            <div className="users w-full flex flex-col overflow-auto max-h-[300px]">
                                <p className="mt-2">Comments</p>
                                {
                                    comments?.map(({content, source, id, created_at, user_id}) => {
                                        return (
                                            <FeedbackComment
                                                comment={content}
                                                key={id}
                                                source={source}
                                                created_at={created_at}
                                                user_id={user_id}
                                            />
                                        )}
                                    )
                                }
                            </div>
                    )
                }
            </div>
        </div>
    );
};

export default IssueDetail;

const FeedbackComment = ({ comment, source, created_at, user_id}) => {
    const {user} = useAppSelector((state) => state.auth);
    const isAdmin = useIsAdmin();
    const isMine = user_id === user?.id && !isAdmin;
    return (
        <div
            className="max-h-20 h-fit p-2 my-2 border border-gray w-11/12 rounded flex flex-col justify-between items-end"
            style={{alignSelf: isMine ? 'start' : 'end'}}
        >
            <div className="w-full h-[90%] overflow-auto text-sm">{comment}</div>
            <div
                className="flex w-full flex-row mt-2 items-center text-xs"
                style={isMine ? {justifyContent: 'flex-start'} : {justifyContent: 'flex-end'}}
            >
                <p className="">{isMine ? 'You' : 'Admin'}</p><p className="ml-2">{dayjs(created_at).fromNow()}</p>
            </div>
        </div>
    )
}

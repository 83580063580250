export default function VoucherTopComponents({ leftComponents, middleComponents, rightComponents, filled = false, className }: { leftComponents?: Array<JSX.Element>, middleComponents?: Array<JSX.Element>, rightComponents?: Array<JSX.Element>, filled?: boolean, className?: string }) {
  return (
    <div className={`w-[100%] flex flex-wrap gap-2 items-center justify-between p-[20px] ${filled && "bg-white"} ${className}`}>
      <div className="flex flex-wrap gap-2 items-center justify-center">
        {leftComponents?.map((leftCom, index) => (leftCom))}
      </div>
      <div className="flex flex-wrap gap-2 items-center justify-center">
        {middleComponents?.map((middleCom, index) => (middleCom))}
      </div>
      <div className="flex flex-wrap gap-2 items-center justify-center">
        {rightComponents?.map((rightCom, index) => (rightCom))}
      </div>
    </div>
  )
}


import { useState, useEffect } from "react";
import { getBusinessInvoices, getSentInvoices } from "../../actions/businessActions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useGetBusinessCode from "../../hooks/useGetBusinessCode";
import NotFound from "../NotFound";
import Modal from "../Modal";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import Button from "../Button";
import { formatCurrency, navigatorShare } from "../../utils/common";
import { compare } from "../../utils/compare";
import Table from "../Table";
import { TableAction } from "../ExpenseList";
import dayjs from "dayjs";

const InvoiceTransactions = (props) => {
  const [isOpenResend, setIsOpenResend] = useState(false);
  const [businessCode] = useGetBusinessCode();
  const dispatch = useAppDispatch();
  const createdInvoices = useAppSelector((state) => state.business.createdInvoices[businessCode as string]);
  const createdInvoicesMetaData = useAppSelector((state) => state.business.createdInvoicesMetaData[businessCode as string]);

  useEffect(() => {
    if (businessCode && !createdInvoices) {
      getBusinessInvoices(businessCode);
    }
  }, [businessCode, createdInvoices]);

  const handleClose = () => {
    setIsOpenResend(false);
  }


  const handlePagination = (payload) => {
    // const page = payload.split('=')[1];
    // Add pagination
    getSentInvoices(dispatch, businessCode);
  }

  const handleShare = (data) => {
    navigatorShare({
      title: data.name,
      text: `You are expected to pay ${formatCurrency({num: data.total})} for ${data.description}. Click ${data.link} to pay.`,
      url: data.link,
      email: data.email
    }, dispatch);
  }

  const parsedTransactions = createdInvoices && createdInvoices.map((item) => {
    const newItm:Record<string, any> = {...item};
    let grandTotal = 0;
    let paid = 0;
    newItm.data.forEach(element => {
      grandTotal += parseInt(element.Grand_total);
      if (element.status) {
        paid = element.amount - element.commission;
      }
      newItm.issue_date = element.issue_date;
      newItm.created_at = element.created_at
    });
    newItm.Grand_total = grandTotal;
    newItm.paid = paid;
    newItm.isInvoice = true;
    return newItm;
  }).slice().sort(compare).reverse();

  const header = [
    {field: 'email', headerName:  'Title'},
    {field: 'Grand_total', headerName:  'Amount', cellRenderer: ({data}: any) => `${formatCurrency({num: data.Grand_total})}`},
    {field: 'created_at', headerName: 'Create At', cellRenderer: ({data}: any) => dayjs(data.created_at).fromNow()},
    {
      field: 'Status',
      headerName:  'Status',
      cellRenderer: ({data}: any) =>  (
        <div className=' flex justify-center items-center'> {parseInt(data?.paid) >= parseInt(data?.Grand_total) ? (<p className="px-2 flex justify-center items-center bg-green-500 text-white rounded !w-fit h-8">Paid</p>) : (<p className="px-2 bg-orange-400 rounded text-white !w-fit h-8 flex justify-center items-center">Pending</p>)}</div>
      )
    },
    {
      field: 'action',
      headerName:  'Action',
      cellRenderer: ({data}: any) =>  <TableAction handleMore={() => props.handleMore(data)} />
    }
  ];

  const mobileConfig = {
    logo: {
      value: '',
      renderer: (data: any) => (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="22.5" cy="22.5" r="22.5" fill="#FFE6E4"/>
          <path d="M22.7256 15.25V30.25" stroke="#B40D00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16.701 21.2998L22.725 15.2498L28.75 21.2998" stroke="#B40D00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    },
    topLeft: {
      // field: 'email',
      renderer: (data) => <p className="overflow-hidden w-[60%]">{data.email}</p>
    },
    topRight: {
      renderer: (data: any) =>  <div className=' flex justify-center items-center'> {parseInt(data?.paid) >= parseInt(data?.Grand_total) ? (<p className="px-2 flex justify-center items-center bg-green-500 text-white rounded !w-fit h-8">Paid</p>) : (<p className="px-2 bg-orange-400 rounded text-white !w-fit h-8 flex justify-center items-center">Pending</p>)}</div>
    },
    bottomLeft:  {
      field: '',
      renderer: (data: any) => `${formatCurrency({num: data.Grand_total})}`
    },
    more: {
      clickHandler: (data:any) => {
        props.handleMore(data);
      }
    }
  }


  return (
    <div className="!w-full">
      <div className="!w-full">
        {
          createdInvoices && createdInvoices.length > 0 ? (
            <div className="!w-full">
              <div className="expense-table overflow-table h-[65vh] text-left">
                <Table
                  data={parsedTransactions || []}
                  header={header}
                  fitStrategy="fitGridWidth"
                  mobileConfig={mobileConfig}
                />
              </div>
            </div>
          ) : (
            <NotFound 
              text1="You have not created any Transaction yet!"
              text2='Create your first Transaction and receive a payment link or generate an invoice to be sent directly to the customer.'
              btnLabel="Create a Transaction"
              onClick={() => props.handleCreate()}
            />
          )
        }
        
        {
          createdInvoicesMetaData && (
            <TablePaginationFooter 
              total={createdInvoicesMetaData.total}
              from={createdInvoicesMetaData.from}
              to={createdInvoicesMetaData.to}
              nextUrl={createdInvoicesMetaData.next_page_url}
              prevUrl={createdInvoicesMetaData.prev_page_url}
              handlePagination={handlePagination}
            />
          )
        }
      </div>
      <Modal
        open={isOpenResend}
        onClose={handleClose}
        overrideStyle={{
            maxWidth: '400px',
            width: '100%',
            height: 'max-content',
        }}
        title="Confirm Transaction Delete Modal"
      >
        <div className="confirm-delete">
          {/* <p>
            Retrieving Your Invoice...
          </p> */}
          <div>
            <Button
              type="contained"
              label="No"
              onClick={handleClose}
            />
            <Button
              type="contained"
              label="Share"
              onClick={() => handleShare({
                text: '',
                link: '',
                body: '',
                title: '',
                email: '',
              })}
            />
          </div>
        </div>    
      </Modal>
    </div>
  );
};

export default InvoiceTransactions;

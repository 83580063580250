import { FormEvent, useEffect, useState } from 'react';
import Input, { Select as SelectComponent } from '../Input';
import Button from '../Button';
import './index.scss';
import { useAppSelector } from '../../app/hooks';
import toast from 'react-hot-toast';
import {
  acceptAjoInvite,
  acceptAjoWithDD,
  createAjo, 
  getAjoById, 
  getDirectDebitBanks, 
  getUserAjoSilent, 
  inviteUserAjo, } from '../../actions/dashboardActions';
import Modal from '../Modal';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import BankInput from '../BankInput';
import { Bank } from '../../pages/Bank/bankSlice';
import useShowNewFeature from '../../hooks/useShowNewFeature';
import { handleMandateMessage } from '../../actions/authActions';
dayjs.extend(isSameOrAfter);

const frequencyMap = {
  Daily: 'Day',
  Weekly: 'Week',
  Monthly: 'Month'
}

const CreateAjo = (props) => {
  const showFeature = useShowNewFeature();
  const [useManual, setUseManual] = useState(false);
  const {user} = useAppSelector((state) => state.auth);
  const address = user?.address;
  const [participants, setParticipants] = useState<Array<Record<string, any>>>([]);
  const [step, setStep] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [ddBanks, setDDBank] = useState<Record<string, string>[]>([]);
  const [availablePosition, setAvailablePosition] = useState({});
  const [data, setData] = useState({
    name: '',
    amount: '',
    startDate: '',
    id: '',
    splittingId: '',
    bankName: '',
    cycle: '',
    frequency: '',
    membersCount: '',
    creatorPosition: '',
    account: '',
    inviteLink: '',
  });

  const [mandateSuccMsg, setMandateSuccMsg] = useState('');

  useEffect(() => {
    if (showFeature) {
      getDirectDebitBanks((resp) => {
        setDDBank(resp);
      });
    }
  }, []);

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleAcceptManual = () => {
    acceptAjoInvite(data.id, {email: user?.email}, () => {
      props.renderCancel();
    })
  }

  const isFirst = step === 0;
  const isSecond = step === 1;
  const isThird = step === 2;
  const renderStep = () => {
    return (
      <div className="stepper my-6 flex flex-row !justify-start !items-center w-full">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isThird ? 'active' : ''}`} />
      </div>
    )
  };

  const handleAddFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (dayjs(new Date(data.startDate)).isBefore(dayjs(new Date()), 'date')) {
      toast.error('Please select a future start date!')
      return;
    }
    if (parseInt(data.creatorPosition) < 1 || data.creatorPosition > data.membersCount) {
      toast.error('Please enter a correct collection position for you.')
      return;
    }
    if (parseInt(data.amount) <= 0) {
      toast.error('Amount per member must be greater than 0!')
      return;
    }
    createAjo({
      ...data,
      "starting_date": data.startDate,
      frequency: data.frequency,
      "amount_per_member": data.amount,
      "member_count": data.membersCount,
    }, async (resp) => {
      setStep(1);
      handleChange('id', resp.id);
      const newAvailPos = {};
      for (let index = 1; index <= parseInt(data.membersCount); index++) {
        if (index !== parseInt(data.creatorPosition)) {
          newAvailPos[index] = true;
        }
      }
      setAvailablePosition(newAvailPos);
      addParticipants({
        position: data.creatorPosition,
        name: user?.name,
        email: user?.email
      }, newAvailPos);
    });
  }

  const addParticipants = (payload, positions?: any) => {
    participants.push(payload);
    setParticipants([...participants]);
    let newAvailPos = {...availablePosition};
    if (positions) {
      newAvailPos = Object.assign(newAvailPos, positions);
    }
    delete newAvailPos[payload.position];
    setAvailablePosition(newAvailPos);
  }

  const handleAddParticipants = (userData: Record<string, any>) => {
    const userExists = participants.find((itm) => itm.position == userData.position || itm.email == userData.email);
    if (userExists) {
      toast.error('A user with provided details already exist(Email & Position)')
      return;
    }
    addParticipants({
      ...userData,
    });
    setOpenAddModal(false);
  }

  const handleRemoveParticipant = (data) => {
    const participant = participants.find((itm) => itm.email === data);
    if (participant) {
      setParticipants(participants.filter((participant) => participant.email !== data));
      const newAvailPos = {...availablePosition};
      newAvailPos[participant.position] = true;
      setAvailablePosition(() => ({
        ...newAvailPos,
      }));
    }
  }

  const handleAddMembers = () => {
    inviteUserAjo({
      users: participants.map((itm) => ({
        phone_number: itm.phone,
        name: itm.name,
        email: itm.email,
        position: itm.position,
      })),
      productName: 'ajo-dd',
      productDescription: 'Generic ajo'
      , ajoId: data.id}, (resp) => {
        if (data.frequency === 'Daily') {
          acceptAjoInvite(data.id, {email: user?.email}, () => {
            props.renderCancel();
            getUserAjoSilent(1);
          });
        } else {
          setStep(2);
          getUserAjoSilent(1);
          const userLink = resp.links.find(itm => itm.indexOf(user?.email) >= 0);
          handleChange('inviteLink', userLink);
        }
    })
  }

  const configureAjoDD = async () => {
    if (!selectedBank?.account_name) {
      return toast('No selected bank');
    }
    const bankIsDDBank = ddBanks.find((bank) => bank.nipCode === selectedBank.bankCode);
    if (!bankIsDDBank) {
      setUseManual(true);
      return toast('Selected bank does not allow direct debit');
    }
    const queries = data.inviteLink.split('?')[1]?.split('&');
    const querieObj:Record<string, any> = {};
    queries?.forEach(element => {
      const splited = element.split('=');
      querieObj[splited[0]] = splited[1];
    });
    acceptAjoWithDD({
      // @ts-ignore
      inviteLink: `${data.inviteLink}&action=accept`,
      // @ts-ignore
      bankName: selectedBank.bankName,
      // @ts-ignore
      bankCode: selectedBank.bankCode,
      accountName: selectedBank.account_name,
      accountNumber: selectedBank.account_number,
      // @ts-ignore
      productId: parseInt(querieObj.productId),
      productName: 'ajo-dd',
      homeAddress: address,
      description: data.name,
      paymentFrequency: data.frequency,
      remarks: data.name,
      ajoId: data.id,
      // @ts-ignore
      referenceId: selectedBank.referenceId
    }, (resp) => {
      if (resp && resp.product) {
        handleMandateMessage(resp.product.message);
      }
      props.renderCancel();
    }, () => {
      setUseManual(true);
    })
  }

  return (
    <div className="w-full h-full">
      <div className='w-full flex flex-col justify-start items-center'>
        {renderStep()}
        <h3 className='w-full text-left text-3xl mt-4'>{step === 0 ? 'Create Ajo' : step === 2 ? 'Configure direct debit for this Ajo' : 'Add Participants'}</h3>
      </div>
      <div className='w-full h-full flex flex-col justify-center items-center py-5'>
        {
          isFirst && (
            <form onSubmit={handleAddFormSubmit} className='h-full  my-5'>
              <Input
                label="Enter Ajo's Name"
                placeholder="Name"
                name="name"
                required
                value={data.name}
                onChange={handleChange}
              />
              <div className="input-grp-wrapper">
                <SelectComponent
                  data={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'BiAnnual', 'Annual']}
                  label="Choose Frequency."
                  placeholder=""
                  name="frequency"
                  required
                  value={data.frequency}
                  onChange={handleChange}
                />
                <Input
                  label="Select Starting Date"
                  placeholder="0"
                  name="startDate"
                  type="date"
                  required
                  min={1}
                  value={data.startDate}
                  onChange={handleChange}
                />
              </div>
              <div className="input-grp-wrapper">
              <Input
                  label="Amount per Member"
                  placeholder="2000"
                  name="amount"
                  type="number"
                  required
                  min={1}
                  value={data.amount}
                  onChange={handleChange}
                />
                <Input
                  label="How many members?"
                  placeholder="0"
                  name="membersCount"
                  type="number"
                  required
                  min={1}
                  value={data.membersCount}
                  onChange={handleChange}
                />
              </div>
              <Input
                label="Choose your collection position?"
                placeholder="1"
                name="creatorPosition"
                type="number"
                required
                value={data.creatorPosition}
                onChange={handleChange}
                min={1}
              />
              <Button
                label="Create"
                type="flat"
                btnActionType="submit"
                overrideStyle={{
                  padding: '10px',
                  marginBottom: '10px'
                }}
              />
            </form>
          )
        }
        {
          isSecond && (
            <div className='user-group-cont w-full h-[70vh] self-start'>
              <div className='user-group-wrapper w-full h-[100%]'>
                {participants.length > 0 && ( <div>
                  {
                    participants.reverse().map((participant) => (
                      <div className='particpant-wrapper my-2' key={participant.email}>
                        <p className='logo !w-10 !h-10'>{participant.name[0]}</p>
                        <div className="ajo-mem-details">
                          <p>{participant.name} {participant.email === user?.email ? '(You)' : ''}</p>
                          <p>{participant.email}</p>
                        </div>
                        <p className='!w-5 !h-5'>{participant.position}</p>
                        {
                          participant.email !== user?.email && (
                            <button
                              onClick={() => handleRemoveParticipant(participant.email)}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                <path d="M16.3238 7.46875C16.3238 7.46875 15.7808 14.2037 15.4658 17.0407C15.3158 18.3957 14.4788 19.1898 13.1078 19.2148C10.4988 19.2618 7.88681 19.2648 5.27881 19.2098C3.95981 19.1828 3.13681 18.3788 2.98981 17.0478C2.67281 14.1858 2.13281 7.46875 2.13281 7.46875" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M17.708 4.24023H0.75" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.4406 4.23998C13.6556 4.23998 12.9796 3.68498 12.8256 2.91598L12.5826 1.69998C12.4326 1.13898 11.9246 0.750977 11.3456 0.750977H7.11258C6.53358 0.750977 6.02558 1.13898 5.87558 1.69998L5.63258 2.91598C5.47858 3.68498 4.80258 4.23998 4.01758 4.23998" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            </button>
                          )
                        }
                      </div>
                    ))
                  }
                </div>)
                }
              </div>
              <Modal 
                onClose={() => setOpenAddModal(!openAddModal)} 
                open={openAddModal}
                overrideStyle={{
                  maxWidth: "400px",
                  width: "85%",
                  height: "max-content",
                  padding: "20px 5px",
                }}
                title="Add Ajo Member"
              >
                <AjoMemberForm
                  handleAddMember={handleAddParticipants}
                  availablePosition={Object.keys(availablePosition)}
                />
              </Modal>
              <div className='btn-group w-full '>
                {
                  participants.length === parseInt(data.membersCount, 10) ? (
                    <Button
                      label="Done"
                      onClick={() => {
                        handleAddMembers();
                      }}
                      type="flat"
                      btnActionType="button"
                    />
                  ) : (
                    <Button
                      label={`Add new Member (${participants.length} of ${data.membersCount} added)`}
                      onClick={() => {
                        setOpenAddModal(true);
                      }}
                      type="flat"
                      btnActionType="button"
                      overrideStyle={{
                        width: '100%'
                      }}
                    />
                  )
                }
                
              </div>
            </div>
          )
        }
        {
          isThird && (
            <div className='user-group-cont w-full h-[70vh] self-start'>
              <div className='user-group-wrapper w-full h-[100%]'>
                {
                  (!useManual) ? (
                    <>
                      <BankInput
                        label="Enter debit account number"
                        setSelectedBank={(value) => {
                          const ddBank = ddBanks?.find((bank) => bank.nipCode === value.bankCode);
                          if (ddBank) {
                            setSelectedBank(value);
                            handleChange('bankName', value.bankName);
                          } else {
                            setSelectedBank(value);
                            setUseManual(true);
                            handleChange('bankName', value.bankName);
                            toast('Selected bank currently doesn\'t support direct debit, select or add another bank.');
                          }
                        }} 
                      />
                      <div className='btn-group w-full mt-10'>
                        <Button
                          label="Configure"
                          onClick={configureAjoDD}
                          type="flat"
                          btnActionType="button"
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <p className="w-full text-center py-10">Your direct debit configuration failed. </p>
                      <div className='flex w-full flex-row justify-around items-center py-10'>
                        <button className='w-5/12 px-6 border bg-az-teal py-2 text-white text-base' type="button" onClick={handleAcceptManual}>Use manual payment</button>
                        <button className='w-5/12 px-6 border bg-az-teal py-2 text-white text-base' type="button" onClick={() => setUseManual(false)}>Try direct debit again</button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CreateAjo;

export const AjoMemberForm = (props) => {
  const [availablePosition, setAvailablePositions] = useState([]);
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    position: '',
  });

  useEffect(() => {
    setAvailablePositions(props.availablePosition);
  }, [props.availablePosition]);
  
  

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleAddMember(data);
  };

  return (
    <form className="ajo-member-form w-full py-10" onSubmit={handleSubmit}>
      <Input
        label="Name"
        placeholder="Selman Ade"
        name="name"
        required
        value={data.name}
        onChange={handleChange}
      />
        <Input
          type='tel'
          label="Phone Number"
          placeholder="+2347033221122"
          name="phone"
          required
          pattern="[789][0-9]{9}"
          value={data.phone}
          onChange={handleChange}
        />
        <SelectComponent
          data={availablePosition}
          label="Member's Position"
          placeholder=""
          name="position"
          required
          value={data.position}
          onChange={handleChange}
        />
      <Input
          label="Email Address"
          placeholder="ema@example.com"
          name="email"
          type='email'
          required
          value={data.email}
          onChange={handleChange}
        />
      <div className='btn-group w-full mt-5'>
        <Button
          label="Add Member"
          onClick={() => {}}
          type="flat"
          btnActionType="submit"
          overrideStyle={{
            width: '100%'
          }}
        />
      </div>
    </form>
  )
}

import { type Dispatch, type SetStateAction, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import AjoDetails from "../../../components/AjoDetails";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import SidePane from "../../../components/SidePane";
import TabComponent from "../../../components/TabComponent";
import GetUserProduct from "../GetUserProduct";
import TablePaginationFooter from "../../../components/TablePaginationFooter/TablePaginationFooter";
import Accordion from "../../../components/AjoGroup/Accordion";
import { AccordionData } from "../../../types";
import NotFound from "../../../components/NotFound";
import { getAllAjoByEmail } from "../../../actions/adminActions";

const AdminAjo = () => {
    const {allAjos, allAjosByEmail} = useAppSelector(state => state.admin)
    const [kontribute, setKontribute] = useState<Record<string, any> | null>(null);
    const [userEmail, setUserEmail] = useState('');
    const handleGetProduct = (email) => {
        setUserEmail(email);
        getAllAjoByEmail(1, email)
    }

    const getAll = () => {
        setUserEmail('');
    }

    const handlePagination = (page) => {
        if (userEmail) {
            getAllAjoByEmail(page, userEmail)
        }
    }

    const allAjosObj = userEmail ? allAjosByEmail: allAjos;

    const renderAjoGroup = () => <AjoGroup ajos={allAjosObj} handleMore={setKontribute} handlePagination={handlePagination} />;
    return (
        <DashboardComponentWrapper
            subTitle="Ajo"
            pageDetails="Here's an overview of all ajo on AzatMe."
        >
            <>
                <GetUserProduct handleGetProduct={handleGetProduct} product="Ajo"  handleGetAll={getAll}  />
                <TabComponent
                    components={{
                        'Ajo': renderAjoGroup,
                    }}
                />
                <SidePane
                    onClose={() => setKontribute(null)}
                    open={!!kontribute}
                    title={""}
                >
                    <AjoDetails
                        group={kontribute}
                        handleCloseDetail={() => setKontribute(null)}
                        isMine
                    />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminAjo;

const AjoGroup = (props: {ajos: Record<string, any> | null, handleMore: Dispatch<SetStateAction<Record<string, any> | null>>, handlePagination:(page: string)=> void, handleCreate?:()=>void}) => {
    const handlePagination = (payload) => {
      const page = payload.split('=')[1];
      props.handlePagination(page)
    }
  
  
    return (
      <div className="user-group mt-6 bg-white !w-full !p-0">
        {
          (!props.ajos?.data) ?
          (
            <NotFound 
                text1="You have no Ajo yet!"
                text2='Begin the Journey by creating your first Ajo.'
                btnLabel='Start new Ajo.'
                onClick={() => props.handleCreate && props.handleCreate()}
            />
          ) : (
            <div className="wrapper !w-full">
              <Accordion
                items={props.ajos?.data as unknown as AccordionData[]}
                handleMore={props.handleMore}
                isMine={false}
              />
              <TablePaginationFooter
                total={props.ajos?.total}
                from={props.ajos?.from}
                to={props.ajos?.to}
                nextUrl={props.ajos?.next_page_url}
                prevUrl={props.ajos?.prev_page_url}
                handlePagination={handlePagination}
              />
            </div>
          )
        }
      </div>
    )
  }

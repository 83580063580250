import { useEffect, useState } from "react";
import TransactionsListWrapper from "../Transactions/TransactionListWrapper";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import AllTransactions from "../Transactions/AllTransactions";
import './transactionList.scss'
import InvoiceTransactions from "../Transactions/InvoiceTransactions";
import { useLocation } from "react-router-dom";

const transactionNavItems = [];

interface StateType {
  order?: any; // Replace `any` with the actual type of `order`
}
const TransactionListTab = ({allExpenses, handleCreate, handleMore}) => {
  const {state, pathname} = useLocation();
  
  useEffect(() => {
    const stateObj = state as StateType;
    if (stateObj && stateObj.order) {
      handleCreate();
    }
  }, []);

  const splittedPath = pathname.split('/')
  const isDirectLink = splittedPath[splittedPath.length - 1] === 'directlinks';

  return (
    <TransactionsListWrapper>
      { isDirectLink ? <AllTransactions handleCreate={handleCreate}  handleMore={handleMore} expenses={allExpenses} /> : <InvoiceTransactions handleCreate={handleCreate} handleMore={handleMore} />}
    </TransactionsListWrapper>
  );
}

export default TransactionListTab;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import SwitchBusiness from "../../components/SwitchBusiness";
import Kontri from '../../assets/new/KontributeOverView.png';
import TotalExpense from '../../assets/new/RefundMeOverview.png';
import useGetBusinessCode from "../../hooks/useGetBusinessCode";
import { getBusinessInvoices, getBusinessLinks, getBusinessReport } from "../../actions/businessActions";
import LegderModal from "../../components/LedgerModal";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Table from "../../components/Table";
import { TableAction } from "../../components/ExpenseList";
import { formatCurrency } from "../../utils/common";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];
export const bardata = {
  labels,
  datasets: [
    // {
    //   label: 'Pending',
    //   data: labels.map(() => Math.random() * 100),
    //   backgroundColor: '#C03636',
    // },
    {
      label: 'Transactions',
      data: labels.map(() => Math.random() * 100),
      backgroundColor: '#3BBF00',
    },
  ],
};

const BusinessOverview = () => {
  const [businessReport, setBusinessReport] = useState<Array<any> | null>(null);
  const [openLedger, setOpenLedger] = useState(false);
  const [tab, setTab] = useState('');
  const dispatch = useAppDispatch();
  const [businessCode] = useGetBusinessCode();
  const {business} = useAppSelector((state) => state);
  const createdInvoices = business.createdInvoices[businessCode as string] as Array<Record<string, any>>;
  const createdLinks = business.createdLinks[businessCode as string];
  // const expenses = dashboard.allExpenses;
  const transactions:Record<string, any> = business.transactions[businessCode as string];
  const navigate = useNavigate();

  useEffect(() => {
    if (businessCode && !createdInvoices) {
      getBusinessInvoices(businessCode);
    }
    if (businessCode && !createdLinks) {
      getBusinessLinks(businessCode);
    }
  }, [businessCode, createdInvoices]);

  useEffect(() => {
    if (businessCode) {
      getBusinessReport(businessCode, (resp) => {
        setBusinessReport(resp.data.data);
      });
    }
  }, [businessCode]);

  const data = [
    {
      icon: TotalExpense,
      title: 'Total Transactions',
      value: transactions?.total || 0,
      bgColor: '#F2E1E0',
      ledgerAction: '',
      ledgerText: '#0898A0'
    },
    {
      icon: Kontri,
      title: 'Awaiting Payment',
      value: 0,
      bgColor: '#EAF1EA',
      ledgerAction: '',
      ledgerText: '#27AE60'
    },
  ]

  const processReportData = () => {
    const labels:Array<any> = [];
    const businessData:Array<any> = [];
    if (businessReport) {
      businessReport.forEach((itm: Record<string, any>) => {
        labels.push(itm.month);
        businessData.push(itm.count || 0)
      });
    }
    return {
      labels,
      datasets: [
        {
          data: businessData,
          label: 'Transactions',
          backgroundColor: '#3BBF00',
        },
      ],
    };
  }


  const expenseNavItemsObj = [
    {
      label: 'View Ledger',
      action: () => setOpenLedger(true),
      bgColor: '#0898A0',
      img: '',
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        navItems={[]}
        navItemsObj={expenseNavItemsObj}
        tab={tab}
        setTab={() => {}}
        dontShowWithdraw
      />
    )
  };

  const compare = (a, b) => {
    if (a.created_at > b.created_at) {
      return 1;
    } else if (a.created_at < b.created_at) {
      return -1;
    }
    return 0;
  }

  const parsedTransactions = createdInvoices && createdInvoices.map((item) => {
    const newItm = {...item};
    let grandTotal = 0;
    let paid = 0;
    newItm.data.forEach(element => {
      grandTotal += parseInt(element.Grand_total);
      if (element.status) {
        paid = element.amount - element.commission;
      }
      newItm.issue_date = element.issue_date;
      newItm.created_at = element.created_at
    });
    newItm.Grand_total = grandTotal;
    newItm.paid = paid;
    newItm.isInvoice = true;
    return newItm;
  }).slice().sort(compare);

  const header = [
    {field: 'email', headerName:  'Title'},
    {field: 'Grand_total', headerName:  'Amount', cellRenderer: ({data}: any) => `${formatCurrency({num: data.Grand_total})}`},
    {
      field: 'Status',
      headerName:  'Status',
      cellRenderer: ({data}: any) =>  (
        <div className=' flex justify-center items-center'> {parseInt(data?.paid) >= parseInt(data?.Grand_total) ? (<p className="px-2 flex justify-center items-center bg-green-500 text-white rounded !w-fit h-8">Paid</p>) : (<p className="px-2 bg-orange-400 rounded text-white !w-fit h-8 flex justify-center items-center">Pending</p>)}</div>
      )
    },
    {
      field: 'action',
      headerName:  'Action',
      cellRenderer: ({data}: any) =>  <TableAction handleMore={() => navigate(`transactions`, {
        state: {
          expense: data,
        }
      })} />
    }
  ];

  const mobileConfig = {
    logo: {
      value: '',
      renderer: (data: any) => (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="22.5" cy="22.5" r="22.5" fill="#FFE6E4"/>
          <path d="M22.7256 15.25V30.25" stroke="#B40D00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16.701 21.2998L22.725 15.2498L28.75 21.2998" stroke="#B40D00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    },
    topLeft: {
      // field: 'email',
      renderer: (data) => <p className="overflow-hidden w-[60%]">{data.email}</p>
    },
    topRight: {
      renderer: (data: any) =>  <div className=' flex justify-center items-center'> {parseInt(data?.paid) >= parseInt(data?.Grand_total) ? (<p className="px-2 flex justify-center items-center bg-green-500 text-white rounded !w-fit h-8">Paid</p>) : (<p className="px-2 bg-orange-400 rounded text-white !w-fit h-8 flex justify-center items-center">Pending</p>)}</div>
    },
    bottomLeft:  {
      field: '',
      renderer: (data: any) => `${formatCurrency({num: data.Grand_total})}`
    },
    more: {
      clickHandler: (data:any) => {
        navigate(`transactions`, {
          state: {
            expense: data,
          }
        })
      }
    }
  }

  return (
    <DashboardComponentWrapper
      title="Business Overview"
      overrideName={() => <SwitchBusiness />}
      renderNavItems={renderNavItems}
      pageDetails="Here's an overview of your business activities on AzatMe."
    >
      <div className="overview">
        <div className='overview-right'>
          <div className="overview-card-wrapper">
            {
              data.map((item) => (
                <div className="!w-[46%]  mb-4 flex flex-col justify-between items-start h-[180px] p-6 rounded-md" key={item.title} style={{background: item.bgColor}}>
                    <div className='flex flex-col justify-start items-start'>
                      <img className='mr-4 w-10 h-10' src={item.icon} alt="" />
                      <p className='text-2xl mt-2 font-light text-left'>{item.title}</p>
                    </div>
                    <div className='flex flex-row w-full justify-between items-center'>
                      <span>{item.value}</span>
                    </div>
                  </div>
              ))
            }
          </div>
          <div className='a-expense-graph'>
            <div className='graph'>
              <Bar data={processReportData()} options={options} />
            </div>
          </div>
        </div>
        <div className='overview-left'>
          <div className='recent-expense !h-[66vh]'>
            <div className='flex flex-row items-center justify-between'>
              <h5 className='!px-5 !lg:px-10'>Recent Transactions</h5>
              <h5
                className='!px-5 !lg:px-10 flex flex-row items-center justify-center cursor-pointer'
                onClick={() => navigate(`/business/dashboard/${businessCode}/transactions`)}
              >
                View all
                <svg className='mx-3' xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                  <path d="M1.66602 1.33341L6.33268 6.00008L1.66602 10.6667" stroke="#0898A0" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </h5>
            </div>
            <div className="overflow-auto  h-[calc(100%-2rem)]">
              <div className='flex flex-col justify-start h-full px-[4%]'>
                <Table
                  data={parsedTransactions || []}
                  header={header}
                  mobileConfig={mobileConfig}
                />
              </div>
            </div>
          </div>
        </div>
        <LegderModal type="business" isOpen={openLedger} handleClose={() => setOpenLedger(false)} />
      </div>
    </DashboardComponentWrapper>
  )
};

export default BusinessOverview;

import BusinessPageBuilder from './BusinessProductBuilder'
import Icon from '../../assets/new/business/sponsor-voucher.png';
import Hero1 from '../../assets/new/business/sponsor.png';
import RefProd2 from '../../assets/new/business/wallet.svg';
import RefProd1 from '../../assets/new/business/options.svg';
import RefProd3 from '../../assets/new/business/security.svg';
import RefProd4 from '../../assets/new/business/secure.svg';
import RefProd5 from '../../assets/new/business/chart.svg';
import RefProd6 from '../../assets/new/business/flex.svg';
import { Reason } from "../../types";
import { voucherForSponsor } from '../../data/faqData';

function VoucherSponsor() {

  const reasons: Reason[] = [
    {
      tagline: 'AFFORDABILITY',
      title: `Cost-effective`,
      details: `AzatMe mPOS is generally much cheaper
            than traditional POS systems. We require
            no upfront investment nor does it puts a
            pressure on minimum turnover.`,
      img: RefProd2,
      bg: ''
    },
    {
      tagline: 'PAYMENT',
      title: `Wider Payment Options`,
      details: `AzatMe mPOS systems accept more
            payment options than traditional POS
            systems. This caters to the growing
            preference for cashless transactions.`,
      img: RefProd1,
      bg: ''
    },
    {
      tagline: 'SECURITY',
      title: `Improve Security`,
      details: `AzatMe mPOS systems typically use
            encryption to protect customer data and
            often don't store card information on the
            device, minimizing the risk of a security
            breach.`,
      img: RefProd3,
      bg: '#F5F8FF'
    },
    {
      tagline: 'MOBILITY',
      title: `Increased Mobility`,
      details: `Unlike traditional POS systems tied to a
            specific location, MPOS allows you to take
            payments on the go. This is ideal for
            businesses like food trucks, farmers' 
            markets, or delivery services.`,
      img: RefProd4,
      bg: ''
    },
    {
      tagline: 'DATA AND INSIGHTS',
      title: `Real-time Data`,
      details: `AzatMe mPOS systems offer real-time
            sales data and inventory tracking. This 
            allows you to make better business
            decisions and manage your stock more
            efficiently.`,
      img: RefProd5,
      bg: ''
    },
    {
      tagline: 'SIMPLICITY',
      title: `Simple to use`,
      details: `AzatMe mPOS systems are designed to be 
            user-friendly, with intuitive interfaces that
            are easy for staff to learn, even with
            minimal training.`,
      img: RefProd6,
      bg: '#F5F8FF'
    }
  ];

  //   Step 1: Create a Sponsor Account
  // Start Making an Impact!
  // Sign up and verify your account to begin allocating funds securely through vouchers. It’s fast and user-friendly.

  // Step 2: Define Your Campaign
  // Target and Allocate Funds!
  // Set up your voucher campaign by defining the total budget, voucher amounts, recipient criteria, and participating merchants.

  // Step 3: Distribute Vouchers
  // Empower Recipients!
  // Easily allocate vouchers to your selected individuals or groups with just a few clicks.

  // Step 4: Track Results and Impact
  // Stay in Control!
  // Monitor voucher usage, redemption rates, and the overall impact of your funding through our real-time analytics dashboard.

  const howItWorks = [
    {
      title: 'Sign on to the business part of the AzatMe App.',
      details: `JStart Making an Impact! 
      Sign up and verify your account to begin allocating funds securely through vouchers. It’s fast and user-friendly.`
    },
    {
      title: `Select a business from the business list
and tap on the mPOS icon to launch it.`,
      details: ``
    },
    {
      title: `Define Your Campaign`,
      details: `Set up your voucher campaign by defining the total budget, voucher amounts, recipient criteria, and participating merchants.`
    },
    {
      title: `Start Accepting Vouchers `,
      details: `Easily allocate vouchers to your selected individuals or groups with just a few clicks.`
    },
    {
      title: `Distribute Vouchers`,
      details: `Use the merchant dashboard to view voucher transactions, analyze customer trends, and plan for future growth.`
    },
    {
      title: `Track Results and Impact`,
      details: `Monitor voucher usage, redemption rates, and the overall impact of your funding through our real-time analytics dashboard.`
    },
  ];
  const benefits = []

  return (
    <BusinessPageBuilder
      title="Empower Communities, Simplify Support!"
      name={"AzatMe Voucher For Sponsor"}
      tagline={`Empower your community or employees by distributing funds securely through vouchers. Allocate, manage, and monitor redemptions with our streamlined, user-friendly platform designed for effortless impact.`}
      titleIcon={Icon}
      image={Hero1}
      imgText={[]}
      summary={`AzatMe Voucher for sponsor offer a variety of advantages for businesses, 
especially those that are just starting out or don't need the complexity of a traditional POS system.`}
      reasons={reasons}
      howItWorks={{ howItWorks }}
      benefits={benefits}
      faq={voucherForSponsor}
    />
  )
}

export default VoucherSponsor
import BusinessPageBuilder from './BusinessProductBuilder'
import Icon from '../../assets/new/business/merchant-voucher.png';
import Hero1 from '../../assets/new/business/merchant.png';
import RefProd2 from '../../assets/new/business/wallet.svg';
import RefProd1 from '../../assets/new/business/options.svg';
import RefProd3 from '../../assets/new/business/security.svg';
import RefProd4 from '../../assets/new/business/secure.svg';
import RefProd5 from '../../assets/new/business/chart.svg';
import RefProd6 from '../../assets/new/business/flex.svg';
import { Reason } from "../../types";
import { mPOS, voucherForMerchant } from "../../data/faqData";

function VoucherMerchant() {

  const reasons: Reason[] = [
    {
      tagline: 'AFFORDABILITY',
      title: `Cost-effective`,
      details: `AzatMe mPOS is generally much cheaper
            than traditional POS systems. We require
            no upfront investment nor does it puts a
            pressure on minimum turnover.`,
      img: RefProd2,
      bg: ''
    },
    {
      tagline: 'PAYMENT',
      title: `Wider Payment Options`,
      details: `AzatMe mPOS systems accept more
            payment options than traditional POS
            systems. This caters to the growing
            preference for cashless transactions.`,
      img: RefProd1,
      bg: ''
    },
    {
      tagline: 'SECURITY',
      title: `Improve Security`,
      details: `AzatMe mPOS systems typically use
            encryption to protect customer data and
            often don't store card information on the
            device, minimizing the risk of a security
            breach.`,
      img: RefProd3,
      bg: '#F5F8FF'
    },
    {
      tagline: 'MOBILITY',
      title: `Increased Mobility`,
      details: `Unlike traditional POS systems tied to a
            specific location, MPOS allows you to take
            payments on the go. This is ideal for
            businesses like food trucks, farmers' 
            markets, or delivery services.`,
      img: RefProd4,
      bg: ''
    },
    {
      tagline: 'DATA AND INSIGHTS',
      title: `Real-time Data`,
      details: `AzatMe mPOS systems offer real-time
            sales data and inventory tracking. This 
            allows you to make better business
            decisions and manage your stock more
            efficiently.`,
      img: RefProd5,
      bg: ''
    },
    {
      tagline: 'SIMPLICITY',
      title: `Simple to use`,
      details: `AzatMe mPOS systems are designed to be 
            user-friendly, with intuitive interfaces that
            are easy for staff to learn, even with
            minimal training.`,
      img: RefProd6,
      bg: '#F5F8FF'
    }
  ];

  const howItWorks = [
    {
      title: 'Sign on to the business part of the AzatMe App.',
      details: `Join Our Merchant Network!
Sign up and provide your business details, including verification documents. It’s the first step to accepting vouchers and growing your customer base`
    },
    {
      title: `Select a business from the business list
and tap on the mPOS icon to launch it.`,
      details: ``
    },
    {
      title: `Set Up Your Storefront`,
      details: `List your business location, operating hours, and any exclusive offers for voucher users to attract more customers.`
    },
    {
      title: `Start Accepting Vouchers `,
      details: `Enable voucher redemptions at your store using our easy-to-use tools for validation and payment processing.`
    },
    {
      title: `Monitor Your Growth`,
      details: `Use the merchant dashboard to view voucher transactions, analyze customer trends, and plan for future growth.`
    },
  ];
  const benefits = []

  return (
    <BusinessPageBuilder
      title="Grow Your Business with Us!"
      name={"AzatMe Voucher For Merchant"}
      tagline={`Expand your reach by joining our merchant network! Accept vouchers seamlessly, attract loyal customers, and increase sales—all while simplifying your payment processes.`}
      titleIcon={Icon}
      image={Hero1}
      imgText={[]}
      summary={`AzatMe Voucher for merchant offer a variety of advantages for businesses, 
especially those that are just starting out or don't need the complexity of a traditional POS system.`}
      reasons={reasons}
      howItWorks={{ howItWorks }}
      benefits={benefits}
      faq={voucherForMerchant}
    />
  )
}

export default VoucherMerchant
import { useEffect, useState } from "react";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import { getAllBusinessTransactions, getAllBusinessTransactionsByEmail } from "../../../actions/adminActions";
import { useAppSelector } from "../../../app/hooks";
import TabComponent from "../../../components/TabComponent";
import BusinessTransactions from "./BusinessTransactions";
import GetUserProduct from "../GetUserProduct";

const AdminTransactions = () => {
    const { allBusinessTransactions, allBusinessTransactionsByEmail } = useAppSelector((state) => state.admin);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        getAllBusinessTransactions(1)
    }, [])

    const getAll = () => {
        setUserEmail('');
    }

    const handleGetTransactions = (email: string) => {
        getAllBusinessTransactionsByEmail(1, email);
        setUserEmail(email)
    }

    const handlePagination = (page) => {
        if (userEmail) {
            getAllBusinessTransactionsByEmail(page, userEmail)
        } else {
            getAllBusinessTransactions(page)
        }
    }
    const allBusinessTransactionsData = userEmail ? allBusinessTransactionsByEmail : allBusinessTransactions;
    return (
        <DashboardComponentWrapper
            subTitle="Transactions"
            pageDetails="Here's an overview of all Transactions on AzatMe."
        >
            <>
                <GetUserProduct handleGetProduct={handleGetTransactions} product="transactions" handleGetAll={getAll} />
                <TabComponent
                    components={{
                        "Business": () => <BusinessTransactions transactionsData={allBusinessTransactionsData} paginate={handlePagination} />,
                    }}
                />
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminTransactions;

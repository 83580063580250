// import Notification from '../../assets/notifications.png';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Switch from "react-switch";
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { switchUser } from '../../actions/authActions';
import closeIcon from "../../assets/close-icon.svg";
import useVerificationDone from '../../hooks/useVerificationDone';
import { setShowVerificationPending } from '../../pages/Auth/authSlice';
import Help from '../../assets/help.png';
import { toggleSideNav } from '../../pages/Dashboard/dashboardSlice';
import useUserType from '../../hooks/useUserType';

interface IDashboardComponentWrapper {
  children: JSX.Element;
  title?: string;
  subTitle?: string,
  pageDetails?: string,
  overrideName?: () => JSX.Element;
  renderNavItems?: () => JSX.Element;
}

const helpText = {
  'Your Businesses': 'All your created business profiles are displayed here, click on any of the business card(s) below to view more about the business.',
  'RefundMe': 'Manage all you expense payback using RefundMe.',
  'Kontribute': 'Manage all your group contributions of varying purposes using Kontribute.'
}

const DashboardComponentWrapper = ({ children, title, subTitle, pageDetails, renderNavItems, overrideName }: IDashboardComponentWrapper) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [mode, setMode] = useState('');
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    const seen = title && localStorage.getItem(title);
    if (!seen && title) {
      setShowHelp(true);
      localStorage.setItem(title, 'true');
    }
  }, [title]);

  useEffect(() => {
    if (pathname.indexOf('business') >= 0) {
      setMode('Personal')
    } else {
      setMode('Business')
    }
  }, [pathname]);

  const handleCloseText = () => {
    title && localStorage.setItem(title, 'true');
    setShowHelp(false);
  };

  return (
    <div className='dashboard-comp-wrapper'>
      {
        (overrideName || (window.innerWidth < 800)) && (
          <div className='comp-wrapper-header mb-4 h-20 !px-8'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              className='cursor-pointer flex lg:hidden'
              onClick={() => {
                dispatch(toggleSideNav(true));
              }}
            >
              <path d="M19 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1C20 1.26522 19.8946 1.51957 19.7071 1.70711C19.5196 1.89464 19.2652 2 19 2ZM20 7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6H1C0.734784 6 0.48043 6.10536 0.292893 6.29289C0.105357 6.48043 0 6.73478 0 7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8H19C19.2652 8 19.5196 7.89464 19.7071 7.70711C19.8946 7.51957 20 7.26522 20 7ZM20 13C20 12.7348 19.8946 12.4804 19.7071 12.2929C19.5196 12.1054 19.2652 12 19 12H1C0.734784 12 0.48043 12.1054 0.292893 12.2929C0.105357 12.4804 0 12.7348 0 13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H19C19.2652 14 19.5196 13.8946 19.7071 13.7071C19.8946 13.5196 20 13.2652 20 13Z" fill="black" />
            </svg>
            {overrideName ? overrideName() : ''}
          </div>
        )
      }
      <div className='top-nav flex flex-row flex-wrap !px-8 h-auto my-4 !items-start'>
        <div className="flex flex-col justify-start w-max max-w-[250px] my-3">
          <h4 className='text-xl md:text-3xl flex flex-row items-center'>
            {title}
            {
              title && helpText[title] && (
                <div className='custom-help-wrapper relative'>
                  <img src={Help} alt="Help" onClick={() => setShowHelp(true)} className='mx-3 h-5 w-5 cursor-pointer' />
                  {
                    showHelp && (
                      <div className="text-lg custom-help absolute p-4 bg-white shadow-md z-50 right-[-200px] top-[-10px] w-[200px] rounded-md">
                        <img src={closeIcon} alt="" className='w-6 h-6 close-btn absolute right-0 top-0 cursor-pointer' onClick={handleCloseText} />
                        {helpText[title]}
                      </div>
                    )
                  }
                </div>
              )
            }
          </h4>
          <p className='w-max text-left text-2xl'>{subTitle}</p>
          <p className='lg:w-max text-left text-sm my-2 w-full'>{pageDetails}</p>
        </div>
        <div className='w-fit bg-white'>
          {renderNavItems && renderNavItems()}
        </div>
      </div>
      <div className='children-wrapper h-full'>{children}</div>
    </div>
  )
};

export default DashboardComponentWrapper;


export const SwitchMode = ({ }) => {
  const { isVerificationDone } = useVerificationDone();
  const dispatch = useAppDispatch();
  const { isIndividual, isMerchant, isSponsor, userType } = useUserType()
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [mode, setMode] = useState('');

  useEffect(() => {
    if (isIndividual) {
      setMode('Merchant')
    }
    else if (isMerchant) {
      setMode('Sponsor')
    }
    else if (isSponsor) {
      setMode('Merchant')
    }
  }, [pathname]);

  const handleSwitchMode = () => {
    if (!isVerificationDone) {
      dispatch(setShowVerificationPending(true));
      return;
    }
    switchUser({ usertype: isIndividual ? "merchant" : isMerchant ? 'sponsor' : 'merchant' }, dispatch, () => {
      if (isMerchant) {
        navigate('/sponsor');
      } else {
        navigate('/business');
      }
    });
  }

  return (
    <div className="w-[300] flex flex-row items-center justify-start">
      <p className='mr-2 text-[#222222]'>Switch to {mode}</p>
      <Switch
        onChange={handleSwitchMode}
        checked={mode === 'Merchant'}
        onColor="#27AE60"
        offColor='#222222'
        onHandleColor="#fff"
        handleDiameter={15}
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={40}
        className="react-switch"
        id="material-switch"
      />
    </div>
  )
}


import { useCallback, useState } from "react";
import CreateCategory from "../../../components/Categories/CreateCategory";
import DashboardNavItem from "../../../components/DashboardNavItem/DashboardNavItem";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import SidePane from "../../../components/SidePane";
import CategoryDetailsTab from "./CategoryDetailsTab";
import CategoriesListTab from "./CategoriesListTab";
const categoriesNavItems = ["All Categories", "Create Category"];

const CategoriesPage = () => {
    const [category, setCategory] = useState<Record<string, any>>({});
    const [categoryId, setCategoryId] = useState('');
    const [tab, setTab] = useState(categoriesNavItems[0]);

    const handleDone = useCallback(() => {
        setTab(categoriesNavItems[0]);
    }, []);

    const renderChildren = () => {
        switch (tab) {
            case categoriesNavItems[0]:
                return <CategoriesListTab category={category} setCategory={setCategory} setCategoryId={setCategoryId} />
            case categoriesNavItems[1]:
                return <CreateCategory handleDone={handleDone} />
            default:
                return <CategoriesListTab category={category} setCategory={setCategory} setCategoryId={setCategoryId} />
        }
    }

    const renderNavItems = () => {
        return (
            <DashboardNavItem navItems={categoriesNavItems} tab={tab} setTab={setTab} />
        )
    }

    return (
        <DashboardComponentWrapper
            subTitle="Categories"
            pageDetails="Here's an overview of all Categories on AzatMe."
            renderNavItems={renderNavItems}
        >
            <>
                {renderChildren()}
                <SidePane onClose={() => setCategoryId("")} open={!!categoryId} title={""}>
                    <CategoryDetailsTab category={category} categoryId={categoryId} />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default CategoriesPage
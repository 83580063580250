import { useCallback, useEffect, useState } from "react";
import Button from "../Button";
import { Select as SelectComponent } from '../Input';
import { AddParticipantForm, Participant, ShareParticipant } from "../CreateExpense";
import Modal from "../Modal";
import './addUserModal.scss';
import { useAppSelector } from "../../app/hooks";
import { addParticipantToGroupActionCreator, addUserToExpenseActionCreator, getSplitformula } from "../../actions/dashboardActions";
import BankInput from "../BankInput";
import { Bank } from '../../pages/Bank/bankSlice';
import toast from "react-hot-toast";

const AddUserModal = ({
  isOpen,
  handleClose,
  members,
  type,
  item,
}) => {

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      overrideStyle={{
        maxWidth: '400px',
        width: '100%',
        height: 'max-content',
        maxHeight: '70%',
        border: '1px solid red',
      }}
      title="Add User"
    >
      <AddUsers
        handleClose={handleClose}
        members={members}
        type={type}
        item={item}
      />
    </Modal>
  )
}; 

export default AddUserModal;

export const AddUsers = ({
  handleClose,
  members,
  type,
  item,
}) => {
  const {user} = useAppSelector((state) => state.auth);
  const {isLoading} = useAppSelector(state => state.loader);
  const {splitFormula} = useAppSelector((state) => state.dashboard);  
  const [splitPayResult, setSplitPayResult] = useState<Array<Record<string, any>>>([]);
  const [participantsMap, setParticipantMap] = useState<Record<string, any>>({});
  const [percentageMap, setPercentageMap] = useState<Record<string, any>>({});
  const [participants, setParticipants] = useState<Array<Record<string, any>>>([]);
  const [set, setStep] = useState(0);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [splitId, setSplit] = useState<number>(0);
  const [data, setData] = useState({
    id: '',
    splittingId: '',
    bankName: '',
    uniqueCode: '',
    name: '',
    description: '',
  });

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      id: item.id,
      name: item.name,
      description: item.description,
    }));
  }, [item]);

  useEffect(() => {
    setSplit(splitFormula?.find((item) => item.split === data.splittingId)?.id as number)
  }, [data.splittingId, splitFormula]);

  useEffect(() => {
    !splitFormula && getSplitformula();
  }, [splitFormula]);

  const handleDoneRefund = () => {
    if (!splitId) {
      toast.error('Select a Splitting Formula to continue!');
      return;
    };
    const payload: Record<string, any> = {
      email: (participants.map((participant) => participant.email)).join(';'),
      split_method_id: splitId,
      bankName: selectedBank?.bankName,
      bankCode: selectedBank?.bankCode,
      account_number: selectedBank?.account_number,
    };
    if (splitId === 1) {
      let totalPercentage = 0;
      const userWtError: Array<string> = [];
      const members = Object.entries(percentageMap);
      members.forEach((item) => {
        totalPercentage += parseFloat(item[1]);
        if (item[1] <= 0) {
          userWtError.push(item[0]);
        }
      });
      if (userWtError.length > 0) {
        toast.error(`All participant must have more than 0 percent, check; \n ${userWtError.join('\n')}`);
        return;
      }
      if (totalPercentage !== 100) {
        toast.error(`Selected Percentage ${totalPercentage < 100 ? 'less' : 'more'} than 100!`);
        return;
      }
      payload.percentage_per_user = (percentageMap.toString());
      if (members.length === 2 && parseFloat(members[0][1]) === 50) {
        payload.percentage = 50;
      }
    }
    addUserToExpenseActionCreator({
      payload,
      expenseId: data.id,
    }, (data) => {
      setStep(1);
      setSplitPayResult(data.result);
    });
  }

  const handleDoneKontribute = () => {
    if (!splitId) {
      toast.error('Select a Splitting Formula to continue!');
      return;
    };
    addParticipantToGroupActionCreator({
      payload: {
        email: (participants.map((participant) => participant.email)).join(';'),
        split_method_id: splitId,
        bankName: selectedBank?.bankName,
        bankCode: selectedBank?.bankCode,
        account_number: selectedBank?.account_number,
      },
      groupId: data.id,
    }, (data) => {
      setStep(1);
      setSplitPayResult(data.result);
    });
  }

  const handleDone = () => {
    if (type === 'RefundMe') {
      handleDoneRefund()
    }
    if (type === 'Kontribute') {
      handleDoneKontribute();
    }
  }

  const addParticipants = (payload) => {
    participants.push(payload);
    const newparticipantsMap = {...participantsMap};
    newparticipantsMap[payload.email] = 1;
    setParticipantMap(newparticipantsMap);
    setParticipants([...participants]);
  }

  const handleAddParticipants = (userData: Record<string, any>) => {
    addParticipants({
      ...userData,
    });
  }

  const handleSetPercentageMap = (email: string, percentage) => {
    setPercentageMap((prevState) => ({
      ...prevState,
      [email]: percentage
    }));
  }

  const handleRemoveParticipant = (data) => {
    setParticipants(participants.filter((participant) => participant.email !== data));
    const newparticipantsMap = {...participantsMap};
    delete newparticipantsMap[data];
    setParticipantMap({...newparticipantsMap});
    if (percentageMap[data] !== undefined) {
      const newObj = {...percentageMap};
      delete newObj[data];
      setPercentageMap({...newObj});
    }
  }

  useEffect(() => {
    setParticipantMap(members);
    const newPart = members?.map((user) => user);
    setParticipants(newPart);
  }, [members]);

  const isFirst = set === 0;
  const isSecond = set === 1;
  const splitData = type === 'RefundMe' ? (splitFormula as unknown as Array<any>)?.filter((splitItem) => splitItem.split !== 'fixed').map((item) => item.split) || [] :
    (splitFormula as unknown as Array<any>)?.filter((item) => item.split === 'shared')
                .map((item) => item.split) || []
  return (
    <div style={{width: '100%', margin: '20px 0'}}>
      {
        isFirst && (
          <form className='user-group-cont' style={{width: '100%'}}>
            <div style={{width: '100%'}}>
              <BankInput setSelectedBank={(value) => {
                setSelectedBank(value);
                handleChange('bankName', value.bankName);
              }} />
            </div>
            <SelectComponent
              data={splitData}
              label="Spliting Formula"
              placeholder=""
              name="splittingId"
              required
              value={data.splittingId}
              onChange={handleChange}
            />
            <div
              className='user-group-wrappe' 
              style={{ 
                width: '100%',
                height: '100px', 
                overflow: 'auto',
              }}>
              {participants.length > 0 && ( <div>
                  {
                    participants.reverse().map((participant) => (
                      <Participant
                        key={participant.email}
                        email={participant.email}
                        splitId={splitId}
                        handleRemove={handleRemoveParticipant}
                        percentage={percentageMap[participant.email] || 0}
                        handleSetPercentage={handleSetPercentageMap}
                      />
                    ))
                  }
              </div>)}
            </div>
            <div style={{width: '100%'}}>
              <AddParticipantForm
                addParticipant={handleAddParticipants}
                participants={participantsMap}
                overrideInputDisplay={{
                  top: '40px',
                }}
                setShowPartForm={handleDone}
                doneBtnText="Save"
                from={type}
              />
            </div>
          </form>
        )
      }
      {
        isSecond && (
          <div>
            <h4 style={{textAlign: 'center'}}>
              Users Added, and a mail containing payment link sent to their email.
              <br />
              <small>Kindly advise the recipients to check their spam or junk folder if they are unable to locate the email in their primary inbox.</small>
              <br/> Click the share button to manually share to other media.
            </h4>
            <div className='user-group-wrapper'>
            {/* https://medium.com/@jsphkhan/share-like-native-mobile-apps-with-web-share-8be6f97cd41a */}
            {splitPayResult && splitPayResult.length > 0 && ( <div>
                {
                  splitPayResult.reverse().map((participant) => (
                    <ShareParticipant
                      key={participant.receipient}
                      email={participant.receipient}
                      url={participant.paylink}
                      title={`AzatMe RefundMe ${data.name} by ${user?.name} | ${user?.email}`}
                      text={`You are expected to pay NGA ${participant.amount} for ${data.description}. To pay click `}
                    />
                  ))
                }
              </div>)}
            </div>
            <Button
              type="flat"
              label='Done'
              onClick={() => {
                handleClose();
              }}
              disabled={isLoading}
            />
          </div>
        )
      }
    </div>
  );
};


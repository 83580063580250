import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "../../components/Button";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import Input from "../../components/Input";
import TabComponent from "../../components/TabComponent";
import toast from "react-hot-toast";
import Modal from "../../components/Modal";
import checkMark from "../../assets/new/checkmark.svg"
import pendingImg from "../../assets/new/Hourglass.svg"
import failedImg from "../../assets/new/failed.png"
import { getMerchantKYCData, getSponsorKYCData } from "../../actions/authActions";
import useUserType from "../../hooks/useUserType";
import uploadIcon from "../../assets/new/uploadIcon.svg"
import { shortenText } from "../../utils/common";
import previewEye from "../../assets/new/Show.svg"
import { KYCUploader } from "../../actions/businessActions";
import { useAppSelector } from "../../app/hooks";

export default function BusinessKYC() {
  return (
    <DashboardComponentWrapper title="Merchant KYC" pageDetails="Help us verify your busness here.">
      <TabComponent
        components={{
          "Manual Verification": () => <ManualKYCVerification />,
        }}
      />
    </DashboardComponentWrapper>
  )
}

export const ManualKYCVerification = () => {
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const [status, setStatus] = useState<"approved" | "pending" | "decline" | null>(null)
  const [statusDescription, setStatusDescription] = useState<string | null>(null)
  const [manualFormData, setManualFormData] = useState({
    identity_card: "",
    utility_bill: "",
    business_registration_certificate: "",
    proof_of_address: "",
    business_registration_number: ""
  })
  const { isMerchant, isSponsor } = useUserType()
  const { merchantSponsorKYC } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (merchantSponsorKYC) {
      setStatus(merchantSponsorKYC.status)
      setStatusDescription(merchantSponsorKYC.description)
      setOpenStatusModal(true)
      setManualFormData((prev) => ({
        ...prev,
        identity_card: merchantSponsorKYC.identity_card,
        utility_bill: merchantSponsorKYC.utility_bill,
        business_registration_certificate: merchantSponsorKYC.business_registration_certificate,
        proof_of_address: merchantSponsorKYC.proof_of_address,
        business_registration_number: merchantSponsorKYC.business_registration_number,
      }))
    }
    else {
      if (isMerchant) {
        getMerchantKYCData()
      } else if (isSponsor) {
        getSponsorKYCData()
      }
    }
  }, [])

  const handleChange = (name: string, value: string) => {
    setManualFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (manualFormData.identity_card === "" &&
      manualFormData.utility_bill === "" &&
      manualFormData.business_registration_certificate === "" &&
      manualFormData.proof_of_address === "") {
      toast.error("You need all the documents to verify your Identity!")
      return
    }

    const formData = new FormData();
    formData.append('business_registration_number', manualFormData.business_registration_number);
    if (typeof manualFormData.identity_card !== "string") {
      formData.append('identity_card', manualFormData.identity_card);
    }
    if (typeof manualFormData.utility_bill !== "string") {
      formData.append('utility_bill', manualFormData.utility_bill);
    }
    if (typeof manualFormData.business_registration_certificate !== "string") {
      formData.append('business_registration_certificate', manualFormData.business_registration_certificate);
    }
    if (typeof manualFormData.proof_of_address !== "string") {
      formData.append('proof_of_address', manualFormData.proof_of_address);
    }

    KYCUploader(formData, (resp) => {
      if (resp.status === 200) {
        setStatus("pending")
        setOpenStatusModal(true)
      }
    })
  }

  const approvedKYC = status === "approved"

  const inputsArray = [
    {
      label: "Identity Card",
      name: "identity_card",
      id: "identity_card",
      required: true,
      type: "file",
      placeholder: "upload Your Identity Card",
      value: manualFormData.identity_card,
      onChange: handleChange,
      accept: ".jpeg, .jpg, .png, .pdf",
      disabled: approvedKYC,
    },
    {
      label: "Utility Bill",
      name: "utility_bill",
      id: "utility_bill",
      required: true,
      type: "file",
      placeholder: "upload a Utility Bill",
      value: manualFormData.utility_bill,
      onChange: handleChange,
      accept: ".jpeg, .jpg, .png, .pdf",
      disabled: approvedKYC,
    },
    {
      label: "Business Registration Certificate",
      name: "business_registration_certificate",
      id: "business_registration_certificate",
      required: true,
      type: "file",
      placeholder: "upload Your Business Registration Certificate",
      value: manualFormData.business_registration_certificate,
      onChange: handleChange,
      accept: ".jpeg, .jpg, .png, .pdf",
      disabled: approvedKYC,
    },
    {
      label: "Proof of Address",
      name: "proof_of_address",
      id: "proof_of_address",
      required: true,
      type: "file",
      placeholder: "upload a proof of address",
      value: manualFormData.proof_of_address,
      onChange: handleChange,
      accept: ".jpeg, .jpg, .png, .pdf",
      disabled: approvedKYC,
    },
  ]

  return (
    <div className="w-full flex justify-center sm:p-5">
      <form className="w-full max-w-[500px]" onSubmit={handleSubmit}>
        <Input
          type="text"
          label="Business Registration Number"
          name="business_registration_number"
          placeholder="RC1*****6"
          value={manualFormData.business_registration_number}
          info="Enter youe business registration number for us to automatically very your business"
          onChange={handleChange}
          required
          disabled={approvedKYC}
        />
        {
          inputsArray.map((singleInput, index) => {
            return (
              <FileInputPreview input={singleInput} key={singleInput.id} />
            )
          })
        }
        {
          !approvedKYC && (
            <Button
              label="Save"
              type="flat"
              btnActionType="submit"
              disabled={
                manualFormData.identity_card === "" &&
                manualFormData.utility_bill === "" &&
                manualFormData.business_registration_certificate === "" &&
                manualFormData.proof_of_address === ""
              }
            />
          )
        }

      </form>
      <StatusModal open={openStatusModal} setOpen={setOpenStatusModal} status={status} description={statusDescription} />
    </div>
  )
}

const FileInputPreview = ({ input }) => {
  const openFileAction = () => {
    // @ts-ignore
    const blob = URL.createObjectURL(input?.value)
    window.open(blob, "_blank")
  }
  return (
    <div className="w-full">
      <p className='text-[18px] w-full text-start  text-[#222222B2] mb-2 mt-5'>{input.label}{input.required && <span className='text-red-500'>*</span>}</p>
      {
        input?.value === "" &&
        (
          <label htmlFor={input.id} className="w-full p-3 bg-[#0898A00D] border-[#0898A066] border-2 border-dashed  cursor-pointer flex gap-5 justify-center items-center mb-2">
            <img src={uploadIcon} className="w-[27px] h-[17px]" alt='upload' />
            <p className='text-[#222222] text-[14px]'>
              Upload or click here to{" "}
              <span className='text-[#0898A0] underline'>upload document</span>
            </p>
          </label>
        )
      }
      {
        input?.value !== "" &&
        (
          <div className='w-full p-1 flex gap-2 items-center flex-wrap justify-between border-2 border-[#2222221A] rounded-md'>
            <div className="flex gap-2 items-center">
              <div className="p-5 rounded-md bg-[#0898A01A] flex justify-center items-center w-[55px] h-[55px] uppercase ">
                {input?.value?.type ? input?.value?.type.split("/")[1] : "DOC"}
              </div>
              <div className="flex flex-col gap-1 w-full text-start">
                <p className='text-[14px]'>{input?.value.name ? shortenText(input?.value.name, 25) : input?.label}</p>
                {
                  input?.value.size && (
                    <p className='text-[#222222B2] text-[12px] font-light'>{Math.floor(parseInt(input?.value.size) / 1000)}KB</p>
                  )
                }
              </div>
            </div>

            <div className="flex gap-4 pr-5">
              {
                !input?.disabled && (
                  <label htmlFor={input.id} className="text-az-teal underline text-sm cursor-pointer opacity-80 hover:opacity-100">
                    Change File
                  </label>
                )
              }
              {
                typeof input?.value === "string" ?
                  (
                    <a href={input?.value} target="__blank">
                      <img src={previewEye} alt="view" className='w-[24px] h-[24px] cursor-pointer' />
                    </a>
                  ) :
                  (
                    <span onClick={openFileAction}>
                      <img src={previewEye} alt="view" className='w-[24px] h-[24px] cursor-pointer' />
                    </span>
                  )
              }
            </div>
          </div>
        )
      }

      <Input
        {...input}
        className='!hidden'
      />
    </div>
  )
}

const StatusModal = ({ open, setOpen, status, description }: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>>, status: null | "approved" | "pending" | "decline", description?: string | null }) => {
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Modal title="KYC Status" open={open} onClose={handleClose} overrideStyle={{ height: "auto" }} >
      <div className="w-full h-full flex flex-col items-center justify-center gap-7 mt-5">
        <img src={status === "approved" ? checkMark : status === "pending" ? pendingImg : status === "decline" ? failedImg : ""} className="w-[100px] h-[100px]" alt="status" />
        <div className="text-center flex flex-col gap-5 items-center">
          <h4 className="font-bold text-xl">
            {status === "approved" && "Your KYC has been approved!"}
            {status === "pending" && "Your KYC has been submitted successfully!"}
            {status === "decline" && "Your KYC has been declined!!"}
          </h4>
          <p>
            {status === "approved" && "Your business KYC information has been reviewed and verified successfully."}
            {status === "pending" && "Your KYC has been submitted successfully and is being verified, you would be alerted once it has been approved or declined."}
            {status === "decline" && "Your KYC documents have been declined, please reupload your valid documents."}
          </p>
          <p>
            {(status === "decline" && description) && (
              <>
                Reason: {description}
              </>
            )
            }
          </p>
        </div>
        <Button
          label="Close"
          type="flat"
          onClick={handleClose}
        />
      </div>
    </Modal>
  )
}
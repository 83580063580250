import React, { type Dispatch } from "react";

interface IProfileContext {
  profile: {};
  setProfile: Dispatch<React.SetStateAction<{}>>
}

export const ProfileContext = React.createContext<IProfileContext>({
  profile: {},
  setProfile: () => { }
});

import SMES from '../../../../../assets/new/business/smes.png';
import LargeOrg from '../../../../../assets/new/business/largeOrgF.png';
import TypeBg from '../../../../../assets/new/business/typeBg.png';

import SMOTOImg from '../../../../../assets/new/business/smoto.png';
import InvoiceImg from '../../../../../assets/new/business/invoice.png';
import MposImg from '../../../../../assets/new/business/mpos.png';
import NQRImg from '../../../../../assets/new/business/nqrImg.png';
import SponsorVoucherImg from '../../../../../assets/new/business/sponsor.png';
import MerchantVoucherImg from '../../../../../assets/new/business/merchant.png';
import ProductBg from '../../../../../assets/new/business/busProdBg.png';
import ArrowRightWhite from '../../../../../assets/new/ArrowRightWhite.png';


import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import Smoto from '../../../../../assets/svgs/components/Smoto';
import Invoicing from '../../../../../assets/svgs/components/Invoicing';
import NQR from '../../../../../assets/svgs/components/NQR';
import MPOSSVG from '../../../../../assets/svgs/components/Mpos';
import useShowNewFeature from '../../../../../hooks/useShowNewFeature';
import SponsorVoucherSVG from '../../../../../assets/svgs/components/SponsrVoucher';
import MerchantVoucherSVG from '../../../../../assets/svgs/components/MerchantVoucher';

const features = [
  {
    title: 'Social Media Order/Telephone Order.',
    details: `
      Provide your customers on any social media platforms with a fast, 
      easy and reliable payment platform, where they can make payments
      securely without hassle.
    `,
    img: SMOTOImg,
    icon: Smoto,
    name: 'SMOTO',
    link: 'smoto',
  },
  {
    title: 'Built to save you time and get you paid faster.',
    details: `
      Streamline the invoicing process for your business by creating
      and sending custom invoices to your customers, making it
      easier to manage and track payment requests.
    `,
    img: InvoiceImg,
    icon: Invoicing,
    name: 'INVOICING',
    link: 'invoicing',
  },
  {
    title: 'Mobile Point of Sales.',
    details: `Enable instant payments: anywhere, anytime by turning your
smartphones into payment terminals, without the need for any
extra investments in hardware.`,
    img: MposImg,
    icon: MPOSSVG,
    name: 'MPOS',
    link: 'mpos',
  },
  {
    title: 'NQR Payment Solution.',
    details: `NQR Payment Solution is a secure QR-code-based payments and 
      collections platform designed for your merchants and customers
      to receive and make payments for goods and services.`,
    img: NQRImg,
    icon: NQR,
    name: 'NQR CODE',
  },
  {
    title: 'Empower Communities, Simplify Support!',
    details: `Empower your community or employees by distributing funds securely through vouchers. Allocate, manage, and monitor redemptions with our streamlined, user-friendly platform designed for effortless impact.`,
    img: SponsorVoucherImg,
    icon: SponsorVoucherSVG,
    name: 'Sponsor Voucher',
    link: 'sponsor_voucher',
    isNewFeature: true,
  },
  {
    title: 'Grow Your Business with Us!',
    details: `Expand your reach by joining our merchant network! Accept vouchers seamlessly, attract loyal customers, and increase sales—all while simplifying your payment processes.`,
    img: MerchantVoucherImg,
    icon: MerchantVoucherSVG,
    name: 'Merchant Voucher',
    link: 'merchant_voucher',
    isNewFeature: true,
  },
  // {
  //   title: 'NQR Payment Solution.',
  //   details: `NQR Payment Solution is a secure QR-code-based payments and 
  //     collections platform designed for your merchants and customers
  //     to receive and make payments for goods and services.`,
  //   img: NQRImg,
  //   icon: NQR,
  //   name: 'NQR CODE',
  // },
];

const details = {
  smes: {
    title: 'Small and Medium Enterprise',
    details: [
      `AzatMe gives you simple tools to accept payments and grow
      quickly, regardless of the stage your business is in.`,

      `With AzatMe's payment solutions, you can give your customers' 
      their preferred payment methods and see their purchase patterns
      on your dashboard, helping you make better-informed business
      decisions.`,

      `You can efficiently create and track invoices, accept payments via
      other seamless methods for smooth and efficient business
      experience.`
    ],
    img: SMES
  },
  'large-org': {
    title: 'Large Organisations',
    details: [
      `Our flexible payment solutions can be configured to fit seamlessly
with your payment operations, helping you streamline your 
operations for increased revenue. `,

      `AzatMe gives you simple tools to accept payments and grow 
quickly, regardless of the stage your business is in.`,
    ],
    img: LargeOrg
  }
}


const Features = () => {
  const showFeature = useShowNewFeature()
  const navigate = useNavigate();
  const [featureType, setFeatureType] = useState<'smes' | 'large-org'>('smes');
  const selectedType = details[featureType];
  return (
    <div className="w-screen min-h-screen bg-[#001213] flex flex-col justify-start items-start content-start p-[6%] flex-wrap relative">
      <div className='w-full flex flex-col justify-center items-center'>
        <p className='bg-[#FFFFFF1A] text-white uppercase text-sm px-5 py-2 rounded-full mb-10'>
          Products
        </p>
        <p className='text-white text-3xl max-w-[500px]'>
          There’s more for your business
          with AzatMe
        </p>
      </div>
      <div className='w-full flex flex-row flex-wrap justify-center mt-10'>
        {
          features.map((type, index) => {
            if ((type.isNewFeature && showFeature) || !type.isNewFeature) {
              return (
                <div key={index} onClick={() => type?.link && navigate(type.link)} className="flex flex-col justify-start items-start content-center bg-[#0898A01A] h-[600px] m-4 w-full max-w-[560px] rounded-lg relative cursor-pointer">
                  <div className='w-full h-full p-8 flex flex-col justify-between item-start text-left'>
                    <div className='flex flex-row justify-start mb-6 items-center bg-[#FFFFFF1A] rounded-full p-2 px-3 w-fit'>
                      <type.icon />
                      <p className='ml-3 mt-1 text-white pr-4'>{type.name}</p>
                    </div>
                    <p className="rounded-full py-1 text-white font-Onest text-2xl">{type.title}</p>
                    <p className="font-Onest text-[#ccc] text-sm text-left mt-4">{type.details}</p>
                    {
                      type.link ? (
                        <div className='mt-5 underline text-white flex flex-row'>
                          Learn more
                          <img src={ArrowRightWhite} alt="" className='ml-3' />
                        </div>
                      ) : (
                        <div className='p-1 px-2 bg-[#3ABE77] text-white w-fit rounded-md'>
                          Coming soon
                        </div>
                      )
                    }
                    <div className='w-full flex flex-row justify-center'>
                      <img src={type.img} alt="" className='w-[70%]' />
                    </div>
                  </div>
                  <img src={ProductBg} alt="" className='w-full h-full absolute ' />
                </div>
              )
            }
            return null
          })
        }
      </div>
      <div className='flex flex-col justify-center items-center w-full pt-[100px]'>
        <p className='text-white text-2xl  md:text-4xl w-10/12 max-w-[700px]'>
          Built to simplify and streamline
          businesses of all types and sizes.
        </p>
        <div className='flex justify-between p-2 rounded-full bg-[#0898A01A] w-full ml-2 max-w-[460px] my-16'>
          <button onClick={() => setFeatureType('smes')} className={`${featureType === 'smes' ? 'bg-white text-az-teal' : 'bg-transparent text-az-teal'} rounded-full w-6/12 h-10 font-normal font-Onest`}>For SMEs</button>
          <button onClick={() => setFeatureType('large-org')} className={`${featureType === 'large-org' ? 'bg-white text-az-teal' : 'bg-transparent text-az-teal'} rounded-full w-6/12 h-10 font-normal font-Onest`}>Large Organisations</button>
        </div>
        <div className='flex flex-col xl:flex-row gap-5 items-center  xl:justify-between w-full'>
          <div className='text-white w-full max-w-[700px] min-h-[514px] h-fit text-left relative mb-10 lg:mb-0'>
            <h1 className='text-4xl my-10 mx-10 mt-16'>{selectedType.title}</h1>
            {
              selectedType.details.map((p) => <p className='my-10 mx-10 w-[85%]' key={p}>{p}</p>)
            }
            <img src={TypeBg} alt="" className='absolute top-0 left-0 w-full h-full ' />
          </div>
          <img src={selectedType.img} className='max-w-[700px] w-full  xl:w-auto object-cover' alt="" />
        </div>
      </div>
    </div>
  )
};

export default Features;
import React from 'react'
import arrowLeft from '../voucher-assets/icons/arrow-left.svg'
import Button from '../../../components/Button'

export default function GoBackBtn() {
  const handleGoBack = () => {
    window.history.back()
  }
  return (
    <>
      <Button
        type='text'
        label='Go back'
        className='!hidden sm:!flex !rounded-full !m-0 !w-auto !p-[25px] !text-black !text-[14px] font-[500] hover:!bg-[#F9F9F9]'
        leftIcon={<img src={arrowLeft} />}
        onClick={handleGoBack}
      />
      <div onClick={handleGoBack} className="flex sm:hidden justify-center items-center !bg-[#F9F9F9] !rounded-full !w-auto !p-[15px]">
        <img src={arrowLeft} />
      </div>
    </>
  )
}

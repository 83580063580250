import { useEffect, useState } from "react"
import { useAppSelector } from "../app/hooks";

const useUserType = () => {
  const { user } = useAppSelector(state => state.auth);

  return { userType: user?.usertype, isIndividual: user?.usertype === "user", isMerchant: user?.usertype === "merchant", isSponsor: user?.usertype === "sponsor" };
}

export default useUserType;

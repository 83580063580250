import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { getCategoriesActionCreator, getUserAjoSilent, getUserGroupsActionCreator } from '../../actions/dashboardActions';

import "./dashboard.scss";
import { useEffect } from "react";
import { getExpenses } from "../../actions/dashboardActions";
import DashboardSideBar from '../../components/DashboardSideBar';
import useIsAdmin from '../../hooks/useIsAdmin';
import { getLedger } from '../../actions/authActions';
import DashboardSVG from '../../components/SVG/Dashboard';
import useVerificationDone from '../../hooks/useVerificationDone';
import useShowNewFeature from '../../hooks/useShowNewFeature';
import VoucherSVG from '../../components/SVG/Voucher';
import TransactionsSVG from '../../components/SVG/TransactionsSVG';
import ReportsSVG from '../../components/SVG/ReportsSVG';
import KycSVG from '../../components/SVG/KYCSvg';

function SponsorDashboard() {
  const showFeature = useShowNewFeature();
  const { allExpenses, categories, allUserGroups, ajo } = useAppSelector((state) => state.dashboard);
  const { ledger } = useAppSelector((state) => state.auth);
  const { isVerificationDone } = useVerificationDone();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (isVerificationDone) {
      if (!allExpenses) {
        getExpenses(1, isAdmin);
      }
      if (!categories) {
        getCategoriesActionCreator();
      }
      if (!allUserGroups) {
        getUserGroupsActionCreator();
      }
      if (Object.entries(ledger).length === 0) {
        getLedger();
      }
      if (showFeature) {
        if (!ajo) {
          getUserAjoSilent(1);
        }
      }
    }
  }, [isVerificationDone]);


  const navItems = [
    {
      name: "dashboard",
      path: `/sponsor/`,
      title: "Dashboard",
      Icon: DashboardSVG
    },
    {
      name: "vouchers",
      path: `/sponsor/vouchers`,
      title: "Vouchers",
      Icon: VoucherSVG
    },
    {
      name: "transactions",
      path: `/sponsor/transactions`,
      title: "Transactions",
      Icon: TransactionsSVG
    },
    {
      name: 'report',
      path: `/sponsor/reports`,
      title: "Reports",
      Icon: ReportsSVG
    },
    {
      name: 'kyc',
      path: `/sponsor/kyc`,
      title: "KYC",
      Icon: KycSVG
    },
  ];


  return (
    <div className="voucherDashboard flex flex-row justify-between items-start !relative">
      <DashboardSideBar
        userType="Sponsor"
        firstItem="Overview"
        items={navItems}
        showVerificationStatus={false}
        showKYCVerificationStatus={true}
      />
      <div className="outlet flex flex-auto flex-grow">
        <Outlet />
      </div>
    </div>
  );
}

export default SponsorDashboard;
